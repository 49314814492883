import React, { useEffect, useState , useContext} from 'react';
import { Formik, Form } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { fsm } from '../../../mobx/FormSuggestionManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ac } from '../../../mobx/AppContoller';
import { modalInterface } from '../../../mobx/ModalInterface';
import api from '../../api/AxiosConfig'
import axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import utils from '../../../utils/utils';
import './GlassesForm.css'
import DateModal from '../../modal/date_modal/DateModal';
import PrintInfo from '../../print_info/PrintInfo';
import CreatableSelect from 'react-select/creatable';
import {observer} from 'mobx-react'

const GlassesMultiForm = ({forms = [] , setForms , initialFormState , setSaveColor , examines , setDeletedForms , patient}) => {
    const {appContext} = useContext(AppContext)
  const addForm = () => {
    setForms(forms => [...forms, {...initialFormState}]);
  };

  const removeForm = (index) => {
    setDeletedForms(prev => [...prev, forms[index].id]);
    setForms(forms => forms.filter((_, i) => i !== index));
  }

  function handleClientPickup(index){
    if(ac.currentGlassesExamine == 0){
        modalInterface.createModal("התראה", 'אין אפשרות לסמן שדה זה בבדיקה חדשה', null , null , 15 * 1000)
    } else {
        if(forms[index].client_pickup){
            
            modalInterface.createModal("התראה",
         "האם אתה בטוח שאתה רוצה לבטל את הסימון של משקפיים נאספו?" ,
            [{
                text: "כן",
                focus: true,
                onclick: () => handleCheckPickup(index, false)
            },
            {   
                text: "לא",
                onclick: () => modalInterface.closeModal()
            }])
        } else {
            modalInterface.createModal("התראה" , null , null , <DateModal handleSetDate={handleSetDate} index={index}  />)
            // modalInterface.createModal("התראה",
            // "האם אתה בטוח שאתה רוצה לסמן שהמשקפיים נאספו?" ,
            //    [{
            //        text: "כן",
            //        onclick: () => handleCheckPickup(index, true)
            //    },
            //    {   
            //        text: "לא",
            //        onclick: () => modalInterface.closeModal()
            //    }])
        }
        
    }
    }

    async function handleCheckPickup(index , condition , date){
        const newForms = [...forms];
        newForms[index] = {
        ...newForms[index],
        ['client_pickup']: condition,
        ['date_of_delivery']: date
        };
        setForms(newForms)

        try {
            const currentExamine = examines[ac.currentGlassesExamine - 1]
            const response = await api.post('/main/set-client-pickup', {
                user_id: appContext.userData.id,
                patient_id: ac.currentPatient,
                examine: currentExamine,
                id: forms[index].id,
                date: utils.fromatDate(date),
                condition: condition
            })
            if(response.data.status){
                modalInterface.closeModal()
            }
        } catch (e) {

        }
        
    }

  function handleInputChange(name , value , index){
    const newForms = [...forms];
    newForms[index] = {
      ...newForms[index],
      [name]: value,
    };

    setForms(newForms)
  }

  function handleChange(event, index ){
    const name = event.target.name
    const value = event.target.value
    const newForms = [...forms];
    newForms[index] = {
      ...newForms[index],
      [name]: value,
    };
    setForms(newForms)
  }

  function handleSelected(name, selected, index) {
    const value = selected ? selected.value : ''; 
    const newForms = [...forms];
    newForms[index] = {
        ...newForms[index],
        [name]: value,
    };
    setForms(newForms);

    if(selected?.__isNew__){
        fsm.addNewGlassesOrderSuggestions(name , value)
        console.log("New Item");
    } else {
        console.log("Existing Item");
    }
}

  function handleSetDate(index , date){
    handleCheckPickup(index , true , date)
  }

  function getOptions(options){
    return options.map((option) => ({
        value: option,
        label: option
    }))
  }

  function getValue(value){
    return value ? {
        value: value,
        lable: value
    } : null
  }

  useEffect(() => {

  },[forms , initialFormState])

  return (
    <div className='no-print'>
      {forms.map((form, index) => (
        <Formik
          key={index}
          initialValues={form}
        >
            
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} className='glasses-order'>
                
              <Col>
                <Row className='justify-content-evenly'>
                  <BootstrapForm.Group title={forms[index].lenses_manufacture ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                    <BootstrapForm.Label>ספק עדשה</BootstrapForm.Label>
                    <CreatableSelect
                        id={`lenses_manufacture-${index}`}
                        classNamePrefix="select"
                        isClearable
                        options={getOptions(fsm.glassesOrderSuggestion?.lenses_manufacture)}
                        onChange={selected => handleSelected('lenses_manufacture', selected, index)}
                        value={forms[index].lenses_manufacture ? {
                            value: forms[index].lenses_manufacture,
                            label: forms[index].lenses_manufacture
                        } : null}
                        placeholder='ספק עדשה'
                        className='glasses-order-select'
                        title={forms[index].lenses_manufacture ?? null}
                        styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                        name={`lenses_manufacture`}
                        formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        
                    />
                    {/* <Typeahead
                        style={{ textAlign: 'right' }}
                        id={`lenses_manufacture-${index}`}
                        options={fsm.glassesOrderSuggestion?.lenses_manufacture || []}
                        placeholder='ספק עדשה'
                        onChange={(selected) => handleSelected('lenses_manufacture' , selected , index )}
                        onInputChange={(value) => handleInputChange('lenses_manufacture' , value , index ) }
                        name={`lenses_manufacture`}
                        selected={forms[index].lenses_manufacture ? [forms[index].lenses_manufacture] : []}
                    /> */}
                  </BootstrapForm.Group>

                  <BootstrapForm.Group title={forms[index].order_id ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                    <BootstrapForm.Label>מס' הזמנה</BootstrapForm.Label>
                    <BootstrapForm.Control 
                        type='text' 
                        placeholder="מס' הזמנה" 
                        name='order_id' 
                        value={forms[index].order_id || ''} 
                        title={forms[index].order_id ?? null}
                        onChange={(event) => handleChange(event , index)}/>
                </BootstrapForm.Group>

                <BootstrapForm.Group title={forms[index].frame_size ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                    <BootstrapForm.Label>גודל מסגרת</BootstrapForm.Label>
                    <CreatableSelect
                        id={`frame_size-${index}`}
                        classNamePrefix="select"
                        isClearable
                        options={getOptions(fsm.glassesOrderSuggestion?.frame_size)}
                        onChange={selected => handleSelected('frame_size', selected, index)}
                        value={forms[index].frame_size ? {
                            value: forms[index].frame_size,
                            label: forms[index].frame_size
                        } : null}
                        className='glasses-order-select'
                        title={forms[index].frame_size ?? null}
                        placeholder="גודל מסגרת"
                        styles={{ control: base => ({ ...base }) }}
                        name={`frame_size`}
                        formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                    />
                    {/* <Typeahead
                        style={{textAlign: 'right'}}
                        id={`frame_size-${index}`}
                        options={fsm.glassesOrderSuggestion?.frame_size || []}
                        onChange={(selected) => handleSelected('frame_size' , selected , index )}
                        onInputChange={(value) => handleInputChange('frame_size' , value , index ) }
                        name='frame_size'
                        placeholder="גודל מסגרת"
                        selected={forms[index].frame_size ? [forms[index].frame_size] : []}
                        >
                    </Typeahead> */}
                </BootstrapForm.Group>
                </Row>
                <br />
                <Row>
                <BootstrapForm.Group title={forms[index].lenses_name ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                    <BootstrapForm.Label>שם העדשה</BootstrapForm.Label>
                        <CreatableSelect
                            id={`lenses_name-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.lenses_name)}
                            onChange={selected => handleSelected('lenses_name', selected, index)}
                            value={forms[index].lenses_name ? {
                                value: forms[index].lenses_name,
                                label: forms[index].lenses_name
                            } : null}
                            placeholder="שם העדשה"
                            className='glasses-order-select'
                            title={forms[index].lenses_name ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`lenses_name`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`lenses_name-${index}`}
                            options={fsm.glassesOrderSuggestion?.lenses_name || []}
                            name='lenses_name'
                            placeholder="שם העדשה"
                            selected={forms[index].lenses_name ? [forms[index].lenses_name] : []}
                            onChange={(selected) => handleSelected('lenses_name' , selected , index )}
                            onInputChange={(value) => handleInputChange('lenses_name' , value , index ) }
                            >
                        </Typeahead> */}
                    </BootstrapForm.Group>
                    <BootstrapForm.Group title={forms[index].frame_supplier ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>ספק מסגרת</BootstrapForm.Label>
                        <CreatableSelect
                            id={`frame_supplier-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.frame_supplier)}
                            onChange={selected => handleSelected('frame_supplier', selected, index)}
                            value={forms[index].frame_supplier ? {
                                value: forms[index].frame_supplier,
                                label: forms[index].frame_supplier
                            } : null}
                            placeholder="ספק מסגרת"
                            className='glasses-order-select'
                            title={forms[index].frame_supplier ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`frame_supplier`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`frame_supplier-${index}`}
                            options={fsm.glassesOrderSuggestion?.frame_supplier || []}
                            name='frame_supplier'
                            placeholder="ספק מסגרת"
                            selected={forms[index].frame_supplier ? [forms[index].frame_supplier] : []}
                            onChange={(selected) => handleSelected('frame_supplier' , selected , index )}
                            onInputChange={(value) => handleInputChange('frame_supplier' , value , index ) }
                            >
                        </Typeahead> */}
                    </BootstrapForm.Group>
                    
                    

                    <BootstrapForm.Group title={forms[index].pole_size ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>אורך מוט</BootstrapForm.Label>
                        <BootstrapForm.Control 
                            type='text' 
                            placeholder='אורך מוט' 
                            name='pole_size' 
                            value={forms[index].pole_size || ''} 
                            title={forms[index].pole_size ?? null}
                            onChange={(event) => handleChange(event , index)}/>
                    </BootstrapForm.Group>
                </Row>
                <br />
                <Row className='justify-content-evenly'>
                    <BootstrapForm.Group title={forms[index].material_index ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>חומר / אינדקס</BootstrapForm.Label>
                        <CreatableSelect
                            id={`material_index-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.material_index)}
                            onChange={selected => handleSelected('material_index', selected, index)}
                            value={forms[index].material_index ? {
                                value: forms[index].material_index,
                                label: forms[index].material_index
                            } : null}
                            placeholder="חומר / אינדקס"
                            className='glasses-order-select'
                            title={forms[index].material_index ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`material_index`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'left'}}
                            id={`material_index-${index}`}
                            options={fsm.glassesOrderSuggestion?.material_index || []}
                            name='material_index'
                            placeholder="חומר / אינדקס"
                            selected={forms[index].material_index ? [forms[index].material_index] : []}
                            onChange={(selected) => handleSelected('material_index' , selected , index )}
                            onInputChange={(value) => handleInputChange('material_index' , value , index ) }
                            
                            >
                        </Typeahead> */}
                        
                    </BootstrapForm.Group>

                    <BootstrapForm.Group title={forms[index].frame_manufacture ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>יצרן מסגרת</BootstrapForm.Label>
                        <CreatableSelect
                            id={`frame_manufacture-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.frame_manufacture)}
                            onChange={selected => handleSelected('frame_manufacture', selected, index)}
                            value={forms[index].frame_manufacture ? {
                                value: forms[index].frame_manufacture,
                                label: forms[index].frame_manufacture
                            } : null}
                            placeholder="יצרן מסגרת"
                            className='glasses-order-select'
                            title={forms[index].frame_manufacture ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`frame_manufacture`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`frame_manufacture-${index}`}
                            options={fsm.glassesOrderSuggestion?.frame_manufacture || []}
                            name='frame_manufacture'
                            placeholder="יצרן מסגרת"
                            selected={forms[index].frame_manufacture ? [forms[index].frame_manufacture] : []}
                            onChange={(selected) => handleSelected('frame_manufacture' , selected , index )}
                            onInputChange={(value) => handleInputChange('frame_manufacture' , value , index ) }
                           
                            >
                        </Typeahead> */}
                        
                    </BootstrapForm.Group>

                    <BootstrapForm.Group title={forms[index].frame_info ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label> אפיון מסגרת </BootstrapForm.Label>
                            <CreatableSelect
                                id={`frame_info-${index}`}
                                classNamePrefix="select"
                                isClearable
                                options={getOptions(fsm.glassesOrderSuggestion?.frame_info)}
                                onChange={selected => handleSelected('frame_info', selected, index)}
                                value={forms[index].frame_info ? {
                                    value: forms[index].frame_info,
                                    label: forms[index].frame_info
                                } : null}
                                placeholder="אפיון מסגרת"
                                className='glasses-order-select'
                                title={forms[index].frame_info ?? null}
                                styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                                name={`frame_info`}
                                formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                            />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`frame_info-${index}`}
                            options={fsm.glassesOrderSuggestion?.frame_info || []}
                            name='frame_info'
                            placeholder="אפיון מסגרת"
                            selected={forms[index].frame_info ? [forms[index].frame_info] : []}
                            onChange={(selected) => handleSelected('frame_info' , selected , index )}
                            onInputChange={(value) => handleInputChange('frame_info' , value , index ) }
                            >
                        </Typeahead> */}
                        
                    </BootstrapForm.Group>
                </Row>
                <br />
                <Row>
                    <BootstrapForm.Group title={forms[index].lenses_diamater ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>קוטר העדשה</BootstrapForm.Label>
                        <CreatableSelect
                            id={`lenses_diamater-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.lenses_diamater)}
                            onChange={selected => handleSelected('lenses_diamater', selected, index)}
                            value={forms[index].lenses_diamater ? {
                                value: forms[index].lenses_diamater,
                                label: forms[index].lenses_diamater
                            } : null}
                            placeholder="קוטר העדשה"
                            className='glasses-order-select'
                            title={forms[index].lenses_diamater ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`lenses_diamater`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`lenses_diamater-${index}`}
                            options={fsm.glassesOrderSuggestion?.lenses_diamater || []}
                            name='lenses_diamater'
                            placeholder="קוטר העדשה"
                            selected={forms[index].lenses_diamater ? [forms[index].lenses_diamater] : []}
                            onChange={(selected) => handleSelected('lenses_diamater' , selected , index )}
                            onInputChange={(value) => handleInputChange('lenses_diamater' , value , index ) }
                            >
                        </Typeahead> */}
                        
                    </BootstrapForm.Group>

                    <BootstrapForm.Group title={forms[index].frame_modal  ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>דגם מסגרת</BootstrapForm.Label>
                        <CreatableSelect
                            id={`frame_modal-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.frame_modal)}
                            onChange={selected => handleSelected('frame_modal', selected, index)}
                            value={forms[index].frame_modal ? {
                                value: forms[index].frame_modal,
                                label: forms[index].frame_modal
                            } : null}
                            placeholder="דגם מסגרת"
                            className='glasses-order-select'
                            title={forms[index].frame_modal  ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`frame_modal`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`frame_modal-${index}`}
                            options={fsm.glassesOrderSuggestion?.frame_modal || []}
                            name='frame_modal'
                            placeholder="דגם מסגרת"
                            selected={forms[index].frame_modal ? [forms[index].frame_modal] : []}
                            onChange={(selected) => handleSelected('frame_modal' , selected , index )}
                            onInputChange={(value) => handleInputChange('frame_modal' , value , index ) }
                            >
                        </Typeahead> */}
                        
                    </BootstrapForm.Group>

                    <BootstrapForm.Group title={forms[index].glasses_function  ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>תפקיד משקפיים</BootstrapForm.Label>
                        <CreatableSelect
                            id={`glasses_function-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.glasses_function)}
                            onChange={selected => handleSelected('glasses_function', selected, index)}
                            value={forms[index].glasses_function ? {
                                value: forms[index].glasses_function,
                                label: forms[index].glasses_function
                            } : null}
                            placeholder="תפקיד משקפיים"
                            className='glasses-order-select'
                            title={forms[index].glasses_function  ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`glasses_function`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`glasses_function-${index}`}
                            options={fsm.glassesOrderSuggestion?.glasses_function  || []}
                            name='glasses_function'
                            placeholder="תפקיד משקפיים"
                            selected={forms[index].glasses_function ? [forms[index].glasses_function] : []}
                            onChange={(selected) => handleSelected('glasses_function' , selected , index )}
                            onInputChange={(value) => handleInputChange('glasses_function' , value , index ) }
                            >
                        </Typeahead> */}
                    </BootstrapForm.Group>
                </Row>
                <br />
                <Row>
                    <BootstrapForm.Group title={forms[index].lenses_cover  ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>ציפוי העדשה</BootstrapForm.Label>
                        <CreatableSelect
                            id={`lenses_cover-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.lenses_cover)}
                            onChange={selected => handleSelected('lenses_cover', selected, index)}
                            value={forms[index].lenses_cover ? {
                                value: forms[index].lenses_cover,
                                label: forms[index].lenses_cover
                            } : null}
                            placeholder="ציפוי העדשה"
                            className='glasses-order-select'
                            title={forms[index].lenses_cover  ?? null}
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`lenses_cover`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            
                            id={`lenses_cover-${index}`}
                            options={fsm.glassesOrderSuggestion?.lenses_cover || []}
                            name='lenses_cover'
                            placeholder="ציפוי העדשה"
                            selected={forms[index].lenses_cover ? [forms[index].lenses_cover] : []}
                            onChange={(selected) => handleSelected('lenses_cover' , selected , index )}
                            onInputChange={(value) => handleInputChange('lenses_cover' , value , index ) }
                            >
                        </Typeahead> */}
                        
                    </BootstrapForm.Group>

                    <BootstrapForm.Group title={forms[index].frame_color  ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>צבע מסגרת</BootstrapForm.Label>
                        <CreatableSelect
                            id={`frame_color-${index}`}
                            classNamePrefix="select"
                            isClearable
                            options={getOptions(fsm.glassesOrderSuggestion?.frame_color)}
                            onChange={selected => handleSelected('frame_color', selected, index)}
                            value={forms[index].frame_color ? {
                                value: forms[index].frame_color,
                                label: forms[index].frame_color
                            } : null}
                            title={forms[index].frame_color  ?? null}
                            placeholder="צבע מסגרת"
                            className='glasses-order-select'
                            styles={{ control: base => ({ ...base, textAlign: 'right' , width: '200px' }) }}
                            name={`frame_color`}
                            formatCreateLabel={inputValue => `הוסף ${inputValue}`}
                        />
                        {/* <Typeahead
                            style={{textAlign: 'right'}}
                            id={`frame_color-${index}`}
                            options={fsm.glassesOrderSuggestion?.frame_color  || []}
                            name='frame_color'
                            placeholder="צבע מסגרת"
                            selected={forms[index].frame_color ? [forms[index].frame_color] : []}
                            onChange={(selected) => handleSelected('frame_color' , selected , index )}
                            onInputChange={(value) => handleInputChange('frame_color' , value , index ) }
                            >
                        </Typeahead> */}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row  justify-content-evenly">
                        <BootstrapForm.Label>תאריך מסירה</BootstrapForm.Label>
                        <div className='d-flex flex-direction-row align-items-center' style={{width: '200px'}}>
                            <BootstrapForm.Control 
                                className={`date-of-delivery ${!forms[index].client_pickup ? "hide-div" : null} `}
                                type='date' 
                                value={utils.fromatDate(forms[index].date_of_delivery) || ''} 
                                name='date_of_delivery' 
                                onChange={(event) => handleChange(event , index)}/>
                                <BootstrapForm.Label className={`date-of-delivery-label ${forms[index].client_pickup ? "hide-div" : null}`}>
                                    האם המשקפיים נמסרו?

                                </BootstrapForm.Label>
                            <BootstrapForm.Check 
                                checked={forms[index].client_pickup} 
                                onChange={(value) => console.log("Glasses pickup changed" , value.target.checked)}
                                onClick={() => handleClientPickup(index)} />
                        </div>
                        
                    </BootstrapForm.Group>
                </Row>
                <br className='no-print' />
                <Row className='d-flex justify-content-evenly'>

                    <BootstrapForm.Group className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>השתתפות קופ"ח</BootstrapForm.Label>
                        <BootstrapForm.Control 
                            type='text' 
                            placeholder='השתתפות קופ"ח' 
                            name='medical_institute_cover' 
                            value={forms[index].medical_institute_cover || ''} 
                            title={forms[index].medical_institute_cover ?? null}
                            onChange={(event) => handleChange(event , index)}/>
                    </BootstrapForm.Group>

                    <BootstrapForm.Group title={forms[index].total_price ?? null} className="col-lg-4 col-md-12 col-xs-12 d-flex flex-direction-row justify-content-evenly">
                        <BootstrapForm.Label>סה"כ תשלום</BootstrapForm.Label>
                        <BootstrapForm.Control 
                        type='text' 
                        placeholder='סה"כ תשלום' 
                        name='total_price'  
                        value={forms[index].total_price || ''} 
                        title={forms[index].total_price ?? null}
                        onChange={(event) => handleChange(event , index)}/>
                    </BootstrapForm.Group>
                </Row>
              </Col>
              
              {index == 0 ?
                <div  onClick={addForm} className='additional-glasses-title no-print'>
                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                    <label>זוג משקפיים נוסף</label>
                </div> :
                <div onClick={() => removeForm(index)} className='additional-glasses-title no-print'>
                    <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
                    <label>הסר זוג משקפיים</label>
                </div>}
            
            {/* <button>sumbit</button> */}
            <div style={{borderBottom: '1px solid #a3a3a3' , width: '100%' , margin: '10px 0px 10px 0px'}}></div>
            </Form>
            
          )}
        </Formik>
      ))}
    </div>
  );
};

export default observer(GlassesMultiForm);