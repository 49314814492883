import React, {useContext, useEffect , useState} from 'react'
import './Header.css'
import { AppContext } from '../../context/AppContext.js';
import { sm } from '../../mobx/ScreenManager.js';
import utils from '../../utils/utils.js';
import Svg from '../../utils/svg.js';
import { modalInterface } from '../../mobx/ModalInterface.js';
import UserImageModal from '../modal/user_image_modal/UserImageModal.js';
import url from '../../context/Url.js';
import loyaLogo from '../../assets/images/loya_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse , faEye , faMagnifyingGlass, faFile, faUser, faCircleDot, faPenToSquare,   } from '@fortawesome/free-solid-svg-icons';
import { ac } from '../../mobx/AppContoller.js';
import { mainmm } from '../../mobx/MainMenuManager.js';
import { observer  } from "mobx-react";
import { em } from '../../mobx/ExamineManager.js';
import { useMediaQuery } from 'react-responsive';
import DiseasesModal from '../modal/diseases_modal/DiseasesModal.js';


function Header() {
  const {appContext , setAppContext} = useContext(AppContext)
  const [currentStyle, setCurrentStyle] = useState(null);
  const isMobile = useMediaQuery({ query: `(max-width: 800.98px)` });

  function handleAvatarError(e ){
    e.target.onerror = null;
    e.target.src = "https://via.placeholder.com/50x50" 
    
  }

  function hanldeImageClick(){
    modalInterface.createModal("העלאת תמונה" , null , null , <UserImageModal />)
  }

  const handleStyles = ()=>{
    return ac.currentPatient &&  (mainmm.showInfo || mainmm.showEdit) ? "patient_set" : "paitent_revoked"
  }

  const handleMenuItemClick = (type) => {
    const itemName = type
    const element_card = document.getElementById(`${itemName}_card`)

    if(itemName != "search" && itemName != "edit" && mainmm.showEdit){
      mainmm.changeCard("info")
      mainmm.setAnchor(itemName)
      return
    }

    if(itemName == "edit"){
      if(em.editingExamine){
        modalInterface.createModal("התראה" , "אתה מנסה לחזור לחיפוש באמצע בדיקה , האם אתה בטוח?" , 
        [{text: "כן", onclick: () => handleEditAlert(true , itemName)},
         {text: "לא", onclick: () => handleEditAlert(false)}])
      } else {
        mainmm.changeCard("edit")
        mainmm.setAnchor(null)
      }
    }

    else if(itemName == "search") {
      if(em.editingExamine){
        modalInterface.createModal("התראה" , "אתה מנסה לחזור לחיפוש באמצע בדיקה , האם אתה בטוח?" , 
        [{text: "כן", onclick: () => handleEditAlert(true, itemName)},
         {text: "לא", onclick: () => handleEditAlert(false)}])
      } else {
        mainmm.changeCard("search")
        ac.setCurrentPatientData(null)
        ac.setPatient(null);
        ac.setCurrentPatientDiseases(null)
        ac.resetExamines()
        mainmm.setAnchor(null)

      }
    }
    else if(element_card){
      mainmm.setAnchor(itemName)
      element_card.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }); 
    } 
  };

  
  function handleEditAlert(condition, itemName){
    if(condition){
      if(itemName == "edit"){
        mainmm.changeCard(itemName)
      } else {
        mainmm.changeCard(itemName)

        ac.setPatient(null);
        ac.setCurrentPatientDiseases(null)
        ac.setCurrentPatientData(null)
        ac.resetExamines()
      }
      mainmm.setAnchor(null)
      modalInterface.closeModal()
      em.setEditExamine(false)
    } else {
      modalInterface.closeModal()
      return
    }
  }


  useEffect(() => {
    setCurrentStyle(handleStyles());
  }, [ac.currentPatient , isMobile]);
  

  return (
    <>
    {!sm.showLogin ?
      <nav className='header no-print col-12'>
          <div className='user_info col-2' >
            {appContext.userData.image_path ? 
            <img onError={(e) => handleAvatarError(e)} src={`${url}/${appContext.userData.image_path}`} className='profile_pic_icon' onClick={() => hanldeImageClick()}></img> :
            <img src="https://via.placeholder.com/50x50" className='profile_pic_icon' onClick={() => hanldeImageClick()}></img>}
            <div className='user_controlers' >
              <span>{appContext.userData.first_name} {appContext.userData.last_name}</span>
              <span onClick={() => utils.logout(appContext)} style={{cursor: 'pointer'}}>התנתק</span>
            </div>
          </div>
          
          <div className={`${currentStyle} user-helper ${isMobile ? 'hide-logo' : null} col-6`}>
            {ac.currentPatientData ? <>
            <div className='user-helper-columns'>
              <label>{ac.currentPatientData.first_name} {ac.currentPatientData.last_name}</label>
              <label>גיל: {utils.calcualteAge(ac.currentPatientData.birthday)}</label>
            </div>
            <div className='user-helper-columns'>
              <label>קופ"ח: {ac.currentPatientData.medical_institute}</label>
              <label>ביטוח: {ac.currentPatientData.insurence_type}</label>
            </div>
            <div className='user-helper-columns'>
                <label>טל: {ac.currentPatientData.phone}</label>
                <label>ת.ז: {ac.currentPatientData.social_number}</label>
            </div>
            <div className='user-helper-columns' style={{cursor: 'pointer'}}>
                {ac.currentPatientDiseases?.length > 0 ? 
                <div className='user-helper-columns' onClick={() => modalInterface.createModal("אבחנות רפאויות", null , null , <DiseasesModal patientDiseases={ac.currentPatientDiseases} />)}>
                    <label style={{color: 'yellow'}}>שימו לב!</label>
                    <label>יש אבחנות רפואיות</label>
                </div> 
                : <label>אין הבחנות רפואיות</label>}
            </div>
              
            </> : null}
             

          </div>
          <div className="header-icons col-4">
            <img className='icon' onClick={() => handleMenuItemClick("search")} src={loyaLogo}></img>
            <div className='sub-icons' onClick={() => handleMenuItemClick("search")}>
              <FontAwesomeIcon  size='lg' className='icon' color='white' icon={faHouse} />
              <label className='icon-text'>חיפוש</label>
            </div>
            <div className={`sub-icons ${currentStyle} ${isMobile ? 'hide-logo' : null}`} onClick={() => handleMenuItemClick("edit")}>
              <FontAwesomeIcon  size='lg' className='icon' color='white' icon={faPenToSquare} />
              <label className='icon-text'>עריכה</label>
            </div>
            <div className={`sub-icons ${currentStyle} ${isMobile ? 'hide-logo' : null}`} onClick={() => handleMenuItemClick("refraction")}>
              <FontAwesomeIcon  size='lg' className='icon' color='white' icon={faMagnifyingGlass} />
              <label className='icon-text'>רפרקציה</label>
            </div>
            <div className={`sub-icons ${currentStyle} ${isMobile ? 'hide-logo' : null}`} onClick={() => handleMenuItemClick("glasses")}>
              <FontAwesomeIcon  size='lg' className='icon' color='white' icon={faEye} />
              <label className='icon-text'>משקפיים</label>
            </div>
            <div className={`sub-icons ${currentStyle} ${isMobile ? 'hide-logo' : null}`} onClick={() => handleMenuItemClick("lenses")}>
              <FontAwesomeIcon  size='lg' className='icon' color='white' icon={faCircleDot} />
              <label className='icon-text'>ע.מגע</label>
            </div>
            <div className={`sub-icons ${currentStyle} ${isMobile ? 'hide-logo' : null}`} onClick={() => handleMenuItemClick("files")}>
              <FontAwesomeIcon  size='lg' className='icon' color='white' icon={faFile} />
              <label>קבצים</label>
            </div>
          </div>
            
      </nav>
      : null}
    </>
  );
}
export default observer(Header)