import React, { useContext, useEffect , useState , useRef} from 'react'
import GenCard from '../../components/cards/gen_card/GenCard'
import { AppContext } from '../../context/AppContext'
import { useMediaQuery } from 'react-responsive';
import SearchCard from '../../components/cards/search_card/SearchCard'
import SideMenu from '../../components/sidemenu/SideMenu'
import NewClientCard from '../../components/cards/new_client_card/NewClientCard'
import './MainMenuPage.css'
import { observer } from 'mobx-react'
import { mainmm } from '../../mobx/MainMenuManager'
import { ac } from '../../mobx/AppContoller'
import PatinetExamines from '../patient_exmaines/PatinetExamines'
import EditClientCard from '../../components/cards/edit_client_card/EditClientCard'
import api from '../../components/api/AxiosConfig'
import LoadingSpinner from '../../components/loading_spinner/LoadingSpinner';
import { fsm } from '../../mobx/FormSuggestionManager';
import SocketService from '../../components/sockets/SocketService';
import { modalInterface } from '../../mobx/ModalInterface';

const MainMenuPage = observer (() => {
  const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` });

  async function loadSuggestions(){
    try {
      const response = await api.get("/main/get-form-suggestions")
      fsm.setSuggestions(response.data.suggestions)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    loadSuggestions();

    const interval = setInterval(() => {
        loadSuggestions();
    }, 1000 * 60 * 60); 

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    SocketService.onPatientsListUpdate()
    return () => {
      SocketService.offPatientsListUpdate();
    }
  },[])

  return (
    <>
      <div className='main_menu container-fluid col-12 col-xl-12' style={{position: 'relative'}}>
        
        {mainmm.showSearch ? <SearchCard isMobile={isMobile} /> : null }
        {mainmm.showNew ? <NewClientCard /> : null}
        {mainmm.showEdit ? <EditClientCard /> : null}
        {mainmm.showInfo ? <PatinetExamines /> : null}
      </div>
    <SideMenu className="col-2"/>
    </>
  )
}) 

export default MainMenuPage