import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import more_info from '../../../assets/images/more_info_dots_2nd.png'
import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faInfo } from '@fortawesome/free-solid-svg-icons';

function ExamineDropDown({examine , onCopy , handleAutofill}) {
    const CustomToggle = forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {<FontAwesomeIcon icon={faCircleInfo} size='xl' />}
        </a>
      ));

  return (
        <Dropdown  drop="down-centered" autoClose="outside inside">
        <Dropdown.Toggle
            as={CustomToggle}
            variant="success"
            id="dropdown-basic"
        >
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {onCopy ? 
            <Dropdown.Item onClick={() => console.log('alert')}>העתקה מכרטיס אחרון</Dropdown.Item> : null}
            
            {handleAutofill ? 
            <Dropdown.Item onClick={() => handleAutofill()}>העתקה מכרטיס רפרקציה </Dropdown.Item> : null}
        </Dropdown.Menu>
        </Dropdown>
      );
}

export default ExamineDropDown