// ProfileCard.js
import React, { useContext, useEffect , useState} from 'react';
import './ProfileCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faEye, faMagnifyingGlass , faUser } from '@fortawesome/free-solid-svg-icons';

import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { hover } from '@testing-library/user-event/dist/hover';
import { ac } from '../../../mobx/AppContoller';
import { mainmm } from '../../../mobx/MainMenuManager';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';
import url from '../../../context/Url';
import { modalInterface } from '../../../mobx/ModalInterface';
import Svg from '../../../utils/svg';
import utils from '../../../utils/utils';
import { AppContext } from '../../../context/AppContext';
import api from '../../api/AxiosConfig'
import LoadingSpinner from '../../loading_spinner/LoadingSpinner';

import AvatarPlaceHolder from '../../../assets/svgs/avatar-place-holder.svg'
import MalePlaceHolder from '../../../assets/svgs/male-place-holder.svg'
import FemalePlaceHolder from '../../../assets/svgs/female-place-holder.svg'

const ProfileCard = ({ hoveredPatient}) => {
  const {appContext} = useContext(AppContext)
  const [lastExamineDate , setLastExamineDate] = useState()
  const [loading , setLoading] = useState(false)

  function onExamimeClick(examine){
    ac.setPatient(hoveredPatient.id)
    ac.setCurrentPatientData(hoveredPatient)
    ac.setNewPatientId(null)
    mainmm.changeCard("info")
    modalInterface.closeModal()
  }

  async function getPatinetExamines(){
    try {
        setLoading(true)
        const response = await api.post('/main/get-patient', {
            user_id: appContext.userData.id,
            patient_id: hoveredPatient.id,
        })

        if(response.data.status){
          const examines = response.data.examines
          setLoading(false)
          return examines
        }
        setLoading(false)
    } catch (e) {
        console.log(e);
    }
}

  async function calculateLastExamine(){
    const patientExamines = await getPatinetExamines()
    if(patientExamines){
      const lastExamine = utils.calculateLastVisit(patientExamines)
      if(lastExamine.status){
        setLastExamineDate(utils.fromatDate(lastExamine.lastExam))
      } else {
        setLastExamineDate(lastExamine.lastExam)
      }
    }
    
  }

   
  function handleAvatarError(e , gender){
    e.target.onerror = null;
    if (gender == "זכר") {
         e.target.src = MalePlaceHolder
    } else if (gender == "נקבה") {
        e.target.src = FemalePlaceHolder
    } else {
        e.target.src = AvatarPlaceHolder
    }
    
}

  function handleAvatar(image_path, gender){
    if(image_path){
        return (
            <img onError={(e) => handleAvatarError(e , gender)} style={{width: '150px' , height:'150px' , objectFit: 'cover'}} alt="Profile" className="profile-image" src={`${url}/${image_path}`}></img>
        )
    } else {
        if(gender == "זכר"){
            return (
              <Svg  style={{width: '150px' , height:'150px' , objectFit: 'cover'}} alt="Profile" className="profile-image" fileName={'avatar-male'}/>
            )
        }
        else if(gender == "נקבה"){
            return (
              <Svg  style={{width: '150px' , height:'150px' , objectFit: 'cover'}} alt="Profile" className="profile-image" fileName={'avatar-female'}/>
            )
        } else {
            return (
              <Svg  style={{width: '150px' , height:'150px' , objectFit: 'cover'}} alt="Profile" className="profile-image" fileName={'avatar-general'}/>
            )
        }
    }
}

  useEffect(() => {
    calculateLastExamine()
  },[hoveredPatient])

  return (
    <div className="card profile-card" style={{position: 'relative'}}>
      <LoadingSpinner visible={loading} />
      <div className='more_options'>
        <DropDownMenu hoveredPatient={hoveredPatient}/>
      </div>
      <div className='profile-card-top'>
        {handleAvatar(hoveredPatient.image_path , hoveredPatient.gender)}
        <h3>{hoveredPatient.first_name} {hoveredPatient.last_name}</h3>
        <p>תאריך בדיקה אחרונה -  {lastExamineDate}</p>
      </div>
      <div className='card-body profile-card-mid'>
      <div>
          <label>מס' לקוח</label>
          <div>{hoveredPatient.id}</div>
        </div>
        <div>
          <label>תז</label>
          <div>{hoveredPatient.social_number}</div>
        </div>
        <div>
          <label>טלפון ראשי</label>
          <div>{hoveredPatient.phone}</div>
        </div>
        <div>
          <label>כתובת</label>
          <div>{hoveredPatient.address}</div>
        </div>
      </div>
     <div className='profile-card-bot'>
     <div>
        <div className="profile-card-icons" style={{backgroundColor: "#98FB98"}}>
          <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => onExamimeClick("refraction")} size={'xl'} icon={faMagnifyingGlass} />
        </div>
        <label>כרטיס רפרקציה</label>
      </div>
      <div>
        <div className="profile-card-icons" style={{backgroundColor: "lightblue"}}>
          <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => onExamimeClick("glasses")} enableBackground={"#e57373"} size={'xl'} icon={faEye} />
        </div>
        <label>כרטיס משקפיים</label>
      </div>
      <div>
        <div className="profile-card-icons" style={{backgroundColor: "lightpink"}}>
          <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => onExamimeClick("lenses")} size={'xl'} icon={faCircleDot} />
        </div>
        <label>כרטיס ע. מגע</label>
      </div>
      
     </div>
    </div>
  );
};

export default ProfileCard;
