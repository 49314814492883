import React, { useContext, useRef, useState , useEffect} from 'react'
import api from '../../api/AxiosConfig'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './LoginForm.css'

import { AppContext } from '../../../context/AppContext';
import { modalInterface } from '../../../mobx/ModalInterface';
import messages from '../../../utils/messages/messages';

function PasswordForm(props) {
    const {appContext} = useContext(AppContext)

    const [userCredentials , setUserCredentials] = useState({
        login_credentails: '',
        password: '',
        user_code: null
    })

    const [recpathca , setRecapthca] = useState({
        isRobot: false,
        visible: false,
        captchaRef: useRef()
    })

    async function handleLogin(event){
        event.preventDefault()
        try {
            const response = await api.post('/auth/login_password' , {
                login_credentails: appContext.userData.username,
                password: userCredentials.password,
                user_code: null,
                captcha: recpathca.visible ? recpathca.captchaRef?.current.getValue() : null
            })
            if(response.data.status){
                if(response.data.tf_auth){
                    props.changeForm("otp")
                } else {
                    window.location.href = "/main_menu"
                }
            } else {
                if(response.data.captcha){
                    setRecapthca(prev => ({
                        ...prev,
                        visible: true
                    }))
                }
                modalInterface.createModal("שגיאה" , messages.errors[response.data.message])
            }
           
        } catch (e) {
            console.log(e);
        }
    }

    function handleTextChange(event){
        setUserCredentials(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }))
    }

    function handleCaptchaChange() {
        setRecapthca(prev => ({
            ...prev,
            isRobot: false
        }))
    }

    function handleRecaptchaExpire() {
        if (recpathca.captchaRef) {
            recpathca.captchaRef?.current.reset()
        }
        setRecapthca(prev => ({
            ...prev,
            isRobot: true
        }))
    }

  return (
    <Form className='login_form_container' onSubmit={handleLogin}>

        <Form.Group  className="mb-3" controlId="formBasicUsername">
            <Form.Control className='login_form_input' type='password' name='password' placeholder="סיסמא" onChange={handleTextChange} />
        </Form.Group>

        <Form.Group className="mb-3" style={{display: recpathca.visible ? 'flex' : 'none' , justifyContent: 'center'}}>
            {recpathca.visible ? <ReCAPTCHA ref={recpathca.captchaRef} onExpired={handleRecaptchaExpire} onChange={handleCaptchaChange} id="recaptchaLogIn" sitekey='6LeOqMIoAAAAAEWgmVPPR3HkmICHREV3AlFScUgu' /> : null}
        </Form.Group>

        <Form.Group className="mb-3" style={{display: 'none'}}>
            <Form.Label>תעודת זהות</Form.Label>
            <Form.Control autoComplete={false} type="text" placeholder="ת.ז" name='user_code' onChange={handleTextChange}/>
        </Form.Group>

        <Form.Group style={{justifyContent: 'flex-end' , display: 'flex' , margin: 0}}>
            <Button style={{width: "10rem" , height: '5rem' , fontSize: '2.5rem'}} variant="primary" type="submit">הבא</Button>
        </Form.Group>

    </Form>
  )
}

export default PasswordForm