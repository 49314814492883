import { faC, faCog, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

function DropDownSpinner({visible = true ,options = [] , className}) {
    
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <FontAwesomeIcon
        color='#fff'
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          icon={faEllipsisV}
        >
          {children}
          &#x25bc;
        </FontAwesomeIcon>
      ));

  return (
    <Dropdown hidden={!visible || options.length == 0} className={className}>
        <Dropdown.Toggle variant='success' as={CustomToggle} >
            
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {options ? options.map((item , index) => {
                return(
                    <Dropdown.Item style={{textAlign: 'right'}} key={`drop_down_${index}`} onClick={item.onClick}>
                        {item.title}
                    </Dropdown.Item>
                )
            }) : null}
        </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropDownSpinner