import React , {useContext, useEffect, useState} from 'react'
import { Button, Form} from 'react-bootstrap';
import api from '../../api/AxiosConfig'
import { AppContext } from '../../../context/AppContext';
import messages from '../../../utils/messages/messages';
import { modalInterface } from '../../../mobx/ModalInterface';
import upload_utils from '../../../utils/upload_utils';
import './UserImageModal.css'
import ImageCropper from '../../image_cropper/ImageCropper';

function UserImageModal({croppedFile}) {
    const {appContext} = useContext(AppContext)
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [message , setMessage] = useState(null)
    const [fileName , setFileName] = useState(null) 

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
    
        // Generate preview URL for image/video
        const fileUrl = URL.createObjectURL(droppedFile);
        setPreviewUrl(fileUrl);
        }
    };
    
    const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    };

    const handleFileChange = (e) => {
    setMessage(null)
    setFileName(null)
    const uploadedFile = e.target.files[0];
    const fileValid = upload_utils.validateImageFile(uploadedFile)
    if(!fileValid){
        setMessage("אינך יכול להעלות קובץ מסוג זה!")
        return
    }
    modalInterface.createModal("חיתוך" , null , null , <ImageCropper file={uploadedFile} onSave={handleSave} onAbort={handleAbort} />)
    };

    const renderPreview = () => {
    if(!file){
        return (
        <div 
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragLeave={handleDragOver}
        style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
        >
        Drag and drop a file here
        </div>
        )
        
    }
    if (!previewUrl) {
        return <div style={{ maxWidth: '200px', maxHeight: '200px' }}>Preview Not Supported</div>
    }

    if (file && file.type.match('image.*')) {
        return <img src={previewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />;
    }
    else {
        return (
        <div style={{ maxWidth: '200px', maxHeight: '200px' }}>Preview Not Supported</div>
        )
    }
    };
    
    const handleSave = (croppedImageUrl) => {
        modalInterface.createModal("העלאת תמונה"  , null , null , <UserImageModal croppedFile={croppedImageUrl}  />)
    }

    const handleAbort = (file) => {
        modalInterface.closeModal()
    };

    async function handleUpload(){
        if(!file){
            setMessage("לא נמצא קובץ להעלאה!")
            return
        }
        setMessage(null)
    try {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('user_id', appContext.userData.id)
        const response = await api.post('/auth/upload_user_image', formData)
        
        if(response.data.status){
            const path = response.data.path
            appContext.setUserData(prev => ({
                ...prev,
                image_path: path
            }))
            modalInterface.closeModal()
        } else {
            setMessage(messages.errors[response.data.message])
        }
    } catch (e) {
        console.log(e);
    }
    }

    useEffect(() => {
        if(croppedFile){
            console.log(croppedFile);
            setFile(croppedFile);
            setFileName(croppedFile.name)
            const fileType = croppedFile.type;
            if (fileType.match('image.*') || fileType.match('video.*') || fileType === 'application/pdf') {
            setPreviewUrl(URL.createObjectURL(croppedFile));
            } else {
            setPreviewUrl(null);
            }
        }
    },[])
      
  return (
    <div className='col-12 row d-flex align-items-center justify-content-center'>
        <div className='col-8'>
            <Form.Control type='file' onChange={handleFileChange} accept="image/*"></Form.Control>
            {fileName ? <div className='fileName'>{fileName}</div> : null}
        </div>
        <div className='col-4'>
            {renderPreview()}
        </div>
        {message ? <p style={{color: 'red'}}>{message}</p> : null}
        <Button onClick={() => handleUpload()} style={{width: "20%" , padding: "6px" , marginTop: "20px"}}>העלאה</Button>
        </div>
  )
}

export default UserImageModal