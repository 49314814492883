import { observable, action, makeObservable } from "mobx";

class ScreenManager {
    showLogin = true
    showMainMenu = false
    showTest = false

    constructor(){
        makeObservable(this , {
            showLogin: observable,
            showMainMenu: observable,
            showTest: observable,
            changeScreen: action,
            hideScreens: action
        })
    }

    changeScreen(screen){
        this.hideScreens()
        switch (screen){
            case "login":
                this.showLogin = true
                break;
            case "main_menu":
                this.showMainMenu = true
                break;
            case "test":
                this.showTest = true
                break;
        }
    }

    hideScreens(){
        this.showLogin = false
        this.showMainMenu = false
        this.showTest = false
    }

}

export const sm = new ScreenManager();
