import React, { useEffect , useState , useRef } from 'react'
import "./SearchCard.css"
import ProfileCard from '../profile_card/ProfileCard'
import CustomDataTable from '../../tables/data_table/CustomDataTable'
import api from '../../api/AxiosConfig'
import logo from '../../../assets/images/loya_logo.png'
import { mainmm } from '../../../mobx/MainMenuManager'
import url from '../../../context/Url'
import { ac } from '../../../mobx/AppContoller'
import LoadingSpinner from '../../loading_spinner/LoadingSpinner'
import { observer  } from "mobx-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faRefresh, faUser } from '@fortawesome/free-solid-svg-icons';
import {toJS} from 'mobx'
import AvatarPlaceHolder from '../../../assets/svgs/avatar-place-holder.svg'
import MalePlaceHolder from '../../../assets/svgs/male-place-holder.svg'
import FemalePlaceHolder from '../../../assets/svgs/female-place-holder.svg'
import Svg from '../../../utils/svg'
import { modalInterface } from '../../../mobx/ModalInterface'
import utils from '../../../utils/utils'


function SearchCard({isMobile}) {
    const [loading , setLoading] = useState(false)
    const [hoveredPatient , setHoverPatient] = useState(null)
    const hoveredRowRef = useRef(null)
    const [selectedRowId , setSelectedRowId] = useState()
    const [resetSearchBar , setResetSearchBar] = useState(false)

    const handleRowClick = (row) => {
        const index = binarySearch(ac.allPatients , row.id )
        console.log(index);
        ac.setCurrentIndex(index)
        setSelectedRowId(row.id)
        ac.setNewPatientId(null)
        if(isMobile){
            modalInterface.createModal("Profile" , null , null , <ProfileCard isMobile={isMobile} hoveredPatient={row} />)
        } else {
            hoveredRowRef.current = row
            setHoverPatient(row)
        }
        
    };


    const handleRowDoubleClick = (row) => {
        const index = binarySearch(ac.allPatients , row.id)
        ac.setCurrentIndex(index)
        ac.setNewPatientId(null)
        ac.setCurrentPatientData(row)
        ac.setPatient(row.id)
        mainmm.changeCard("edit")
        modalInterface.closeModal()
    }


    function binarySearch(arr, x) {
        let start = 0; // Start from the beginning of the array
        let end = arr.length - 1; // End at the last element of the array
        
        while (start <= end) {
            let mid = Math.floor((start + end) / 2);
            
            if (arr[mid].id === x) {
                return mid; // Found x, return index
            } else if (arr[mid].id > x) {
                start = mid + 1; // Search in the right half
            } else {
                end = mid - 1; // Search in the left half
            }
        }
        
        return -1; // x not found in the array
    }
    


    async function getPatients(force = false){
        if(!force && ac.allPatients.length > 0){
            return
        }
        try {
            setLoading(true)
            const response = await api.get('/main/get-all-patients')
            if(response){
                ac.setAllPatients(response.data.patients)
            }
            setResetSearchBar(!resetSearchBar)
            setLoading(false)
        } catch (e) {
            console.log(e);
        }
    }
    
    function handleAvatarError(e , gender){
        e.target.onerror = null;
        if (gender == "זכר") {
             e.target.src = MalePlaceHolder
        } else if (gender == "נקבה") {
            e.target.src = FemalePlaceHolder
        } else {
            e.target.src = AvatarPlaceHolder
        }
        
    }

    function handleAvatar(image_path, gender){
        if(image_path){
            return (
                <img onError={(e) => handleAvatarError(e , gender)} style={{width: '30px' , height: '30px', borderRadius: "50%"}} className='client_table_icon' src={`${url}/${image_path}`}></img>
            )
        } else {
            if(gender == "זכר"){
                return (
                    <Svg  style={{width: '30px' , height: '30px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-male'}/>
                )
            }
            else if(gender == "נקבה"){
                return (
                    <Svg  style={{width: '30px' , height: '30px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-female'}/>
                )
            } else {
                return (
                    <Svg  style={{width: '30px' , height: '30px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-general'}/>
                )
            }
        }
    }

    const columns = [
        {
            name: "מס' לקוח",
            selector: row => row.id,
            omit: true,
        },
        {
            name: "ת.ז",
            selector: row => row.social_number,
            sortable: true
        },
        {
            name: "שם מלא",
            selector: row =>  
                <div style={{zIndex: 100}} onClick={() => handleRowClick(row)} onDoubleClick={() => handleRowDoubleClick(row)}>
                    {handleAvatar(row.image_path , row.gender)}
                    {row.first_name + " " + row.last_name}
                </div>
            ,
            sortable: true,
            sortFunction: (rowA, rowB) => {
                let nameA = rowA.first_name + " " + rowA.last_name;
                let nameB = rowB.first_name + " " + rowB.last_name;
                return nameA.localeCompare(nameB);
            },
            
        },
        {
            name: "מין",
            selector: row => row.gender,
        },
        {
            name: "טלפון ראשי",
            selector: row => row.phone,
            sortable: true
        },
        {
            name: "קופת חולים",
            selector: row => row.medical_institute,
            sortable: true
        },
        {
            name: 'יישוב',
            selector: row => row.city,
            sortable: true
        }]

    useEffect(() => {
        getPatients()
    },[mainmm])
    
    useEffect(() => {
        if(ac.newPatientInfo){
            setHoverPatient(ac.newPatientInfo)
        }
       
    },[])

    // useEffect(() => {

    // },[ac.allPatients])


  return (
    <div className='row client_container '>
        
        <div className='row justify-content-evenly'>
            
            <section className='client_right col-xs-3 col-xl-7 col-lg-6 col-md-12 '>
            <LoadingSpinner visible={loading} />
                <div className='client_right_top'>
                    <div className='client_header'>
                        <h2>לקוחות</h2>
                        <FontAwesomeIcon onClick={() => getPatients(true)} style={{cursor: 'pointer'}} icon={faRefresh}/>
                    </div>
                </div>
                <div className='table-responsive' style={{position: 'relative'}}>
                
                    <CustomDataTable columns={columns} data={ac.allPatients} handleRowClick={handleRowClick} handleRowDoubleClick={handleRowDoubleClick} isMobile={isMobile} selectedRowId={selectedRowId} resetSearchBar={resetSearchBar}/>
                </div>
            </section>
            <section className='client_left col-lg-5 col-xl-4 col-md-6 '>
                {hoveredPatient ? <ProfileCard hoveredPatient={hoveredPatient} isMobile={isMobile}/> : <div className='loya_logo'><img  src={logo}/></div>}
            </section>
        </div>
    </div>
  )
}

export default observer(SearchCard)