import { fsm } from "../../mobx/FormSuggestionManager"
import { toJS } from "mobx"

const tableInfo = {
    refraction: {
        tHeaders: {
            oldRxHeader: {
                0: {
                    title: "Distance",
                    values: ['Sph' , 'Cyl', 'Ax.'],
                    merge: 1
                },
                1: {
                    title: 'Prism Distance',
                    values: ['H.', 'Base', 'V.' , 'Base'],
                    merge: 1
                },
                2: {
                    title: 'VA',
                    values: [],
                    merge: 2
                },
                3: {
                    title: '',
                    values: ['Add N.' , 'Add I.'],
                    merge: 1
                },
                4: {
                    title: '',
                    values: [ 'High'],
                    merge:1
                },
                5: {
                    title: 'PD',
                    values: [],
                    merge: 2
                }
            },
            subjHeader: {
            0: {
                title: '',
                values: ['SC'],
                merge: 1
            },
            1: {
                title: "Distance",
                values: ['Sph' , 'Cyl', 'Ax.'],
                merge: 1
            },
            2: {
                title: 'Prism Distance',
                values: ['H.', 'Base', 'V.' , 'Base'],
                merge: 1
            },
            3: {
                title: 'VA',
                values: [],
                merge: 2
            },
            4: {
                title: '',
                values: ['Add N.' , 'Add i'],
                merge: 1
            },
            5: {
                title: '',
                values: ['High'],
                merge:1 ,
            },
            6: {
                title: 'PD',
                values: [],
                merge: 2
            }
            },
            objHeader: {
                0: {
                    title: '',
                    values: ['Sph' , 'Cyl' , 'Ax'],
                    merge: 1
                },
                1: {
                    title: 'PD',
                    values: [],
                    merge: 2
                }
            }
        },
        tColumns: {
            oldRxColumns: {
                1: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                2: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },
                4: {
                    name: 'h',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                5: {
                    name: 'base',
                    isEdit: false,
                    isDropdown: ['In' , 'Out'],
                    min: 0,
                    max: 120,
                    jumps: "6",

                },
                6: {
                    name: 'v',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                7: {
                    name: 'base2',
                    isEdit: false,
                    isDropdown: ['Up' , 'Down'],
                    min: 0,
                    max: 120,
                    jumps: "12",
                },
                8: {
                    name: 'va',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3

                },
                9: {
                    name: 'va2',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: true,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    
                },
                10: {
                    name: 'add_n',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                11: {
                    name: 'add_i',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                12: {
                    name: 'high',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.5,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                13: {
                    name: 'pd',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                },
                14: {
                    name: 'pd2',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: true,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    duplicate: true,
                    diffAlert: 0,
                    decimal: 2,
                },
                
            },
            subjColumns: {
                1: {
                    name: 'sc',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3
                },
                2: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                4: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },
                5: {
                    name: 'h',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                6: {
                    name: 'base',
                    isEdit: false,
                    isDropdown: ['In' , 'Out'],
                    min: 0,
                    max: 120,
                    jumps: "6",

                },
                7: {
                    name: 'v',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                8: {
                    name: 'base2',
                    isEdit: false,
                    isDropdown: ['Up' , 'Down'],
                    min: 0,
                    max: 120,
                    jumps: "12",
                },
                9: {
                    name: 'va',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3

                },
                10: {
                    name: 'va2',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: true,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    
                },
                11: {
                    name: 'add_n',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                12: {
                    name: 'add_i',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                13: {
                    name: 'high',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.5,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                14: {
                    name: 'pd',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                },
                15: {
                    name: 'pd2',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: true,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    duplicate: true,
                    diffAlert: 0,
                    decimal: 2,
                },
                
            },
            objColumns: {
                1: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                2: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },   
                4: {
                    name: 'pd',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                }, 
                5: {
                    name: 'pd2',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: true,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    duplicate: true,
                    diffAlert: 0,
                    decimal: 2,
                }, 
            }
        },
        oldRxInitialValues: {
            r: {
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                add_n: '',
                add_i: '',
                high: '',
                pd: '',
                pd2: ''
            },
            l: {
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                add_n: '',
                add_i: '',
                high: '',
                pd: '',
                pd2: ''
            }
        },
        subjInitialValues: {
            r: {
                sc: '',
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                add_n: '',
                add_i: '',
                high: '',
                pd: '',
                pd2: ''
            },
            l: {
                sc: '',
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                add_n: '',
                add_i: '',
                high: '',
                pd: '',
                pd2: ''
            }
        },
        objInitialValues: {
            r: {
                sph: '',
                cyl: '',
                ax: '',
                pd: '',
                pd2: ''
            },
            l: {
                sph: '',
                cyl: '',
                ax: '',
                pd: '',
                pd2: ''
            }
        }
    },
    glasses: {
        tHeaders: {
            distanceHeaders: {
                0: {
                    title: '',
                    values: ['SC'],
                    merge: 1
                },
                1: {
                    title: "Distance",
                    values: ['Sph' , 'Cyl', 'Ax.'],
                    merge: 1
                },
                2: {
                    title: 'Prism Distance',
                    values: ['H.', 'Base', 'V.' , 'Base'],
                    merge: 1
                },
                3: {
                    title: 'VA',
                    values: [],
                    merge: 2
                },
                4: {
                    title: '',
                    values: ['Add N.' , 'Add I.'],
                    merge: 1
                },
                5: {
                    title: '',
                    values: [ 'High'],
                    merge:1
                },
                6: {
                    title: 'PD',
                    values: [],
                    merge: 2
                }
            },
            nearHeader: {
            0: {
                title: "",
                values: ['Sph' , 'Cyl', 'Ax.'],
                merge: 1
            },
            1: {
                title: 'Prism Distance',
                values: ['H.', 'Base', 'V.' , 'Base'],
                merge: 1
            },
            2: {
                title: 'VA',
                values: [],
                merge: 2
            },
            3: {
                title: 'PD',
                values: [],
                merge: 2
            }

            },
            otherHeader: {
                0: {
                title: "",
                values: ['Sph' , 'Cyl', 'Ax.'],
                merge: 1
                },
                1: {
                    title: 'Prism Distance',
                    values: ['H.', 'Base', 'V.' , 'Base'],
                    merge: 1
                },
                2: {
                    title: 'VA',
                    values: [],
                    merge: 2
                },
                3: {
                    title: 'PD',
                    values: [],
                    merge: 2
                }
            }
        },
        tColumns: {
            distacneColumns: {
                1: {
                    name: 'sc',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3
                },
                2: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                4: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },
                5: {
                    name: 'h',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                6: {
                    name: 'base',
                    isEdit: false,
                    isDropdown: ['In' , 'Out'],
                    min: 0,
                    max: 120,
                    jumps: "6",

                },
                7: {
                    name: 'v',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                8: {
                    name: 'base2',
                    isEdit: false,
                    isDropdown: ['Up' , 'Down'],
                    min: 0,
                    max: 120,
                    jumps: "12",
                },
                9: {
                    name: 'va',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3

                },
                10: {
                    name: 'va2',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: true,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    
                },
                11: {
                    name: 'add_n',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                12: {
                    name: 'add_i',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                13: {
                    name: 'high',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.5,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                14: {
                    name: 'pd',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                },
                15: {
                    name: 'pd2',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: true,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    duplicate: true,
                    diffAlert: 0,
                    decimal: 2,
                },
                
            },
            nearColumn: {
                1: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                2: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },
                4: {
                    name: 'h',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                5: {
                    name: 'base',
                    isEdit: false,
                    isDropdown: ['In' , 'Out'],
                    min: 0,
                    max: 120,
                    jumps: "6",

                },
                6: {
                    name: 'v',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                7: {
                    name: 'base2',
                    isEdit: false,
                    isDropdown: ['Up' , 'Down'],
                    min: 0,
                    max: 120,
                    jumps: "12",
                },
                8: {
                    name: 'va',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3

                },
                9: {
                    name: 'va2',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: true,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    
                },
                10: {
                    name: 'pd',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                },
                11: {
                    name: 'pd2',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: true,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    duplicate: true,
                    diffAlert: 0,
                    decimal: 2,
                },
                
            },
            otherColumn: {
                1: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                2: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },
                4: {
                    name: 'h',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                5: {
                    name: 'base',
                    isEdit: false,
                    isDropdown: ['In' , 'Out'],
                    min: 0,
                    max: 120,
                    jumps: "6",

                },
                6: {
                    name: 'v',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.05,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                7: {
                    name: 'base2',
                    isEdit: false,
                    isDropdown: ['Up' , 'Down'],
                    min: 0,
                    max: 120,
                    jumps: "12",
                },
                8: {
                    name: 'va',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3

                },
                9: {
                    name: 'va2',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: true,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    
                },
                10: {
                    name: 'pd',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                },
                11: {
                    name: 'pd2',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 150,
                    jumps: 0.5,
                    merge: true,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    duplicate: true,
                    diffAlert: 0,
                    decimal: 2,
                },
                
            },
        },
        distanceInitailValues: {
            r: {
                sc: '',
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                add_n: '',
                add_i: '',
                high: '',
                pd: '',
                pd2: ''
            },
            l: {
                sc: '',
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                add_n: '',
                add_i: '',
                high: '',
                pd: '',
                pd2: ''
            }
        },
        nearOtherInitialValues: {
            r: {
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                pd: '',
                pd2: ''
            },
            l: {
                sph: '',
                cyl: '',
                ax: '',
                h: '',
                base: '',
                v: '',
                base2: '',
                va: '',
                va2: '',
                pd: '',
                pd2: ''
            }
        }
    },
    lenses: {
        tHeaders: {
            corneaHeader: {
                0: {
                    title: '',
                    values: ['rH' , 'rV' , 'Avr' , 'Cyl' , 'AxH'],
                    merge: 1
                }
            },
            lensesHeader: {
                0: {
                    title: '',
                    values: ['Type'],
                    merge: 1
                },
                1: {
                    title: '',
                    values: ['ספק'],
                    merge: 1
                },
                2: {
                    title: '',
                    values: ['Brand'],
                    merge: 1
                },
                3: {
                    title: '',
                    values: ['Diam.'],
                    merge: 1
                },
                4: {
                    title: '',
                    values: ['B.C'],
                    merge: 1
                },
                5: {
                    title: 'Distance',
                    values: ['Sph' , 'Cyl' , 'Ax'],
                    merge: 1
                },
                6: {
                    title: 'VA',
                    values: [],
                    merge: 2
                },
                7: {
                    title: '',
                    values: ['Add N.'],
                    merge: 1
                },
                8: {
                    title: '',
                    values: ['Material'],
                    merge: 1
                },
                9: {
                    title: '',
                    values: ['מלל חופשי'],
                    merge: 1
                }
            }
        },
        tColumns: {
            corneaColumns: {
                1: {
                    name: 'rh',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.01,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                2: {
                    name: 'rv',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.01,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                3: {
                    name: 'avr',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                4: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                5: {
                    name: 'axh',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.5,
                    merge: false,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                }

            },
            lensesColumns: {
                1: {
                    name: 'table_type',
                    isEdit: false,
                    isDropdown: [],
                    overrideDropdown: true,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex:  /^[0-9.a-zA-Zא-ת]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    className: 'print-large'
                },
                2: {
                    name: 'supplier',
                    isEdit: false,
                    isDropdown: [],
                    overrideDropdown: true,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex:  /^[0-9.a-zA-Zא-ת]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    className: 'print-large'
                },
                3: {
                    name: 'brand',
                    isEdit: false,
                    isDropdown: [],
                    overrideDropdown: true,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex:  /^[0-9.a-zA-Zא-ת]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                    className: 'print-large'
                },
                4: {
                    name: 'diam',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                5: {
                    name: 'bc',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2
                },
                6: {
                    name: 'sph',
                    isEdit: true,
                    isDropdown: null,
                    min: -25,
                    max: 25,
                    jumps: 0.25,
                    regex:  /^[0-9Pp.-]+$/,
                    polarity: 'both',
                    diffAlert: 3,
                    decimal: 2
                },
                7: {
                    name: 'cyl',
                    isEdit: true,
                    isDropdown: null,
                    min: -999,
                    max: 0,
                    jumps: 0.25,
                    regex:  /^[0-9.-]+$/,
                    polarity: 'negative',
                    diffAlert: 3,
                    decimal: 2
                },
                8: {
                    name: 'ax',
                    isEdit: true,
                    isDropdown: null,
                    min: 1,
                    max: 180,
                    jumps: 1,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 0,
                    decimal: 0
                },
                9: {
                    name: 'va',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3

                },
                10: {
                    name: 'va2',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: true,
                    regex: /^[0-9.a-zA-Z\+\-]+$/,
                    polarity: 'text',
                    diffAlert: 3,
                },
                11: {
                    name: 'add_n',
                    isEdit: true,
                    isDropdown: null,
                    min: 0,
                    max: 999,
                    jumps: 0.25,
                    regex:  /^[0-9.]+$/,
                    polarity: 'positive',
                    diffAlert: 3,
                    decimal: 2,
                    duplicate: true
                },
                12: {
                    name: 'material',
                    isEdit: false,
                    isDropdown: [],
                    overrideDropdown: true,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex:  /^[0-9.a-zA-Zא-ת]+$/,
                    polarity: 'text',
                    diffAlert: 3
                },
                13: {
                    name: 'open_text',
                    isEdit: true,
                    isDropdown: null,
                    min: null,
                    max: null,
                    jumps: 1,
                    merge: false,
                    regex:  /^[0-9.a-zA-Zא-ת\+\-\s]+$/,
                    polarity: 'text',
                    diffAlert: 3
                }
                
            }
        },
        corneaInitalValues: {
            r: {
                rh: '',
                rv: '',
                avr: '',
                cyl: '',
                axh: ''
            },
            l: {
                rh: '',
                rv: '',
                avr: '',
                cyl: '',
                axh: ''
            }
        },
        lensesInitialValues: {
            r: {
                table_type: '',
                supplier: '',
                brand: '',
                diam: '',
                bc: '',
                sph: '',
                cyl: '',
                ax: '',
                va: '',
                va2: '',
                add_n: '',
                material: '',
                open_text: ''
            },
            l: {
                table_type: '',
                supplier: '',
                brand: '',
                diam: '',
                bc: '',
                sph: '',
                cyl: '',
                ax: '',
                va: '',
                va2: '',
                add_n: '',
                material: '',
                open_text: ''
            }
        }
    }   
}

export default tableInfo