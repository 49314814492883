import React, { useContext, useRef, useState , useEffect} from 'react'
import api from '../../api/AxiosConfig'
import ReCAPTCHA from 'react-google-recaptcha'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './LoginForm.css'
import { AppContext } from '../../../context/AppContext.js';

import { sm } from '../../../mobx/ScreenManager.js';
import { modalInterface } from '../../../mobx/ModalInterface.js';
import messages from '../../../utils/messages/messages.js';

function OtpForm(props) {
    const {appContext} = useContext(AppContext)

    const [userCredentials , setUserCredentials] = useState({
        otp_code: '',
        user_code: ''
    })

    const [recpathca , setRecapthca] = useState({
        isRobot: false,
        visible: false,
        captchaRef: useRef()
    })

    async function handleResend(event){
        event.preventDefault()
        try {
            const response = await api.post('/auth/resend-otp-code' , {
                login_credentails: appContext.userData.username,
            })
            
            if(response.data.status){

            } else {
                modalInterface.createModal("שגיאה" , messages.errors[response.data.message] , null , null , response.data.time * 1000)
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function handleLogin(event){
        event.preventDefault()
        try {
            const response = await api.post('/auth/login_otp_validate' , {
                login_credentails: appContext.userData.username,
                otp_code: userCredentials.otp_code,
                user_code: userCredentials.user_code,
                captcha: recpathca.visible ? recpathca.captchaRef?.current.getValue() : null
            })
            
            if(response.data.status){
                appContext.setUserData(prev => ({
                    ...prev,
                    logged_in: true
                }))
                sm.changeScreen("main_menu")
            } else {
                if(response.data.captcha){
                    setRecapthca(prev => ({
                        ...prev,
                        visible: true
                    }))
                }
                modalInterface.createModal("שגיאה" , messages.errors[response.data.message])
                handleRecaptchaExpire()
            }
        } catch (e) {
            console.log(e);
        }
    }

    function handleTextChange(event){
        const { name, value } = event.target;
        const numbersRegex = /^[0-9]*$/;
        if(numbersRegex.test(value) || value == ""){
            setUserCredentials(prev => ({
                ...prev,
                [name]: value
            }))
        }
        
    }

    function handleCaptchaChange() {
        setRecapthca(prev => ({
            ...prev,
            isRobot: false
        }))
    }

    function handleRecaptchaExpire() {
        if (recpathca.captchaRef) {
            recpathca.captchaRef?.current.reset()
        }
        setRecapthca(prev => ({
            ...prev,
            isRobot: true
        }))
    }

  return (
    <Form className='login_form_container' onSubmit={handleLogin}>

        <Form.Group className="mb-3 login_form_text_container" >
            <Form.Label className='login_form_text'>היי {appContext.userData.first_name} {appContext.userData.last_name} שלחנו לך קוד אימות. אנא הזן/י אותו בתיבה. <a onClick={handleResend} style={{textDecoration: 'underline' , cursor: 'pointer'}}>לא קיבלת?</a></Form.Label>
        </Form.Group>

        <Form.Group  className="mb-3" controlId="formBasicUsername">
            <Form.Control className='login_form_input' type='text' name='otp_code' value={userCredentials.otp_code} maxLength={6} placeholder="קוד אימות" onChange={handleTextChange} />
        </Form.Group>

        <Form.Group className="mb-3" style={{display: recpathca.visible ? 'flex' : 'none' , justifyContent: 'center'}}>
            {recpathca.visible ? <ReCAPTCHA ref={recpathca.captchaRef} onExpired={handleRecaptchaExpire} onChange={handleCaptchaChange} id="recaptchaLogIn" sitekey='6LeOqMIoAAAAAEWgmVPPR3HkmICHREV3AlFScUgu' /> : null}
        </Form.Group>

        <Form.Group className="mb-3" style={{display: 'none'}}>
            <Form.Label>תעודת זהות</Form.Label>
            <Form.Control type="text" placeholder="ת.ז" name='user_code' onChange={handleTextChange}/>
        </Form.Group>

        <Form.Group style={{justifyContent: 'flex-end' , display: 'flex' , margin: 0}}>
            <Button style={{width: "10rem" , height: '5rem' , fontSize: '2.5rem'}} variant="primary" type="submit">הבא</Button>
        </Form.Group>

    </Form>
  )
}

export default OtpForm