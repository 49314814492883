import React, { useContext, useRef, useState , useEffect} from 'react'
import api from '../../components/api/AxiosConfig.js'
import "./LoginPage.css"
import { AppContext } from '../../context/AppContext.js';
import LoginForm from '../../components/forms/login_forms/LoginForm.js';
import OtpForm from '../../components/forms/login_forms/OtpForm.js';
import PasswordForm from '../../components/forms/login_forms/PasswordForm.js';
import { sm } from '../../mobx/ScreenManager.js';
import logo from '../../assets/images/loya_logo.png'
import assafmediaLogo from '../../assets/images/assafmedia_logo.png'

function LoginPage() {
    const {appContext} = useContext(AppContext)
    const [showForm , setShowForm] = useState("login")

    function changeForm(form){
        setShowForm(form)
    }

    async function autoLogin(){
        try { 
            const response = await api.post('/auth/auto_login')
            if(response.data.status){
                
                sm.changeScreen('main_menu')
                appContext.setUserData(prev => ({
                    ...prev,
                    ...response.data.userInfo,
                    logged_in: true
                }))
            }
        } catch (e) {
            console.log(e);
        }
    }

    const FormController = ({ showForm, changeForm }) => {
        return (
            <div>
                {showForm === "login" && <LoginForm changeForm={changeForm} />}
                {showForm === "otp" && <OtpForm changeForm={changeForm} />}
                {showForm === "password" && <PasswordForm changeForm={changeForm} />}
            </div>
        );
    };

    useEffect(() => {
        autoLogin()
    },[appContext])

  return (
    <main className='login_screen container-fluid'>
            <div className="row">
                <section className='login-container col-xl-5 col-lg-7 col-md-8 col-sm-12 col-xs-10'>
                    <i><img src={logo} alt="Logo"/></i>
                    <FormController showForm={showForm} changeForm={changeForm}/>
                </section>
                <section className='login_cover col-xl-7 col-lg-5 col-md-4'>
                    <div className='login_credits'>
                        <p style={{fontSize: '0.91rem' , fontWeight: 100}}>גם מערכת זו נבנתה על-ידי</p>
                        <i><img src={assafmediaLogo} width={80} alt="Logo"/></i>
                    </div>
                </section>
            </div>
    </main>
  )
}

export default LoginPage