import React, { useContext, useEffect, useState } from 'react'
import { toJS } from 'mobx';
import { Button, Form } from 'react-bootstrap';
import api from '../../api/AxiosConfig'
import { ac } from '../../../mobx/AppContoller';
import { AppContext } from '../../../context/AppContext';
import { modalInterface } from '../../../mobx/ModalInterface';
import messages from '../../../utils/messages/messages';

function EditFileModal({file , setPatientFiles}) {
    const {appContext} = useContext(AppContext)
    const [jsFile , setJsFile] = useState(toJS(file))
    const [newName , setNewName] = useState(file.name)
    const [newNote , setNewNote] = useState(file.note)

    async function handleUpdate(){
        try {
            const response = await api.post('/main/update-patient-file' , {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                new_name: newName,
                new_note: newNote,
                file: file
            })
            if(response.data.status){
                const documents = response.data.documents
                
                setPatientFiles([...documents])
                modalInterface.closeModal()
            } else {
                modalInterface.createModal("התראה", messages.errors[response.data.message] , null , null)
            }
        } catch (e) {
            console.log(e);
        }
    }


  return (
    <Form>
        <Form.Group>
            <Form.Label>שם הקובץ</Form.Label>
            <Form.Control value={newName} onChange={(e) => setNewName(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group className='mb-3'>
            <Form.Label>הערה</Form.Label>
            <Form.Control as={'textarea'} value={newNote} onChange={(e) => setNewNote(e.target.value)}></Form.Control>
        </Form.Group>
        <Button onClick={() => handleUpdate()}>עדכן</Button>
    </Form>
  )
}

export default EditFileModal