import React from 'react'
import './Footer.css'
import AssafmediaLogo from '../../assets/images/assafmedia_logo.png'
import { sm } from '../../mobx/ScreenManager'

function Footer() {
  return (
    <>
    {!sm.showLogin ? 
      <div className='footer no-print'>
      <div className='footer-container'>
        
        <img className='assaf-meida-logo' src={AssafmediaLogo}></img>
        <label>Powered By</label>
      </div>
  </div> : null
    }
    </>
    
   
  )
}

export default Footer