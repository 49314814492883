import React, { useState, useEffect } from 'react';
import "./SideMenuExpand.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faX, faCaretRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SideMenuExpand = ({isMenuOpen, closeMenu, openMenu}) => {

  const iconClick = () =>{
    if(!isMenuOpen) return openMenu();
    closeMenu();
  }


  return (
    <div onClick={iconClick} className={isMenuOpen ? "expand_icon sidebar_open" : "expand_icon sidebar_close" } >
     {!isMenuOpen ? <FontAwesomeIcon size='lg' icon={faChevronRight} /> : <FontAwesomeIcon size='sm' icon={faX} />  }
  </div>
  );
};

export default SideMenuExpand;
