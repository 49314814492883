import { io } from "socket.io-client";
import { ac } from "../../mobx/AppContoller";

const host = `${window.location.hostname}`
let ENDPOINT = ''

if(host.includes("localhost")){
  ENDPOINT = `${window.location.protocol}//${host}:3100`
} else {
  ENDPOINT = `${window.location.protocol}//server.${host}`
}

class SocketService {

  connect() {
    this.socket = io(ENDPOINT);
    this.socket.on('connect', () => console.log('Connected to server'));
  }

  disconnect() {
    if (this.socket) {
        this.socket.disconnect();
    }
  }

  onPatientEnter(patientId, userId){
    if(this.socket){
        this.socket.emit("patient-enter", {patientId: patientId , userId: userId})
    }
  }

  onPatientExit(patientId , userId){
    if(this.socket){
        this.socket.emit("patient-exit", {patientId: patientId , userId: userId})
    }
  }

  onNewPatients(patient){
    if(this.socket){
      this.socket.emit("new-patient" , {patient: patient})
    }
  }

  onPatientViewResult(callback){
    if(this.socket){
        this.socket.on("handle-patient-view" , (data) => {
            const status = data.status
            callback(status)         
        })
    }
  }

  onPatientsListUpdate(callback){
    if(this.socket){
      this.socket.on("handle-patients-load" , (data) => {
        ac.insertNewPatient(data.patient)
        callback()
      })
    }
  }
  offPatientsListUpdate(callback){
    if (this.socket) {
      this.socket.off("handle-patients-load", callback);
    }
  }
}

export default new SocketService();
