import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useCitiesAndStreets = () => {
  const api_url = "https://data.gov.il/api/3/action/datastore_search";
  const cities_resource_id = "5c78e9fa-c2e2-4771-93ff-7f400a12f7ba";
  const streets_resource_id = "a7296d1a-f8c9-4b70-96c2-6ebb4352f8e3";
  const city_name_key = "שם_ישוב";
  const street_name_key = "שם_רחוב";

  const [cities, setCities] = useState([]);
  const [streets, setStreets] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  const getData = async (resource_id, q = "", limit = "100") => {
    try {
      const response = await axios.get(api_url, {
        params: { resource_id, q, limit },
        responseType: "json"
      });
      return response.data.result.records;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchCities = useCallback(async () => {
    const cityData = await getData(cities_resource_id, "", 32000);
    setCities(cityData.map(city => city[city_name_key]));
  }, []);

  const fetchStreets = useCallback(async () => {
    if (selectedCity) {
      const streetData = await getData(streets_resource_id, selectedCity, 32000);
      setStreets(streetData.map(street => street[street_name_key]));
    }
  }, [selectedCity]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  useEffect(() => {
    fetchStreets();
  }, [fetchStreets]);

  return {
    cities,
    streets,
    selectedCity,
    setSelectedCity
  };
};

export default useCitiesAndStreets;
