import React, { useState, useEffect, useRef } from 'react';
import { ac } from '../../mobx/AppContoller'
import "./SideMenu.css";
import SideMenuExpand from './sidemenu_expand/SideMenuExpand';
import { mainmm } from '../../mobx/MainMenuManager';
import logo from '../../assets/images/loya_logo.png'
import assafmedia_logo from '../../assets/images/assafmedia_logo.png'
import { em } from '../../mobx/ExamineManager';
import { modalInterface } from '../../mobx/ModalInterface';


const Sidebar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidemenuRef = useRef(null)
  
  const handleMenuItemClick = (event) => {
    const itemName = event.target.getAttribute('data-name');
    const element_card = document.getElementById(`${itemName}_card`)

    if(itemName != "search" && itemName != "edit" && mainmm.showEdit){
      mainmm.changeCard("info")
      mainmm.setAnchor(itemName)
      return
    }

    if(itemName == "edit"){
      if(em.editingExamine){
        modalInterface.createModal("התראה" , "אתה מנסה לחזור לחיפוש באמצע בדיקה , האם אתה בטוח?" , 
        [{text: "כן", onclick: () => handleEditAlert(true , itemName)},
         {text: "לא", onclick: () => handleEditAlert(false)}])
      } else {
        mainmm.changeCard("edit")
        mainmm.setAnchor(null)
      }
    }

    else if(itemName == "search") {
      if(em.editingExamine){
        modalInterface.createModal("התראה" , "אתה מנסה לחזור לחיפוש באמצע בדיקה , האם אתה בטוח?" , 
        [{text: "כן", onclick: () => handleEditAlert(true, itemName)},
         {text: "לא", onclick: () => handleEditAlert(false)}])
      } else {
        mainmm.changeCard("search")
        ac.setPatient(null);
        ac.setCurrentPatientDiseases(null)
        ac.setCurrentPatientData(null)
  
        ac.resetExamines()
        mainmm.setAnchor(null)
      }
    }
    else if(element_card){
      mainmm.setAnchor(itemName)
      element_card.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }); 
    } 
  };

  function handleEditAlert(condition, itemName){
    if(condition){
      mainmm.changeCard(itemName)
      ac.setPatient(null);
      ac.setCurrentPatientDiseases(null)
      ac.setCurrentPatientData(null)
      ac.resetExamines()
      mainmm.setAnchor(null)
      modalInterface.closeModal()
      em.setEditExamine(false)
    } else {
      modalInterface.closeModal()
      return
    }
  }

  const hadleStyles = ()=>{
    return ac.currentPatient &&  (mainmm.showInfo || mainmm.showEdit) ? "patient_set" : "paitent_revoked"
  }

  const handleMenuToggleClick = () => {
    if (!isMenuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  };


  const closeMenu = () => {
    document.querySelector('.sidebar_container').style.transform = 'translateX(-100%)';
    setIsMenuOpen(false);
  };

  const openMenu = () => {
    document.querySelector('.sidebar_container').style.transform = 'translateX(0)';
    setIsMenuOpen(true);
  };
  useEffect(()=>{
    
  }, [ac.currentPatient])

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidemenuRef.current && !sidemenuRef.current.contains(event.target)) {
        closeMenu()
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidemenuRef]);

  return (
    <>
      <SideMenuExpand 
        closeMenu={closeMenu}
        openMenu={openMenu}
        isMenuOpen={isMenuOpen}
        
      />
      <div ref={sidemenuRef} className="sidebar_container sidebar-comp">
        <div className="left">
        <div className='loya_logo sidebar_logo'><img src={logo}/></div> 
          <ul className="menu">
            <li>
              <input type="radio" id="menu-css" name="menu-item" />
              <label htmlFor="menu-css" data-name="search" onClick={handleMenuItemClick}>
                חיפוש
              </label>
            </li>
            <li className={hadleStyles()} >
                  <input type="radio" id="menu-css" name="menu-item" />
                  <label htmlFor="menu-css" data-name="edit" onClick={handleMenuItemClick}>
                    עריכה
                  </label>
                </li>
              <li className={hadleStyles()} >
                  <input type="radio" id="menu-css" name="menu-item" />
                  <label htmlFor="menu-css" data-name="refraction" onClick={handleMenuItemClick}>
                    רפרקציה
                  </label>
                </li>
                <li className={hadleStyles()}>
                  <input type="radio" id="menu-css" name="menu-item" />
                  <label htmlFor="menu-css" data-name="glasses" onClick={handleMenuItemClick}>
                    משקפיים
                  </label>
                </li>
                <li className={hadleStyles()}>
                  <input type="radio" id="menu-css" name="menu-item" />
                  <label htmlFor="menu-css" data-name="lenses" onClick={handleMenuItemClick}>
                    עדשות
                  </label>
                </li>
                <li className={hadleStyles()}>
                  <input type="radio" id="menu-css" name="menu-item" />
                  <label htmlFor="menu-css" data-name="files" onClick={handleMenuItemClick}>
                    קבצים
                  </label>
                </li>
              </ul>
              
        </div>
        <div className='assafmedia_logo_container'>
          <img className='assafmedia_logo' src={assafmedia_logo}></img>
        </div>
      </div>
    </>
  );
};

export default Sidebar;



// import React from 'react'
// import "./SideMenu.css"
// import { Button } from 'react-bootstrap'
// function SideMenu({close}) {
//   return (
//     <div className='sidebar-comp'>
//         <i><img src="https://via.placeholder.com/200x100" alt="Logo"/></i>
        
//     </div>
//   )
// }

// export default SideMenu

// <li>
// <input type="radio" id="menu-component" name="menu-item" />
// <label htmlFor="menu-component" onClick={handleMenuItemClick}>
//   Component
// </label>
// <ul className="collapsed-menu">
//   <li>Badges</li>
//   <li>Buttons</li>
//   <li>Breadcumbs</li>
//   <li>Cards</li>
//   <li>Chips</li>
// </ul>
// </li>
// <li>
// <input type="radio" id="menu-js" name="menu-item" />
// <label htmlFor="menu-js" onClick={handleMenuItemClick}>
//   Javascript
// </label>
// <ul className="collapsed-menu">
//   <li>Carausel</li>
//   <li>Collapsible</li>
//   <li>Dialogs</li>
//   <li>Dropdown</li>
//   <li>Parallax</li>
// </ul>
// </li>
// <li>
// <input type="radio" id="menu-mobile" name="menu-item" />
// <label htmlFor="menu-mobile" onClick={handleMenuItemClick}>
//   Mobile
// </label>
// <ul className="collapsed-menu">
//   <li>Navbar</li>
//   <li>Toast</li>
//   <li>Media</li>
//   <li>Tabs</li>
//   <li>Hamburger</li>
// </ul>
// </li>
// <li>
// <input type="radio" id="menu-showcase" name="menu-item" />
// <label htmlFor="menu-showcase" onClick={handleMenuItemClick}>
//   Showcase
// </label>
// <ul className="collapsed-menu">
//   <li>Gold</li>
//   <li>Silver</li>
//   <li>Trophies</li>
//   <li>Medals</li>
//   <li>Bronze</li>
// </ul>
// </li>