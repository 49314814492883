import { ac } from "../mobx/AppContoller"
import { mainmm } from "../mobx/MainMenuManager"
import { sm } from "../mobx/ScreenManager"
import Cookies from "js-cookie"
import messages from '../utils/messages/messages'
import { em } from "../mobx/ExamineManager"

const utils = {
    calcualteAge: function calcualteAge(dob){
        if(dob === "0000-00-00 00:00:00"){
            return ""
        }
        const date = new Date(dob)
        const currentDate = new Date()
        let age = currentDate.getFullYear() - date.getFullYear()
        let month = currentDate.getMonth() - date.getMonth()
        if (month < 0 || (month === 0 && currentDate.getDate() < date.getDate())) {
            age--;
        }
    
        return age;
          
    },
    getDateDay: function getDateDay(date){
        if (!date || date === '0000-00-00 00:00:00') {
            return; // Return undefined if no date is provided
        }
        const day = new Date(date).getDate(); // Get day of the month
        return day < 10 ? '0' + day : day.toString(); 
    },
    getDateMonth: function getDateMonth(date){
        if (!date || date === '0000-00-00 00:00:00') {
            return; // Return undefined if no date is provided
        }
        const month = new Date(date).getMonth() + 1; // Get month (adjusted for zero-index)
        return month < 10 ? '0' + month : month.toString(); 
    },
    getDateYear: function getDateYear(date){
        if(!date || date === '0000-00-00 00:00:00'){
            return
        }
        return new Date(date).getFullYear()
    },
    fromatDate: function formatDate(date){
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    },
    compareTime: function compareTime(timeString, hourDiff){
        const inputTime = new Date(timeString);

        const currentTime = new Date();

        const diffHours = Math.abs(currentTime - inputTime) / 36e5;

        return diffHours <= hourDiff;
    },
    isEmptyObject: function isEmptyObject(obj) {
        if(!obj){
            return true
        }
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    changeFillType: function changeFillType(examine, to){
        examine['r']['type'] = to
        examine['l']['type'] = to

        Object.keys(examine).forEach(side => {
            Object.keys(examine[side]).forEach(key => {
                if (key != 'sph' && examine[side][key] == 0) {
                    examine[side][key] = null;
                }
            });
        });
    
        return examine
    },
    calculateLastVisit: function calculateLastVisit(examines){
        let latestExam = null;
        let latestDate = new Date(0); // Initialize to a very early date

        const arraysToCheck = [examines.glasses, examines.lenses, examines.refraction];

        arraysToCheck.forEach(array => {
            array.forEach(exam => {
                const examDate = new Date(exam.creation_datetime);
                if (examDate > latestDate) {
                    latestDate = examDate;
                    latestExam = exam;
                }
            });
        });

        if (latestExam) {
            return {status: true , lastExam: latestExam.creation_datetime}
        } 

        return {status: false , lastExam: "אין"}
        
    },

    validatePhone: function validatePhone(phone){
        let fixedPhone = String(phone).replace('-' , '').replace('+' , '')
        let lenCheck = 10
        
        if(fixedPhone.startsWith("972")){
            lenCheck = 12
        }
        
        if(fixedPhone.length < lenCheck || fixedPhone.length > lenCheck){
            return false
        }
        if(fixedPhone.startsWith("972")){
            return fixedPhone
        } else if (fixedPhone.startsWith("0")) {
            fixedPhone = fixedPhone.replace("0", "972")
            return fixedPhone
        } else {
            return false
        }
    },
    logout: function logout(appContext){
        appContext.setUserData(prev => ({
            ...prev,
            id: null,
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            last_login_attempt: null,
            access_level: 0,
            logged_in: false  
            
        }))
        ac.setPatient(null)
        ac.setCurrentPatientDiseases(null)
        ac.setCurrentPatientData(null)
        ac.resetExamines()
        mainmm.changeCard("search")
        Cookies.remove('access-token', {path: '/' , domain: `.${window.location.hostname}`})
        sm.changeScreen("login")
    },

    findMissingField: function findMissingField(errorFields){
        for (const field in errorFields){
            if(errorFields[field] == false){
                return messages.fields[field]
            } 
        }
    },

    fixTableInputFormat: function fixTableInputFormat(examines){
        const keysToIgnore = ['type' , 'b' , 'eye' , 'base' , 'base2' , 'pd' , 'sc' ,  'pd2', 'v' , 'h' , 'add_n' , 'add_i' , 'high' ,'table_type', 'brand', 'supplier' , 'diam' , 'bc' , 'material' , 'rh' , 'rv',  'open_text' , 'note' , 'va' , 'va2' , 'avr']
        const keysWithoutFix = ['ax' , 'avr' , 'axh' , 'sc']
        Object.keys(examines).forEach(category => {
            ['r', 'l'].forEach(side => {
                if (!examines[category][side]) {
                    return;
                }
                Object.keys(examines[category][side]).forEach(key => {
                    let value = examines[category][side][key]

                    if(key == 'sph' && (value?.toLowerCase() == "p" || value?.toLowerCase() == 'plano')){
                        examines[category][side][key] = 'plano';
                        return;
                    }

                    if ((value == 0 || value === "0" || value === 0) && key !== 'sph'  ) {
                        examines[category][side][key] = null;
                        return;
                    }

                    if (keysToIgnore.includes(key)) {
                        return;
                    }
                 
                    if(value == null || value == "" || value == 0 || value == "0"){
                        return
                    }
                    if (keysWithoutFix.includes(key)) {
                        examines[category][side][key] = parseInt(value);
                        return
                    }
                    
                    if(value > 0){
                        examines[category][side][key] = `+${parseFloat(value).toFixed(2)}`
                    } else {
                        examines[category][side][key] = parseFloat(value).toFixed(2)
                    }
                    
                })
            });
        });
        return examines

    },


    glasses: {

        // fillNear: function fillNear(distance) {
        //     const sums = {};
        //     const propertiesToCopy = ['cyl', 'ax' , 'h' , 'base', 'v' , 'base2' , 'pd'];  // List properties to copy directly
        
        //     // Helper function to format sph values
        //     const formatSph = (sphValue) => {
        //         if(isNaN(sphValue)){
        //             return null
        //         }
        //         return (sphValue >= 0 ? "+" : "") + sphValue.toFixed(2);
        //     };

        //     const adjustPd2 = (pd2Value) => {
        //         const pd2 = parseFloat(pd2Value);
        //         if (!isNaN(pd2)) {
        //             if (pd2 > 70) {
        //                 return pd2 - 4;
        //             } else if (pd2 >= 60) {
        //                 return pd2 - 3;
        //             } else {
        //                 return pd2 - 2;
        //             }
        //         }
        //         return pd2Value; // Return original if not a number (or handle differently if needed)
        //     };
        
        //     ['r', 'l'].forEach(eye => {
        //         const { sph, add_n, pd2 , ...rest } = distance[eye];
        //         const sphFloat = parseFloat(sph);
        //         const addNFloat = parseFloat(add_n);
        
        //         if (!isNaN(addNFloat)) {
        //             const sumSph = sphFloat + addNFloat;
        //             sums[eye] = {
        //                 sph: formatSph(sumSph),
        //                 ...Object.fromEntries(propertiesToCopy.map(key => [key, rest[key]])),
        //                 pd: adjustPd2(pd2) / 2,
        //                 pd2: adjustPd2(pd2) 
        //             };
        //         } else {
        //             // Optionally handle the case where add_n is not a number
        //             sums[eye] = {};
        //         }
        //     });
        
        //     return sums;
        // },
        // fillOther: function fillOther(distance){
        //     const sums = {};
        //     const propertiesToCopy = ['cyl', 'ax' , 'h' , 'base', 'v' , 'base2' , 'pd'];  // List properties to copy directly
        
        //     // Helper function to format sph values
        //     const formatSph = (sphValue) => {
        //         if(isNaN(sphValue)){
        //             return null
        //         }
        //         return (sphValue >= 0 ? "+" : "") + sphValue.toFixed(2);
        //     };

        //     const adjustPd2 = (pd2Value) => {
        //         const pd2 = parseFloat(pd2Value);
        //         if (!isNaN(pd2)) {
        //             if (pd2 > 70) {
        //                 return pd2 - 4;
        //             } else if (pd2 >= 60) {
        //                 return pd2 - 3;
        //             } else {
        //                 return pd2 - 2;
        //             }
        //         }
        //         return pd2Value; // Return original if not a number (or handle differently if needed)
        //     };
        
        //     ['r', 'l'].forEach(eye => {
        //         const { sph, add_i, pd2,  ...rest } = distance[eye];
        //         const sphFloat = parseFloat(sph);
        //         const addIFloat = parseFloat(add_i);
        
        //         if (!isNaN(addIFloat)) {
        //             const sumSph = sphFloat + addIFloat;
        //             sums[eye] = {
        //                 sph: formatSph(sumSph),
        //                 ...Object.fromEntries(propertiesToCopy.map(key => [key, rest[key]])),
        //                 pd: adjustPd2(pd2) / 2,
        //                 pd2: adjustPd2(pd2) 
        //             };
        //         } else {
        //             // Optionally handle the case where add_n is not a number
        //             sums[eye] = {};
        //         }
        //     });
        
        //     return sums;
        // },
        fillGlassesTables: function fillGlassesTables(distance, addProperty) {
            const sums = {};
            const propertiesToCopy = ['cyl', 'ax', 'h', 'base', 'v', 'base2', 'pd'];  // List properties to copy directly
    
             // Helper function to format sph values
            const formatSph = (sphValue) => {
            if (isNaN(sphValue)) {
                return null;
            }
            return (sphValue >= 0 ? "+" : "") + sphValue.toFixed(2);
            };
    
            const adjustPd2 = (pd2Value) => {
                const pd2 = parseFloat(pd2Value);
                if (!isNaN(pd2)) {
                    if (pd2 > 70) {
                        return pd2 - 4;
                    } else if (pd2 >= 60) {
                        return pd2 - 3;
                    } else {
                        return pd2 - 2;
                    }
            }
            return pd2Value; // Return original if not a number (or handle differently if needed)
        };
    
        ['r', 'l'].forEach(eye => {
            const { sph, pd2, ...rest } = distance[eye];
            const addValue = distance[eye][addProperty];
            const sphFloat = parseFloat(sph);
            const addFloat = parseFloat(addValue);
    
            // Check if any parameter is 0
            const adjustedRest = {};
            propertiesToCopy.forEach(key => {
                adjustedRest[key] = (rest[key] == 0) ? null : rest[key];
            });

            if (!isNaN(addFloat)) {
                const sumSph = sphFloat + addFloat;
                sums[eye] = {
                    sph: formatSph(sumSph),
                    ...adjustedRest,
                    pd: adjustPd2(pd2) / 2,
                    pd2: adjustPd2(pd2)
                };
            } else {
                // Optionally handle the case where addValue is not a number
                sums[eye] = {};
            }
            
        });
    
        return sums;
        },
    },

   

}

export default utils