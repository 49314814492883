import React, { useState } from 'react'
import './DateModal.css'
import { Form } from 'react-bootstrap'
import GenButton from '../../buttons/GenButton'
import utils from '../../../utils/utils'

function DateModal({handleSetDate , index}) {

    const [date , setDate] = useState(utils.fromatDate(new Date()))
    const [error , setError] = useState(null)

    function handleChange(event){
        console.log(event.target.value);
        setDate(event.target.value)
    }

    function handleSumbit(){
        handleSetDate(index , date)
    }

  return (
    <div className='date-modal-container'>
        <label>מה התאריך שבהם נמסרו המשקפיים?</label>
        <Form.Control value={date} className='date-picker' onChange={(e) => handleChange(e)} type='date'></Form.Control>
        {error ? <label style={{color: 'red'}}>{error}</label> : null}
        <GenButton onClick={() => handleSumbit()} text={'אישור'} backgroundColor={"#0d6efd"} ></GenButton>
    </div>
  )
}

export default DateModal