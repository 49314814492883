import React from 'react';

import AvatarPlaceHolder from '../assets/svgs/avatar-place-holder.svg'
import MalePlaceHolder from '../assets/svgs/male-place-holder.svg'
import FemalePlaceHolder from '../assets/svgs/female-place-holder.svg'

import PdfPlaceHolder from '../assets/svgs/pdf-place-holder.svg'
import DocPlaceHolder from '../assets/svgs/doc-place-holder.svg'

const svgObj = {
    "avatar-male": MalePlaceHolder,
    "avatar-female": FemalePlaceHolder,
    "avatar-general": AvatarPlaceHolder,
    "file-pdf": PdfPlaceHolder,
    "file-doc": DocPlaceHolder
};

const Svg = ({ fileName  , onClick , alt , className , style , onError}) => {
    return <img onError={onError} className={className} style={style} src={svgObj[fileName]} alt={alt} onClick={onClick} />;
};

export default Svg; 
