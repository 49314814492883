import React, { useState , useContext , useEffect , useRef} from 'react'
import './LensesCard.css'
import GenTable from '../../tables/table/GenTable'
import tableInfo from '../../../utils/tables_info/TablesInfo'
import GenButton from '../../buttons/GenButton'
import CardTitle from '../card_title/CardTitle'
import api from '../../api/AxiosConfig'
import { AppContext } from '../../../context/AppContext'
import { ac } from '../../../mobx/AppContoller'
import { observer } from "mobx-react";
import { Col, Form, Row } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useFormik } from 'formik'
import utils from '../../../utils/utils'
import LoadingSpinner from '../../loading_spinner/LoadingSpinner'
import { fsm } from '../../../mobx/FormSuggestionManager'
import { modalInterface } from '../../../mobx/ModalInterface'
import { mainmm } from '../../../mobx/MainMenuManager'
import { em } from '../../../mobx/ExamineManager'
import CheckerNameInput from '../../inputs/checker_name/CheckerNameInput'
import PrintInfo from '../../print_info/PrintInfo'
import TextEditor from '../../text_editor/TextEditor'


function LensesCard({examines , getPatinetInfo , allowEdit , patient}) {
    const {appContext} = useContext(AppContext)
    const [minimize , setMinimize] = useState(true)
    const [saveColor , setSaveColor] = useState('#005371')
    const [tableData , setTableData] = useState({})
    const [loadValues , setLoadValues] = useState({})
    const [overlay, setOverlay] = useState(false)
    const [formSuggestions , setFormSuggestions] = useState({})
    const [checkerName , setCheckerName] = useState()
    const [tableValues , setTableValues] = useState({})
    const lensesTableRef = useRef();
    const corneaTableRef = useRef();

    const onSubmit = async (values) => {
        save()
    }
    
    const validate = values => {

    }

    const initialValues = {
        diam_pupil_r: '',
        diam_pupil_l: '',
        diam_cornea_r: '',
        diam_cornea_l: '',
        tbut_l: '',
        tbut_r: '',
        lens_color_r: '',
        lens_color_l: '',
        clean_solution: '',
        wash_solution: '',
        watering_drops: '',
        info_code: '',
        item_code: '',
        deal_type: '',
        total_price: '',
        note: ''
    }

    function handleChange(value , name){
        formik.setFieldValue(name , value)
    }

    function handleInputChange(value , name){
        formik.setFieldValue(name , value)
    }

    async function save(print = false){
        modalInterface.closeModal()
        if(utils.isEmptyObject(tableData)){
            if(print){
                mainmm.setAnchor("lenses")
                window.print()
                return
            }
        }

        if(ac.currentLensesExamine != 0){
            modalInterface.createModal("התראה" , "האם אתה בטוח שאתה רוצה לעדכן בדיקה קיימת?" , [{
                text: "כן",
                focus: true,
                onclick: () => update(print)
            },{
                text: "לא",
                onclick: () => modalInterface.closeModal()}])
            return
        }
       
        try {
            const response = await api.post('/main/create-new-lenses-examine-card', {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                username: appContext.userData.username,
                type: "lenses",
                tableData: JSON.stringify(tableData),
                lensesOrder: formik.values,
                checker_name: checkerName   
            })
            if(print){
                setTimeout(() => window.print(), 100)
             }
             em.setLastSavedExamine("glasses" , checkerName)
                em.setEditExamine(false)
                setTableData({})
                getPatinetInfo()
                resetTables()
            
        
  
        } catch (e){
            console.log(e);
        }
 
    }

    async function update(print){
        try {
            const response = await api.post('/main/update-exsiting-lenses-card', {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                username: appContext.userData.username,
                type: "lenses",
                tableData: JSON.stringify(tableData),
                lensesOrder: formik.values,
                note: formik.values.note,
                checker_name: checkerName,
                examine: examines[ac.currentLensesExamine - 1],   
            })
            modalInterface.closeModal()
            if(print){
                setTimeout(() => window.print(), 100)
             }

             setTimeout(() => {
                em.setEditExamine(false)
                setTableData({})
                getPatinetInfo()
                resetTables()
             },[print ? 1000 : 0])
            
        } catch (e) {
            console.log(e);
        }
    }

    function resetTables(){
        if (lensesTableRef.current) {
            lensesTableRef.current.onSave();
        }
        if (corneaTableRef.current) {
            corneaTableRef.current.onSave();
        }
    }

    async function load(){
    setTableValues({})
    formik.handleReset()
    setCheckerName("")
    if(!examines || ac.currentLensesExamine == 0 || !examines[ac.currentLensesExamine - 1]){
        return
    }
    try {
        setOverlay(true)
        const response = await api.post('/main/load-patinet-examine',{
            user_id: appContext.userData.id,
            patient_id: ac.currentPatient,
            type: 'lenses',
            examine_id: examines[ac.currentLensesExamine - 1].id
        })

        const examinesInfo = response.data.examine
        const examineCard = response.data.examineCard[0]
        setCheckerName(examineCard.manual_checker_name)
        const fixedValues = utils.fixTableInputFormat(response.data.examine)
        setTableValues(fixedValues)
        formik.setValues(response.data.lensesOrder[0] ? response.data.lensesOrder[0] : initialValues)
        formik.setFieldValue('note' , examineCard.note)
        setOverlay(false)
    } catch (e) {
        console.log(e);
    }
    }

    const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    })

    function isSaveRequired(){
        if(formik.dirty){
            setSaveColor('#008000')
          } else {
            setSaveColor('#005371')
          }
    }

    function handleOption(option){
        if (typeof option === 'object' && option.label) {
            return typeof option.label === 'string' ? option.label.toLowerCase() : option.label.toString();
        }
        // If the option is a string, return it in lowercase
        else if (typeof option === 'string') {
            return option.toLowerCase();
        }
        // If the option is a number, convert it to a string
        else if (typeof option === 'number') {
            return option.toString();
        }
        // Default case to handle other unexpected types
        else {
            return '';
        }
    }

    async function handlePrint(){
        mainmm.setAnchor("lenses")
        save(true)
         
    }

    useEffect(() => {
        load()
    },[ac.currentLensesExamine, examines])

    useEffect(() => {
        isSaveRequired()
    },[formik.values])

    useEffect(() => {
        if(mainmm.examineAnchor == "lenses"){
            setMinimize(false)
        } else {
            setMinimize(true)
        }
    },[mainmm.examineAnchor])

    useEffect(() => {
        const handleKeyDown = (event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 's' && em.currentExamine == "lenses") {
            event.preventDefault();
            modalInterface.createModal("התראה" , `האם אתה בטוח שברצונך לשמור בדיקת עדשות מגע?` ,[{text: "כן" , onclick: () => save() }, {text: "לא" , onclick: modalInterface.closeModal()}])
            
          }
        };
      
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [tableData]);


  return (
    <div id="lenses_card" className={`row ${mainmm.examineAnchor == "lenses" ? "print-friendly" : "no-print" } lenses_container container-fluid ${!allowEdit ? 'disabled-div' : null}`} style={{position: 'relative'}}>
        <LoadingSpinner visible={overlay} />
        <div className='justify-content-evenly'>
                    <section className='col-xs-12 col-lg-12'>
                            <div className='col-xs-12 col-lg-12 row'>
                                <div className='card_header_section'>
                                    <CardTitle handlePrint={handlePrint} type={"lenses"} current_examine={ac.currentLensesExamine} examines={examines} title={"עדשות מגע"} minimize={minimize} setMinimize={setMinimize} saveColor={saveColor} onSaveClick={() => save()} />
                                </div>
                                <div>
                                    <PrintInfo patient={patient} current_examine={ac.currentLensesExamine} examines={examines}/>
                                </div>
                                <div className={`${!minimize ? "open-examine-card" : "close-examine-card"} lenses-override`}>
                                <div className='col-lg-12 col-xs-12 d-flex justify-content-center mt-2'>
                                    <CheckerNameInput checkerName={checkerName} setCheckerName={setCheckerName} />
                                    {/* <Form.Control placeholder='שם הבודק' className='checker-name' value={checkerName} onChange={(e) => setCheckerName(e.target.value)} /> */}
                                </div>
                                    <div className=" row flex-wrap">
                                        <div className='col-lg-12 col-xs-10'>
                                            
                                                <div style={{textAlign: 'left'}}>Lenses</div>
                                                <GenTable hideBalance={true} ref={lensesTableRef} tableFor={"lenses"} examine={"lenses"} setTableData={setTableData} setSaveColor={setSaveColor} headers={tableInfo.lenses.tHeaders.lensesHeader} columns={tableInfo.lenses.tColumns.lensesColumns} initialValues={tableValues.lenses ?  tableValues.lenses : tableInfo.lenses.lensesInitialValues}/>
                                            
                                        </div>
                                    </div>
                                    <div className='row flex-wrap'>
                                        <div className='col-lg-12 col-xs-10'>
                                            
                                                <div style={{textAlign: 'left'}}>Corneal curvature</div>
                                                <GenTable hideBalance={true} ref={corneaTableRef} tableFor={"cornea"} examine={"lenses"} setTableData={setTableData} setSaveColor={setSaveColor} headers={tableInfo.lenses.tHeaders.corneaHeader} columns={tableInfo.lenses.tColumns.corneaColumns} initialValues={tableValues.cornea ? tableValues.cornea : tableInfo.lenses.corneaInitalValues}/>
                                            
                                        </div>
                                    </div>
                                    <div>                  
                                    <Form className='lenses-form'>
                                        <Col >
                                            <Form.Group className='form-double-row'>
                                            <Form.Label>קוטר אישון</Form.Label>
                                                <Form.Group className='form-double-row-item'>
                                                   <Form.Label>ימין</Form.Label>
                                                    <Form.Control placeholder='ימין' name='diam_pupil_r' value={formik.values.diam_pupil_r} onChange={formik.handleChange} ></Form.Control>
                                                </Form.Group>
                                                <Form.Group className='form-double-row-item'>
                                                <Form.Label>שמאל</Form.Label>
                                                    <Form.Control placeholder='שמאל' name='diam_pupil_l' value={formik.values.diam_pupil_l} onChange={formik.handleChange}></Form.Control>
                                                </Form.Group>
                                            </Form.Group>
                                        
                                            <Form.Group className='form-double-row'>
                                                <Form.Label>קוטר קרנית</Form.Label>
                                                <Form.Group className='form-double-row-item'>
                                                    
                                                    <Form.Control placeholder='ימין' name='diam_cornea_r' value={formik.values.diam_cornea_r} onChange={formik.handleChange}></Form.Control>
                                                </Form.Group>
                                                <Form.Group className='form-double-row-item'>
                                                   
                                                    <Form.Control placeholder='שמאל'  name='diam_cornea_l' value={formik.values.diam_cornea_l} onChange={formik.handleChange}></Form.Control>
                                                </Form.Group>
                                            </Form.Group>
                                        
                                            <Form.Group className='form-double-row'>
                                                <Form.Label>TBUT</Form.Label>
                                                <Form.Group className='form-double-row-item'>
                                              
                                                    <Form.Control placeholder='ימין' name='tbut_r' value={formik.values.tbut_r} onChange={formik.handleChange}></Form.Control>
                                                </Form.Group>
                                                <Form.Group className='form-double-row-item'>
                                               
                                                    <Form.Control placeholder='שמאל'  name='tbut_l' value={formik.values.tbut_l} onChange={formik.handleChange}></Form.Control>
                                                </Form.Group>
                                            </Form.Group>

                                            <Form.Group className='form-double-row'>
                                                <Form.Label>צבע העדשה</Form.Label>
                                                <Form.Group className='form-double-row-item'>
                                                    <Typeahead 
                                                        placeholder='ימין'
                                                        style={{textAlign: 'right'}}
                                                        id="lens_color"
                                                        options={fsm.lensesOrderSuggestion?.lens_color || []}
                                                        onInputChange={(value) => handleInputChange(value , 'lens_color_r')}
                                                        name='lens_color_r'
                                                        labelKey={(option) => handleOption(option)}
                                                        selected={formik.values.lens_color_r ? [formik.values.lens_color_r] : []}
                                                        onChange={(selected) => {
                                                            handleChange(selected.length > 0 ? selected[0] : '', 'lens_color_r');
                                                        }}
                                                        />
                                                </Form.Group>
                                                <Form.Group className='form-double-row-item'>
                                                        <Typeahead 
                                                        placeholder='שמאל' 
                                                        style={{textAlign: 'right'}}
                                                        id="lens_color"
                                                        options={fsm.lensesOrderSuggestion?.lens_color || []}
                                                        onInputChange={(value) => handleInputChange(value , 'lens_color_l')}
                                                        name='lens_color_l'
                                                        labelKey={(option) => handleOption(option)}
                                                        selected={formik.values.lens_color_l ? [formik.values.lens_color_l] : []}
                                                        onChange={(selected) => {
                                                            handleChange(selected.length > 0 ? selected[0] : '', 'lens_color_l');
                                                        }}
                                                        />
                                                </Form.Group>
                                                    
                                            </Form.Group>

                                            
                                        </Col>
                                        
                                        <Col>
                                            
                                            
                                            <Form.Group className='form-single-row'>
                                                <Form.Label>תמיסת ניקוי</Form.Label>
                                                <Typeahead 
                                                    style={{textAlign: 'right'}}
                                                    id="clean_solution"
                                                    options={fsm.lensesOrderSuggestion?.clean_solution  || []}
                                                    onInputChange={(value) => handleInputChange(value , 'clean_solution')}
                                                    name='clean_solution'
                                                    value={formik.values.clean_solution}
                                                    labelKey={(option) => handleOption(option)}
                                                    selected={formik.values.clean_solution ? [formik.values.clean_solution] : []}
                                                    onChange={(selected) => {
                                                        handleChange(selected.length > 0 ? selected[0] : '', 'clean_solution');
                                                    }}
                                                    />
                                                    
                                                    
                                            </Form.Group>

                                            <Form.Group className='form-single-row'>
                                                <Form.Label>תמיסת שטיפה</Form.Label>
                                                <Typeahead 
                                                    style={{textAlign: 'right'}}
                                                    id="wash_solution"
                                                    options={fsm.lensesOrderSuggestion?.wash_solution  || []}
                                                    onInputChange={(value) => handleInputChange(value , 'wash_solution')}
                                                    name='wash_solution'
                                                    value={formik.values.wash_solution}
                                                    labelKey={(option) => handleOption(option)}
                                                    selected={formik.values.wash_solution ? [formik.values.wash_solution] : []}
                                                    onChange={(selected) => {
                                                        handleChange(selected.length > 0 ? selected[0] : '', 'wash_solution');
                                                    }}
                                                    />
                                            </Form.Group>

                                            <Form.Group className='form-single-row'>
                                                <Form.Label>טיפות הרטבה</Form.Label>
                                                <Typeahead 
                                                    style={{textAlign: 'right'}}
                                                    id="watering_drops"
                                                    options={fsm.lensesOrderSuggestion?.watering_drops || []}
                                                    onInputChange={(value) => handleInputChange(value , 'watering_drops')}
                                                    name='watering_drops'
                                                    value={formik.values.watering_drops}
                                                    labelKey={(option) => handleOption(option)}
                                                    selected={formik.values.watering_drops ? [formik.values.watering_drops] : []}
                                                    onChange={(selected) => {
                                                        handleChange(selected.length > 0 ? selected[0] : '', 'watering_drops');
                                                    }}
                                                    />
                                            </Form.Group>

                                            <Form.Group className='form-single-row'>
                                                <Form.Label>סוג עסקה</Form.Label>
                                                <Typeahead 
                                                        style={{textAlign: 'right'}}
                                                        id="deal_type"
                                                        options={fsm.lensesOrderSuggestion?.deal_type || []}
                                                        onInputChange={(value) => handleInputChange(value , 'deal_type')}
                                                        name='deal_type'
                                                        value={formik.values.deal_type}
                                                        labelKey={(option) => handleOption(option)}
                                                        selected={formik.values.deal_type ? [formik.values.deal_type] : []}
                                                        onChange={(selected) => {
                                                            handleChange(selected.length > 0 ? selected[0] : '', 'deal_type');
                                                        }}
                                                    />
                                                
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='form-single-row'>
                                                <Form.Label>קוד פריט</Form.Label>
                                                <Form.Control name='item_code' value={formik.values.item_code} onChange={formik.handleChange}></Form.Control>
                                            </Form.Group>

                                            <Form.Group className='form-single-row'>
                                                <Form.Label>קוד אפיון</Form.Label>
                                                <Form.Control name='info_code' value={formik.values.info_code} onChange={formik.handleChange}></Form.Control>
                                            </Form.Group>
                                        

                                            <Form.Group className='form-single-row'>
                                                <Form.Label>גובה תשלום</Form.Label>
                                                <Form.Control name='total_price' value={formik.values.total_price} onChange={formik.handleChange}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Form>
                                    </div>
                                    <div className='col-lg-12 col-xs-12 table-note-container' >
                                        <TextEditor placeholder='הערות' value={formik.values.note} setValue={(value) => formik.setFieldValue('note',value)} />
                                        {/* <Form.Control placeholder='הערות' as={'textarea'} rows={5} value={formik.values.note} name='note' onChange={formik.handleChange}  className='table-note'></Form.Control> */}
                                    </div>
                                    <br />
                                    <div className='no-print'>
                                        <GenButton width={'40%'} text={ac.currentLensesExamine == 0 ? "שמירה כרטיס עדשות.מ" : "עדכן כרטיס עדשות.מ"} onClick={() => save()} backgroundColor={saveColor} textColor={'#fff'}></GenButton>
                                    </div>
                                </div>
                            </div>
                    </section>              
        </div>
    </div>
  )
}

export default observer(LensesCard)