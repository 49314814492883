import React from 'react'
import './LoadingSpinner.css'

function LoadingSpinner({visible}) {
  return (
    <div className={`loading-overlay no-print ${visible ? "loading-visible" : null}`}>
        <div className='loading-spinner'></div>
    </div>
  )
}

export default LoadingSpinner