import React, { useContext, useEffect, useState } from 'react'
import utils from '../../../utils/utils';
import './DiseasesModal.css'
import { Row , Form, Button} from 'react-bootstrap';
import GenButton from '../../buttons/GenButton';
import { toJS } from 'mobx';
import api from '../../api/AxiosConfig'
import { useFormik } from 'formik';
import { ac } from '../../../mobx/AppContoller';
import { AppContext } from '../../../context/AppContext';

function DiseasesModal() {
  const {appContext} = useContext(AppContext)
  const [diseaseInfo , setDiseaseInfo] = useState({
    disease: '',
    diseaseDate: new Date()
  })
  const [patientDiseases , setPatientDiseases] = useState([])


  function handleChange(e){
    setDiseaseInfo(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  async function deleteDisesae(index){
    try {
      const response = await api.post('/main/delete-patient-disease' , {
        patient_id: ac.currentPatient,
        user_id: appContext.userData.id,
        disease: patientDiseases[index]
      })
      load()
      
    } catch (e) {
      console.log(e);
    }
  }

  async function addDisease() {
    try {
      const response = await api.post('/main/add-patient-disease', {
        user_id: appContext.userData.id,
        patient_id: ac.currentPatient,
        disease_info: diseaseInfo
      })
      load()
      
    } catch (e) {
      console.log(e);
    }
  }

  async function load(){
    try {
      const response = await api.post('/main/get-patient-disesaes', {
        patient_id: ac.currentPatient,
        user_id: appContext.userData.id
      })
      
      if(response.data.status){
        setPatientDiseases(response.data.diseases)
      }
    } catch (e){
      console.log(e);
    }
  }

  useEffect(() => {
    load()
  },[])

  return (
    <div>
      <div>
        {patientDiseases.map((index , item) => {
            return(
                <div key={index} className='disease_modal_container'>
                    <div className="mb-1 col-lg-5">{patientDiseases[item].disease}</div>
                    <div className="mb-1 col-lg-5">{utils.fromatDate(patientDiseases[item].disease_found_date)}</div>
                    <Button onClick={() => deleteDisesae(item)} className='mb-1 col-lg-1'>מחק</Button>
                </div>
                
            )
        })}
      </div>
      <div className='new-disease'>
        <Form.Group className="mb-1 col-lg-5">
          <Form.Control type="text" placeholder='האבחנה' onChange={handleChange} value={diseaseInfo.disease} name='disease'/>
        </Form.Group>
        <Form.Group className="mb-1 col-lg-5">
          <Form.Control type="date" placeholder="תאריך האבחנה" onChange={handleChange} value={utils.fromatDate(diseaseInfo.diseaseDate)} name='diseaseDate'/>
        </Form.Group>
        <Button onClick={() => addDisease()} className='mb-1 col-lg-1'>הוסף</Button>
      </div>
    </div>
  )
}

export default DiseasesModal