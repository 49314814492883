import React, { useEffect, useState } from 'react'
import './PrintInfo.css'
import utils from '../../utils/utils'

function PrintInfo({patient , current_examine , examines}) {
  const [date , setDate] = useState(null)

  useEffect(() => {
    setDate(examines[current_examine - 1]?.creation_datetime || new Date())
  },[current_examine])

  return (
    <div className='patient-info print-friendly'>
        <div>שם מלא: {patient.first_name} {patient.last_name} </div>
        <div>תז: {patient.social_number}</div>
        <div>מס טלפון: {patient.phone} </div>
        <div>מס' לקוח: {patient.id}</div>
        <div>תאריך: {utils.fromatDate(date)} </div>
        <div>קופת חולים: {patient.medical_institute}</div>
        <div>סוג ביטוח: {patient.insurence_type}</div>
        <div>עיר: {patient.city}</div>
        <div>כתובת: {patient.address}</div>
        <div>גיל: {utils.calcualteAge(patient.birthday)}</div>
    </div>
  )
}

export default PrintInfo