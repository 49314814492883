import React , {useState , useRef, useEffect, useContext} from 'react'
import './NewClientCard.css'
import { useFormik } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as Yup from 'yup';
import { Button , ListGroup } from 'react-bootstrap';
import api from '../../api/AxiosConfig'
import useCitiesAndStreets from '../../../utils/apis/gov_api/GovApi'
import { AppContext } from '../../../context/AppContext';
import { ac } from '../../../mobx/AppContoller';
import { mainmm } from '../../../mobx/MainMenuManager'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import utils from '../../../utils/utils';
import GenListGroup from '../../GenListGroup/GenListGroup';
import url from '../../../context/Url';
import { Typeahead } from 'react-bootstrap-typeahead';
import GenButton from '../../buttons/GenButton'
import LoadingSpinner from '../../loading_spinner/LoadingSpinner';
import SocketService from '../../sockets/SocketService';
import { modalInterface } from '../../../mobx/ModalInterface';
import messages from '../../../utils/messages/messages'
import upload_utils from '../../../utils/upload_utils';
import Cropper from 'react-easy-crop';
import ImageCropper from '../../image_cropper/ImageCropper';
import validatePatient from '../../../utils/validation/validation';
import TextEditor from '../../text_editor/TextEditor'


function NewClientCard() {
  const {appContext} = useContext(AppContext)
  const { cities, streets, selectedCity, setSelectedCity } = useCitiesAndStreets();
  const [medical , setMedcial] = useState([])
  const [insurence , setInsurence] = useState([])
  const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const [imageSrc, setImageSrc] = useState("https://via.placeholder.com/200x200");
  const [selectedFile , setSelectedFile] = useState(null);
  const fileInputRef = useRef();

  const [citySuggestions , setCitySuggestions] = useState([])
  const [streetsSuggestions , setStreetSuggestions] = useState([])
  const [medicalSuggestions , setMedicalSuggestions] = useState([])
  const [insurenceSuggestions , setInsurenceSuggestions] = useState([])
  const [age, setAge] = useState(null)
  const [medicalDiagnostics , setMedicalDiagnostics] = useState([])

  const [showSecondaryPhone , setShowSecondaryPhone] = useState(false)
  const [showWorkPlace , setShowWorkPlace] = useState(false)
  const [loading , setLoading] = useState(false)
  const [styleSheet , setStyleSheet] = useState({})

  const [dateDay , setDateDay] = useState()
  const [dateMonth , setDateMonth] = useState()
  const [dateYear , setDateYear] = useState() 

  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);

  function handleFileInput(event){
    if(!event.target.files[0]){
      return
    }
    const file = event.target.files[0]
    const fileValid = upload_utils.validateImageFile(file)
    if(!fileValid){
      modalInterface.createModal("התראה" , "אינך יכול להעלות קובץ מסוג זה!" , null , null , 5 * 1000)
      return
    }
    modalInterface.createModal("חיתוך" , null , null , <ImageCropper file={file}  onSave={handleSave} onAbort={handleAbort}/>)
    event.target.value = ''
  }

  const handleSave = (croppedImageUrl) => {
    setSelectedFile(croppedImageUrl);
    const reader = new FileReader();
    
    reader.onload = () => {
      setImageSrc(reader.result)
    }
    reader.readAsDataURL(croppedImageUrl)
    modalInterface.closeModal()
  };

  const handleAbort = (file) => {
      setSelectedFile(file);
      modalInterface.closeModal()
  };

  function handleTypeaHeadChange(value , name){
    formik.setFieldValue(name , value);
  } 

  function handleCameraClick(){
    fileInputRef.current?.click();
  }

  //Those two new function are beacuse Typeahead onInputChange return a value and not an event
  async function handleCityChange(value){
    formik.setFieldValue("city" , value);
    filterCities(value)
  }

  async function handleStreetChange(value){
    formik.setFieldValue("address" , value);
    filterStreets(value)
  }

  const initialValues = {
      first_name: '',
      last_name: '',
      phone: '',
      secondary_phone: '',
      email: '',
      secondary_email: '',
      city: 'בת ים',
      address: '',
      allow_sms: false,
      allow_email: false,
      allow_mail: false,
      allow_phone: false,
      id: '',
      social_number: '',
      birthday: '',
      gender: '',
      status: '',
      medical_institute: '',
      insurence_type: '',
      occupation: '',
      work_place: '',
      note: '',
      refer_by: '',
      refer_date: ''
    }

  useEffect(() => {
    init()
  },[])

  function init(){
    const values = JSON.parse(localStorage.getItem("new-client"))
    if(values){
      formik.setValues(values)
    }
    getSuggestions()
  }
  
  async function createUser(values){
    if(!formik.dirty){
      modalInterface.createModal("התראה", 'אנא מלא את אחד מן השדות')
      return
    }
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('patient', JSON.stringify(values))
      formData.append('diseases', JSON.stringify(medicalDiagnostics))
      formData.append('image', selectedFile)
      const response = await api.post('/auth/create_new_patient', formData)
      if(response.data.status){
        setLoading(false)
        localStorage.removeItem("new-client")
        SocketService.onNewPatients(response.data.patient)
        ac.insertNewPatient(response.data.patient)
        ac.setNewPatientId(response.data.patientID , response.data.patient)

        mainmm.changeCard("search")
      } else {
        setLoading(false)
        const missingField = utils.findMissingField(response.data.fields)
        modalInterface.createModal("שגיאה" ,` ${messages.errors[response.data.message]} ${missingField ? missingField : ''}`)
      }

    } catch (e) {
      console.log(e);
    }
  }

  const onSubmit = async (values) => {
      createUser(values)
  }

  const validate = values => {

  }

  const validationSechema = Yup.object({
    first_name: Yup.string().required(),
    last_name: Yup.string().required()
  })
  
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  function handleChange(e){
    formik.handleChange(e)
    localStorage.setItem("new-client", JSON.stringify(formik.values))
  }

  function filterStreets(input){
    const filteredStreets = streets.filter(street => street.includes(input))
    setStreetSuggestions(filteredStreets)
  }

  function filterCities(input){
    const filteredCity = cities.filter(city => city.includes(input))
    setCitySuggestions(filteredCity)
  }

  function filterMedicalInstitute(input){
    const filteredInstitue = medical.filter(institute => institute.includes(input))
    setMedicalSuggestions(filteredInstitue)
  }

  function filterInsurnce(input){
    const filteredInsurence = insurence.filter(insurence => insurence.includes(input))
    setInsurenceSuggestions(filteredInsurence)
  }

  const handleSelectCity = (suggestion) => {
    formik.setFieldValue("city" , suggestion);
    setSelectedCity(suggestion[0])
    setCitySuggestions([])

  };

  const handleSelectStreet = (suggestion) => {
    formik.setFieldValue("address" , suggestion);
    setStreetSuggestions([])
  };

  const handleSelectMedical = (suggestion) => {
    formik.setFieldValue("medical_institute" , suggestion);
    setMedicalSuggestions([])
  }

  const handleSelectInstitute = (suggestion) => {
    formik.setFieldValue("insurence_type" , suggestion);
    setInsurenceSuggestions([])
  }

  const addRow = () => {
    setMedicalDiagnostics([...medicalDiagnostics, { disease: '', disease_found_date: new Date() }]);
  };

  const removeRow = (index) => {
    setMedicalDiagnostics(medicalDiagnostics.filter((item, i) => i !== index));
  };

  const handleDiagnosticChange = (index, e) => {
    const updatedDiagnostics = medicalDiagnostics.map((item, i) => {
      if (i === index) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setMedicalDiagnostics(updatedDiagnostics);
  };

  async function getSuggestions(){
    try {
      const response = await api.get('/main/get-new-user-suggestions')
     
      if(response.data.status){
        setMedcial(response.data.suggestions.medicalSuggestionsArray)
        setInsurence(response.data.suggestions.insurenceSuggestionsArray)
      }
     
    } catch (e) {
      console.log(e);
    }
  }

  function handleOption(option){
    if (typeof option === 'object' && option.label) {
        return typeof option.label === 'string' ? option.label.toLowerCase() : option.label.toString();
    }
    // If the option is a string, return it in lowercase
    else if (typeof option === 'string') {
        return option.toLowerCase();
    }
    // If the option is a number, convert it to a string
    else if (typeof option === 'number') {
        return option.toString();
    }
    // Default case to handle other unexpected types
    else {
        return '';
    }
  }

  function handleValidation(e){
    const value = e.target.value
    const name = e.target.name
    let valid = false
    if(name == "first_name" || name == "last_name"){
      valid = validatePatient.validatePatientName(value)
    } else {
      valid = validatePatient.validatePatientSocialNum(value)
    }
    if(!valid){
      setStyleSheet(prev => ({
        ...prev,
        [name]: 'highlight'
      }))
    } else {
      setStyleSheet(prev => ({
        ...prev,
        [name]: null
      }))
    }
    
  }

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
        case 'day':
          if (value === "" || /^[0-9]+$/.test(value)) {
            setDateDay(value);
            if (value.length === 2) {
              monthInputRef.current.focus();
            }
          }
            break;
        case 'month':
          if (value === "" || /^[0-9]+$/.test(value)) {
            setDateMonth(value);
            if (value.length === 2) {
              yearInputRef.current.focus();
            }
          }
           
            break;
        case 'year':
          if (value === "" || /^[0-9]+$/.test(value)) {
            setDateYear(value);
          }
            break;
        default:
            break;
    }
};


function handleCalculateAge() {
  if (dateDay && dateMonth && dateYear) {
      const date = `${dateYear}-${dateMonth.padStart(2, '0')}-${dateDay.padStart(2, '0')}`;
      console.log(date);
      const age = utils.calcualteAge(date);
      console.log(getDateFormat());
      formik.setFieldValue('birthday' , getDateFormat())
      if (isNaN(age)) {
          setAge("");
      } else {
          setAge(age);
      }
  }
}

function getDateFormat() {
  if (dateDay && dateMonth && dateYear) {
    // Create a Date object in UTC
    const date = new Date(Date.UTC(dateYear, dateMonth - 1, dateDay));
    return date; // Optionally, format the date as required
  }
  return null;
}


  return (
    <div className='new_client container-fluid' style={{position: 'relative'}}>
      <LoadingSpinner visible={loading}/>
      <div className='new_client_header row'>
        <h2>הוספת לקוח חדש</h2>
        <hr className='new_client_header_line'></hr>
      </div>
      <Form className='new_client_form row' onSubmit={formik.handleSubmit}>
        
        <Col>
          <Row> 
            <Row>
              <Form.Label  className='section_header additional_info_text'>שם הלקוח</Form.Label>
            </Row>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="שם פרטי" className={styleSheet?.first_name} onBlur={(e) => handleValidation(e)} onChange={handleChange} value={formik.values.first_name} name='first_name'/>
              <Form.Label className='input_label'>שם פרטי *</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="שם משפחה"  className={styleSheet?.last_name}  onBlur={(e) => handleValidation(e)} onChange={handleChange} value={formik.values.last_name} name='last_name'/>
              <Form.Label className='input_label'>שם משפחה *</Form.Label>
            </Form.Group>
          </Row>

          <Row> 
            <Row >
              <div className='flex-row additional_info_text'>
                <Form.Label className='section_header'>יצירת קשר</Form.Label>
                <FontAwesomeIcon 
                tabIndex={0}
                onClick={() => setShowSecondaryPhone(prev => !prev)} 
                icon={showSecondaryPhone ? faMinus : faPlus}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setShowSecondaryPhone(prev => !prev);
                  }
                }} />
              </div>
              
            </Row>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="טלפון נייד" onChange={handleChange} value={formik.values.phone} name='phone'/>
              <Form.Label className='input_label'>טלפון נייד</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" auto placeholder="דואר אלקטרוני" onChange={handleChange} value={formik.values.email} name='email'/>
              <Form.Label className='input_label'>דואר אלקטרוני</Form.Label>
            </Form.Group>
          </Row>

          {showSecondaryPhone ?
          <Row>
           <Form.Group className="mb-1 col-lg-5">
             <Form.Control type="text" placeholder="טלפון נוסף" onChange={handleChange} value={formik.values.secondary_phone} name='secondary_phone'/>
             <Form.Label className='input_label'>טלפון נוסף</Form.Label>
           </Form.Group>
           <Form.Group className="mb-1 col-lg-5">
             <Form.Control type="text" placeholder="דואר אלקטרוני נוסף" onChange={handleChange} value={formik.values.secondary_email} name='secondary_email'/>
             <Form.Label className='input_label'>טלפון נוסף</Form.Label>
           </Form.Group>
          </Row> : null}
         

          <Row>
          <Form.Group className="mb-1 col-lg-5">
            <Typeahead
                  style={{textAlign: 'right'}}
                  id="city"
                  options={citySuggestions}
                  onChange={handleSelectCity}
                  onInputChange={handleCityChange}
                  name='city'
                  placeholder="שם יישוב"
                  value={formik.values.city}

                  
                >
            </Typeahead>
            <Form.Label className='input_label'>יישוב</Form.Label>
          </Form.Group>
          <Form.Group className="mb-1 col-lg-5">
            <Typeahead
                  style={{textAlign: 'right'}}
                  id="address"
                  // onChange={handleInputChange}
                  options={streetsSuggestions}
                  onChange={handleSelectStreet}
                  onInputChange={handleStreetChange}
                  name='address'
                  placeholder="כתובת"
                  value={formik.values.address}
                >
            </Typeahead>
            <Form.Label className='input_label'>כתובת</Form.Label>
            </Form.Group>
          </Row>

          <Row>
            <Row>
              <Form.Label className='section_header input_label'>מאשר\ת שליחת מסרים פרסומיים באמצעות:</Form.Label>
            </Row>
            <Row className="">
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label="SMS" onChange={handleChange} name='allow_sms' value={formik.values.allow_sms} checked={formik.values.allow_sms} className="custom-checkbox"/>
                </Form.Group>
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label='דוא"ל' onChange={handleChange} name='allow_email' value={formik.values.allow_email} checked={formik.values.allow_email}  className="custom-checkbox" />
                </Form.Group>
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label="טלפון" onChange={handleChange} name='allow_phone' value={formik.values.allow_phone} checked={formik.values.allow_phone}  className="custom-checkbox"/>
                </Form.Group>
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label="דואר ישראל" onChange={handleChange} name='allow_mail' value={formik.values.allow_mail} checked={formik.values.allow_mail}  className="custom-checkbox"/>
                </Form.Group>
            </Row>
          </Row>
          <Row>
            <Form.Group className="mb-1 col-lg-12 ">
                <TextEditor value={formik.values.note} placeholder={"הערות"} setValue={(value) => formik.setFieldValue('note' , value)} />
                {/* <Form.Control className='user_notes' as='textarea' rows={5} onChange={handleChange} value={formik.values.note} name='note'/> */}
                <Form.Label className='input_label'>הערות לקוח</Form.Label>
            </Form.Group>
          </Row>
          

        </Col>

        <Col>
          <Row>
            <Col className='col-lg-6'>
              <Row>
                <Form.Label className='section_header additional_info_text'>מזהה לקוח</Form.Label>
              </Row>
              <Row>
              <Form.Group className="mb-1 col-lg-8">
                
                <Form.Control type="text" placeholder="מספר לקוח" disabled value={formik.values.id} name='id'/>
                <Form.Label className='input_label'>מס' לקוח</Form.Label>
              </Form.Group>
              </Row>
              <Row>
              <Form.Group className="mb-1 col-lg-8">
                <Form.Control type="text" placeholder="מספר זהות"  onBlur={(e) => handleValidation(e)} className={styleSheet?.social_number}  value={formik.values.social_number} onChange={handleChange} name='social_number'/>
                <Form.Label className='input_label'>מס' זהות *</Form.Label>
              </Form.Group>
              </Row>
            </Col>
            <Col>
              <Row>
                <Form.Group className="mb-1 col-lg-2 user_profile_pic" onClick={handleCameraClick}>
                <Form.Control type="file" onChange={handleFileInput} ref={fileInputRef} style={{display: 'none'}} accept='.png,.jpeg,.jpg'/>
                {imageSrc ? 
                  <img className="new_client_image" src={imageSrc} ></img> :
                  null
                }
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Row>
            <Row>
              <div className='flex-row additional_info_text'>
                <Form.Label className='section_header' >פרטיים אישיים</Form.Label>
                <FontAwesomeIcon 
                tabIndex={0}
                onKeyDown={(event) => {
                  // Check if the Enter key is pressed
                  if (event.key === "Enter") {
                    setShowWorkPlace(prev => !prev);
                  }
                }}
                onClick={() => setShowWorkPlace(prev => !prev)} 
                icon={showWorkPlace ? faMinus : faPlus} />
              </div>
            </Row>
            <Row>
            <Form.Group className="mb-1 col-lg-2 width-override">
                <Form.Control className='date_year' ref={yearInputRef}  onChange={handleDateChange} value={dateYear} maxLength={4}  onBlur={(e) => handleCalculateAge(e)}  name='year'/>
                <Form.Label className='input_label'>שנה</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-1">
                <Form.Control className='date_month' ref={monthInputRef} onChange={handleDateChange} value={dateMonth} maxLength={2} onBlur={(e) => handleCalculateAge(e)}  name='month'/>
                <Form.Label className='input_label'>חודש</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-1">
                <Form.Control className='date_day' onChange={handleDateChange} value={dateDay} maxLength={2} onBlur={(e) => handleCalculateAge(e)}  name='day'/>
                <Form.Label className='input_label'>יום</Form.Label>
              </Form.Group>
              {/* <Form.Group className="mb-1 col-lg-3">
                <Form.Control type="date" onChange={handleChange} value={formik.values.birthday} onBlur={(e) => handleCalculateAge(e)} name='birthday'/>
                <Form.Label className='input_label'>תאריך לידה</Form.Label>
              </Form.Group> */}
              <Form.Group className="mb-1 col-lg-2">
                <Form.Control type="text" placeholder="גיל" disabled value={age} name='age'/>
                <Form.Label className='input_label'>גיל</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-2">
              <Form.Select id='gender' name='gender' value={formik.values.gender} onChange={(event) => formik.setFieldValue('gender' , event.target.value)}>
           
                  <option value={'זכר'}>זכר</option>
                  <option value={'נקבה'}>נקבה</option>
                  <option value={'אחר'}>אחר</option>
                </Form.Select>
                {/* <Typeahead
                    style={{textAlign: 'right'}}
                    id="gender"
                    // onChange={handleInputChange}
                    options={["זכר",  "נקבה" , "אחר"]}
                    onChange={(selected) => formik.setFieldValue('gender' , [selected])}
                    onInputChange={(value) => formik.setFieldValue('gender' , value)}
                    name='gender'
                    placeholder="מין"
                    value={formik.values.gender}
                  >
              </Typeahead> */}
                {/* <Form.Control type="text" placeholder="מין" onChange={handleChange} value={formik.values.gender} name='gender'/>
                <Form.Label className='input_label'>מין</Form.Label> */}
              </Form.Group>
              <Form.Group className="mb-1 col-lg-3">
                <Form.Control type="text" placeholder="סטטוס" onChange={handleChange} value={formik.values.status} name='status'/>
                <Form.Label className='input_label'>סטטוס</Form.Label>
              </Form.Group>
            </Row>
        </Row>
        <Row>
          <Form.Group className="col-lg-5">
                <Typeahead
                  id="test_rtl"
                  className="Etamar"
                  onInputChange={(value) => handleTypeaHeadChange(value , "medical_institute")}
                  options={medical}
                  onChange={handleSelectMedical}
                  name='medical_institute'
                  placeholder="קופת חולים"
                  value={formik.values.medical_institute}
                  
                >
                </Typeahead>
                <Form.Label className='input_label'>קופת חולים</Form.Label>
            </Form.Group>
          <Form.Group className="col-lg-5">
            <Typeahead
              id="insurence_type"
              onInputChange={(value) => handleTypeaHeadChange(value , "insurence_type")}
              options={insurence}
              onChange={handleSelectInstitute}
              name='insurence_type'
              placeholder="סוג ביטוח"
              value={formik.values.insurence_type}
            >
            </Typeahead>
            <Form.Label className='input_label'>סוג ביטוח</Form.Label>
          </Form.Group>
        </Row>
        {showWorkPlace ?
        <Row>
        <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" onChange={handleChange} placeholder='מקצוע' value={formik.values.occupation}  name='occupation'/>
              <Form.Label className='input_label'>מקצוע</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="מקום עבודה" onChange={handleChange} value={formik.values.work_place} name='work_place'/>
              <Form.Label className='input_label'>מקום עבודה</Form.Label>
            </Form.Group>
        </Row> : null}
        <Row>
            <div className='flex-row additional_info_text'>
              <Form.Label className='section_header'>גורם מפנה</Form.Label>
            </div>
          </Row>
          <Row>
            <Form.Group className="mb-1 col-lg-5">
                <Form.Control type="text" placeholder='גורם מפנה' onChange={handleChange} value={formik.values.refer_by}  name='refer_by'/>
                <Form.Label className='input_label'>גורם מפנה</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-5">
                <Form.Control type="date" placeholder="תאריך ההפנייה" onChange={handleChange} value={formik.values.refer_date} name='refer_date'/>
                <Form.Label className='input_label'>תאריך ההפנייה</Form.Label>
            </Form.Group>
          </Row>
        <Row>
            <div className='flex-row additional_info_text mb-4'>
              <Form.Label className='section_header'>אבחנות רפואיות</Form.Label>
              <FontAwesomeIcon 
              tabIndex={0}
              onKeyDown={(event) => {
                // Check if the Enter key is pressed
                if (event.key === "Enter") {
                  addRow()
                }
              }}
              onClick={addRow} 
              icon={faPlus} />
            </div>
          </Row>
          <div className='new_client_medical_diagnose'>
          {medicalDiagnostics.map((item , index) => {
            
            return(
              <Row key={index}>
                <Form.Group className="mb-1 col-lg-5">
                  <Form.Control type="text" placeholder='האבחנה' onChange={(e) => handleDiagnosticChange(index, e)} value={item.disease}  name='disease'/>
                  <Form.Label className='input_label'>האבחנה</Form.Label>
                </Form.Group>
                <Form.Group className="mb-1 col-lg-5">
                  <Form.Control type="date" placeholder="תאריך האבחנה" onChange={(e) => handleDiagnosticChange(index, e)} value={item.disease_found_date} name='disease_found_date'/>
                  <Form.Label className='input_label'>תאריך האבחנה</Form.Label>
                </Form.Group>
                <div className='col-lg-1'>
                  <FontAwesomeIcon 
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      removeRow(index)
                    }
                  }}
                  onClick={() => removeRow(index)} 
                  icon={faCancel} />
                </div>
              </Row>
            )
          })}
          </div>
         
          
          <Row>
            <Button type='submit'>שמירה</Button>
          </Row>
        </Col>  
        
      </Form>  
      
            
    </div>
  )
}

export default NewClientCard