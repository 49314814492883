import React , {useEffect, useState} from 'react'
import './CardTitle.css'
import { faArrowLeft, faChevronLeft, faChevronRight, faFloppyDisk, faMinusCircle, faPlusCircle, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import utils from '../../../utils/utils';
import GenButton from '../../buttons/GenButton';
import { ac } from '../../../mobx/AppContoller';
import { observer } from "mobx-react";
import { modalInterface } from '../../../mobx/ModalInterface';
import { mainmm } from '../../../mobx/MainMenuManager';
import ExamineDropDown from '../../DropDownMenu/ExamineDropDown/ExamineDropDown';

const CardTitle = observer(({ examines, current_examine , title , handlePrint , hidePrint = false , onSaveClick , setMinimize , minimize , saveColor , type , buttonText = "שמירה" , displayExamines = true , handleAutofill}) => {
    const [date , setDate] = useState(new Date())
    const [totalExamines , setTotalExamines] = useState()

    function init(){
        if(examines){
            setTotalExamines(examines.length)
            if(current_examine != 0){
                const currentDate = examines[current_examine - 1]?.creation_datetime
                setDate(currentDate)   
            } else {
                setDate(new Date())
            }
              
        }
         
    }

    function handleMinimize(){
        mainmm.setAnchor(type)
    
        setMinimize(prev => !prev)
    }

    function navigateExamines(action){
        if(totalExamines == 0){
            return
        }
        if(action == "right"){
            if(current_examine + 1 > totalExamines){
                setDate(new Date())
                ac.setExamines(type , 0)
                return
            }
            ac.setExamines(type ,current_examine + 1)
        }
        if(action == "left"){
            if(current_examine == 0){
                ac.setExamines(type , totalExamines)
                return
            }
            else if(current_examine - 1 <= 0){
                ac.setExamines(type , 0)
                setDate(new Date())
                return
            }
            ac.setExamines(type , current_examine - 1)
        }
    }


    function setHeader(){
        return `בדיקה ${current_examine == 0 ? "חדשה" : current_examine} מתוך ${totalExamines}`
    }

    useEffect(() => {
        init()
    },[examines , current_examine])

    

  return (
    <div className='title-container container-fluid no-print'>
        <div className='title'>
            <FontAwesomeIcon title={`${minimize ? "הרחבה" : "צמצום"}`} onClick={() => handleMinimize()} size='xl' icon={minimize ? faPlusCircle : faMinusCircle} />
            <h2>{title}</h2>
            
        </div>
        <div className='inspections'>
            <div className='title-content'>
                
                {displayExamines ? <div> 
                    <div className='title-header'>
                    <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => navigateExamines("right")} title='בדיקה הבאה'  enableBackground={"#e57373"} size={'lg'} icon={faChevronRight} />
                    {utils.fromatDate(date)}
                    <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => navigateExamines("left")} title='בדיקה הקודמת' enableBackground={"#e57373"} size={'lg'} icon={faChevronLeft} />
                </div>
                
                 <div className='title-sub-header'>
                 {setHeader()}
                </div> 
                </div> : null}
                
               
            </div>
        </div>
        <div className='buttons'>
            <div className='refrection-button-container'>
                {!minimize ? 
                <>
                
                <GenButton text={current_examine == 0 || !current_examine ? buttonText : "עדכן"} onClick={onSaveClick} backgroundColor={saveColor} textColor={'#fff'} optionalIcon={type == "upload" ? null : faFloppyDisk} ></GenButton>
                {!hidePrint ? <GenButton text={'הדפסה'} backgroundColor={saveColor} onClick={handlePrint} textColor={'#fff'} optionalIcon={faPrint} ></GenButton> : null}
                <ExamineDropDown handleAutofill={handleAutofill} />
                </>
                
                : null }
          </div>
        </div>
        
        
    </div>
)})

export default CardTitle