import { observable, action, makeObservable , toJS } from "mobx";

class FormSuggestionManager {
    loaded = false
    lastLoadedTime = null
    glassesOrderSuggestion = null
    lensesExamineSuggestion = null
    lensesOrderSuggestion = null
    checkerNameSuggestion = null

    constructor(){
        makeObservable(this , {
            loaded: observable,
            lastLoadedTime: observable,
            glassesOrderSuggestion: observable,
            lensesExamineSuggestion: observable,
            lensesOrderSuggestion: observable,
            checkerNameSuggestion: observable,
            resetSuggestions: action,
            setSuggestions: action,
            addNewGlassesOrderSuggestions: action,
        })
    }

    setSuggestions(suggestions){
        this.loaded = true
        this.lastLoadedTime = new Date
        this.glassesOrderSuggestion = suggestions.glasses
        this.lensesExamineSuggestion = suggestions.lenses
        this.lensesOrderSuggestion = suggestions.lensesOrder
        this.checkerNameSuggestion = suggestions.checkerName
 
    }

    resetSuggestions(){
        this.loaded = false
        this.lastLoadedTime = null
        this.glassesOrderSuggestion = null
        this.lensesExamineSuggestion = null
        this.lensesOrderSuggestion = null
        this.checkerNameSuggestion = null
    }

    addNewGlassesOrderSuggestions(field , value){
        const suggestionsArr = this.glassesOrderSuggestion[field]
        suggestionsArr.push(value)
    }

}

export const fsm = new FormSuggestionManager();
