import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import'./TextEditor.css'

export default function TextEditor({ value, setValue, defaultRows = 3 , placeholder}) {
  const editorRef = useRef(null);
  
  // Modules configuration for React Quill
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }, { 'align': [] }],
    ],
  };
  
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'direction', 'align'
  ];
  
  function handleClasses(){
    const textareas = document.getElementsByClassName("ql-editor");
    const toolbars = document.getElementsByClassName('ql-toolbar');

    Array.from(textareas).forEach(textarea => {
        textarea.classList.add('force-rtl');
        textarea.classList.add('borders');
    });

    Array.from(toolbars).forEach(toolbar => {
        toolbar.classList.add('no-print');
    });
    
  }

  useEffect(() => {
    if (editorRef.current) {
        handleClasses()
        editorRef.current.getEditor().root.style.height = `${defaultRows * 30}px`; // Example: 3 rows * 20px each
        editorRef.current.getEditor().root.style.fontSize = "medium"
    }
  }, []);



  return (
    <ReactQuill
      ref={editorRef}
      theme="snow"
      modules={modules}
      formats={formats}
      value={value}
      onChange={setValue}
      placeholder={placeholder}
      style={{width: '100%'}}
      className='print-override'
     
    />
  )
}