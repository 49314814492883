import React , {useState , useRef, useEffect, useContext} from 'react'
import './EditClientCard.css'
import { useFormik } from 'formik';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import api from '../../api/AxiosConfig'
import { AppContext } from '../../../context/AppContext';
import { ac } from '../../../mobx/AppContoller';
import { mainmm } from '../../../mobx/MainMenuManager'
import { faCircleDot, faMagnifyingGlass, faPlus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye , faPhone} from '@fortawesome/free-solid-svg-icons';
import utils from '../../../utils/utils';
import useCitiesAndStreets from '../../../utils/apis/gov_api/GovApi'
import AvatarPlaceHolder from '../../../assets/svgs/avatar-place-holder.svg'
import MalePlaceHolder from '../../../assets/svgs/male-place-holder.svg'
import FemalePlaceHolder from '../../../assets/svgs/female-place-holder.svg'
import { Typeahead } from 'react-bootstrap-typeahead';
import url from '../../../context/Url';
import { modalInterface } from '../../../mobx/ModalInterface';
import DiseasesModal from '../../modal/diseases_modal/DiseasesModal';
import LoadingSpinner from '../../loading_spinner/LoadingSpinner';
import Svg from '../../../utils/svg';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import upload_utils from '../../../utils/upload_utils';
import messages from '../../../utils/messages/messages';
import ImageCropper from '../../image_cropper/ImageCropper';
import TextEditor from '../../text_editor/TextEditor';


function EditClientCard() {
  const {appContext} = useContext(AppContext)
  const { cities, streets, selectedCity, setSelectedCity } = useCitiesAndStreets();
  const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile , setSelectedFile] = useState(null);
  const fileInputRef = useRef();

  const [age, setAge] = useState(null)
  const [medicalDiagnostics , setMedicalDiagnostics] = useState([])

  const [citySuggestions , setCitySuggestions] = useState([])
  const [streetsSuggestions , setStreetSuggestions] = useState([])

  const [showSecondaryPhone , setShowSecondaryPhone] = useState(false)
  const [showWorkPlace , setShowWorkPlace] = useState(false)
  const [loading , setLoading] = useState(false)

  const [dateDay , setDateDay] = useState()
  const [dateMonth , setDateMonth] = useState()
  const [dateYear , setDateYear] = useState() 

  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);

  const initialValues = {
    first_name: '',
    last_name: '',
    phone: '',
    secondary_phone: '',
    email: '',
    secondary_email: '',
    city: '',
    address: '',
    allow_sms: '',
    allow_email: '',
    allow_mail: '',
    allow_phone: '',
    id: '',
    social_number: '',
    birthday: '',
    gender: '',
    status: '',
    medical_institute: '',
    insurence_type: '',
    occupation: '',
    work_place: ''
  }

  function handleFileInput(event){
    if(!event.target.files[0]){
      return
    }
    const file = event.target.files[0]

    const fileValid = upload_utils.validateImageFile(file)
    if(!fileValid){
      modalInterface.createModal("התראה" , "אינך יכול להעלות קובץ מסוג זה!" , null , null , 5 * 1000)
      return
    }

    modalInterface.createModal("חיתוך" , null , null , <ImageCropper file={file}  onSave={handleSave} onAbort={handleAbort}/>)

    event.target.value = ''
  }

  const handleSave = (croppedImageUrl) => {
    setSelectedFile(croppedImageUrl);
    const reader = new FileReader();
    
    reader.onload = () => {
      setImageSrc(reader.result)
    }
    reader.readAsDataURL(croppedImageUrl)
    modalInterface.closeModal()
  };

  const handleAbort = (file) => {
      setSelectedFile(file);
      modalInterface.closeModal()
  };

  function handleCameraClick(){
    fileInputRef.current?.click();
  }

  async function updateUser(values){
    try {
      const formData = new FormData()
      setLoading(true)
      formData.append('patient', JSON.stringify(values))
      formData.append('image', selectedFile)
      formData.append('user_id' , appContext.userData.id)
      const response = await api.post('/auth/update_existing_patient' , formData)
      
      if(response.data.status){
        ac.updatePatient(ac.currentIndex , response.data.patient)
        ac.setCurrentPatientData(response.data.patient)
        modalInterface.createModal("התראה" , "המשתמש עודכן בהצלחה" , null , null , 5 * 1000)
        setLoading(false)
      } else {
        const missingField = utils.findMissingField(response.data.fields)
        modalInterface.createModal("שגיאה" ,` ${messages.errors[response.data.message]} ${missingField ? missingField : ''}`)
        setLoading(false)
      }
      setSelectedFile(null)
      getPatientInfo()
    } catch (e) {
      console.log(e);
    }
    
  }

  function getDateFormat() {
    if (dateDay && dateMonth && dateYear) {
      // Create a Date object in UTC
      const date = new Date(Date.UTC(dateYear, dateMonth - 1, dateDay));
      return date; // Optionally, format the date as required
    }
    return null;
  }
  const onSubmit = async (values) => {
    updateUser(values)
  }

  const validate = values => {

  }

  const validationSechema = Yup.object({
    first_name: Yup.string().required(),
    last_name: Yup.string().required()
  })
  
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  function handleChange(e){
    formik.handleChange(e)
  }

  function onExamimeClick(examine){
    mainmm.changeCard("info")
  }

  async function getPatientInfo(){
    try {
      setLoading(true)
      const response = await api.post('/main/get-patient' , {
        user_id: appContext.userData.id,
        patient_id: ac.currentPatient,
      })
      
      const patientInfo = response.data.patient
      const patientDisease = response.data.diseases
      ac.setCurrentPatientDiseases(patientDisease)
      formik.setValues(patientInfo[0])
      setSelectedCity(patientInfo[0].city)

      setDate(patientInfo[0].birthday)
      setDateDay(utils.getDateDay(patientInfo[0].birthday))
      setDateMonth(utils.getDateMonth(patientInfo[0].birthday))
      setDateYear(utils.getDateYear(patientInfo[0].birthday))
      
      if(String(patientInfo[0].image_path).length > 0){
        setImageSrc(`${url}/${patientInfo[0].image_path}`)
      }
      

      if(patientInfo[0].secondary_email || patientInfo[0].secondary_phone){
        setShowSecondaryPhone(true)
      }

      if(patientInfo[0].occupation || patientInfo[0].work_place){
        setShowWorkPlace(true)
      }

      if(patientDisease[0]){
        setMedicalDiagnostics(patientDisease)
      }
      setLoading(false)
    } catch (e) {
      console.log(e);
    }
    
  }

  //Cities and Address

  const handleSelectCity = (suggestion) => {
   
    if(suggestion.length > 0){
      formik.setFieldValue("city" , suggestion[0]);
      setSelectedCity(suggestion[0])
      setCitySuggestions([])
    }
    

  };

  async function handleCityChange(value){
    formik.setFieldValue("city" , value);
    filterCities(value)
  }

  const handleSelectStreet = (suggestion) => {
    if(suggestion.length > 0){
      formik.setFieldValue("address" , suggestion[0]);

      setStreetSuggestions([])
    }
    
  };

  async function handleStreetChange(value){
    formik.setFieldValue("address" , value);
    filterStreets(value)
  }

  function filterStreets(input){
    const filteredStreets = streets.filter(street => street.includes(input))
    console.log(streets);
    setStreetSuggestions(filteredStreets)
  }

  function filterCities(input){
    const filteredCity = cities.filter(city => city.includes(input))
    setCitySuggestions(filteredCity)
  }


  //Avatar

  function handleAvatarError(e , gender){
    e.target.onerror = null;
    if (gender == "זכר") {
         e.target.src = MalePlaceHolder
    } else if (gender == "נקבה") {
        e.target.src = FemalePlaceHolder
    } else {
        e.target.src = AvatarPlaceHolder
    }
    
  }

  function handleAvatar(image_path, gender){
        if(image_path){
            return (
                <img onError={(e) => handleAvatarError(e , gender)} style={{width: '200px' , height: '200px', borderRadius: "50%"}} className='client_table_icon' src={`${url}/${image_path}`}></img>
            )
        } else {
          console.log(gender);
            if(gender == "זכר"){
                return (
                    <Svg  style={{width: '200px' , height: '200px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-male'}/>
                )
            }
            else if(gender == "נקבה"){
                return (
                    <Svg  style={{width: '200px' , height: '200px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-female'}/>
                )
            } else {
                return (
                    <Svg  style={{width: '200px' , height: '200px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-general'}/>
                )
            }
        }
  }


  function setDate(dob){
    const birthday = utils.fromatDate(dob)
    formik.setFieldValue("birthday" , birthday);
    const age = utils.calcualteAge(dob)
    if(isNaN(age)){
      setAge("")
    } else {
      setAge(age)
    }
    
  }

  function toggleDisesesModal(){
    modalInterface.createModal("אבחנות רפאויות", null , null , <DiseasesModal patientDiseases={medicalDiagnostics} />)
  }

  function disesesText(){
    return (
      medicalDiagnostics.length > 0 ?
            <label>ישנן אבחנות רפואיות</label>:
            <label>אין אבחנות רפואיות</label>
        )
  }

  const handleWhatsAppClick = ()=>{
    const defaultMessage = encodeURIComponent(`שלום זה ${appContext.userData.first_name} מלויה אופטימטריה,`)
    const patient_phone =  utils.validatePhone(formik.values?.phone)
    if(!patient_phone){
      modalInterface.createModal("שגיאה",  "לא ניתן לשלוח הודעה לטלפון זה" , [{text: "אוקיי", focus: true,onclick: () => modalInterface.closeModal()}])
      return
    }
    window.open(`whatsapp://send?phone=${patient_phone}&text=${defaultMessage}`)
    // window.open(`https://wa.me/send?phone=${patient_phone}&text=${defaultMessage}`)
    return;
  }

  function handleCalculateAge() {
    if (dateDay && dateMonth && dateYear) {
        const date = `${dateYear}-${dateMonth.padStart(2, '0')}-${dateDay.padStart(2, '0')}`;
        const age = utils.calcualteAge(date);
        formik.setFieldValue('birthday' , getDateFormat())
        if (isNaN(age)) {
            setAge("");
        } else {
            setAge(age);
        }
    }
  }

  function handleOption(option){
    if (typeof option === 'object' && option.label) {
        return typeof option.label === 'string' ? option.label.toLowerCase() : option.label.toString();
    }

    else if (typeof option === 'string') {
        return option.toLowerCase();
    }

    else if (typeof option === 'number') {
        return option.toString();
    }

    else {
        return '';
    }
  }

  function isCheckBoxTrue(value){

    if(value[0]) value = value[0]
    if(value == 1 || value == "1" || value == true || value == "true"){
      return true
    } else if(value == 0 || value == "0" || value == false || value == "false"){
      return false
    } else {
      return false
    }
  }

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
        case 'day':
          if (value === "" || /^[0-9]+$/.test(value)) {
            setDateDay(value);
            if (value.length === 2) {
              monthInputRef.current.focus();
            }
          }
            break;
        case 'month':
          if (value === "" || /^[0-9]+$/.test(value)) {
            setDateMonth(value);
            if (value.length === 2) {
              yearInputRef.current.focus();
            }
          }
           
            break;
        case 'year':
          if (value === "" || /^[0-9]+$/.test(value)) {
            setDateYear(value);
          }
            break;
        default:
            break;
    }
};

  useEffect(() => {
    getPatientInfo()
    
  },[])

  return (
    <div className='new_client container-fluid' style={{position: 'relative'}}>
      <LoadingSpinner  visible={loading}/>
      <div className='new_client_header row'>
        <h2>עריכת לקוח קיים</h2>
        <hr className='new_client_header_line'></hr>
      </div>
      <Form className='new_client_form row' onSubmit={formik.handleSubmit}>
        <Col>
          <Row> 
            <Row>
              <Form.Label  className='section_header additional_info_text'>שם הלקוח</Form.Label>
            </Row>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="שם פרטי" onChange={handleChange} value={formik.values.first_name} name='first_name'/>
              <Form.Label className='input_label'>שם פרטי</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="שם משפחה" onChange={handleChange} value={formik.values.last_name} name='last_name'/>
              <Form.Label className='input_label'>שם משפחה</Form.Label>
            </Form.Group>
          </Row>

          <Row> 
            <Row >
              <div className='flex-row additional_info_text'>
                <Form.Label className='section_header'>יצירת קשר</Form.Label>
                <FontAwesomeIcon onClick={() => setShowSecondaryPhone(prev => !prev)} icon={showSecondaryPhone ? faMinus : faPlus} />
              </div>
            </Row>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="טלפון נייד" onChange={handleChange} value={formik.values.phone} name='phone'/>
              <Form.Label className='input_label'>טלפון נייד</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" auto placeholder="דואר אלקטרוני" onChange={handleChange} value={formik.values.email} name='email'/>
              <Form.Label className='input_label'>דואר אלקטרוני</Form.Label>
            </Form.Group>
          </Row>

          {showSecondaryPhone ?
          <Row>
           <Form.Group className="mb-1 col-lg-5">
             <Form.Control type="text" placeholder="טלפון נוסף" onChange={handleChange} value={formik.values.secondary_phone} name='secondary_phone'/>
             <Form.Label className='input_label'>טלפון נוסף</Form.Label>
           </Form.Group>
           <Form.Group className="mb-1 col-lg-5">
             <Form.Control type="text" placeholder="דואר אלקטרוני נוסף" onChange={handleChange} value={formik.values.secondary_email} name='secondary_email'/>
             <Form.Label className='input_label'>טלפון נוסף</Form.Label>
           </Form.Group>
          </Row> : null}
          <Row>
          <Form.Group className="mb-1 col-lg-5">
          <Typeahead
                  style={{textAlign: 'right'}}
                  id="city"
                  options={citySuggestions}
                  onChange={handleSelectCity}
                  onInputChange={handleCityChange}
                  name='city'
                  placeholder="שם יישוב"
                  selected={formik.values.city ? [formik.values.city] : []}
                  labelKey={(option) => handleOption(option)}
                  
                >
            </Typeahead>
            {/* <Form.Control
                  style={{textAlign: 'right'}}
                  id="city"
                  onChange={handleChange}
                  name='city'
                  placeholder="שם יישוב"
                  value={formik.values.city}
                >
            </Form.Control> */}
            <Form.Label className='input_label'>יישוב</Form.Label>
          </Form.Group>
          <Form.Group className="mb-1 col-lg-5">
          <Typeahead
                  style={{textAlign: 'right'}}
                  id="adress"
                  // onChange={handleInputChange}
                  options={streetsSuggestions}
                  onChange={handleSelectStreet}
                  onInputChange={handleStreetChange}
                  name='adress'
                  placeholder="כתובת"
                  selected={formik.values.address ? [formik.values.address] : []}
                  labelKey={(option) => handleOption(option)}
                >
            </Typeahead>
            {/* <Form.Control
                  style={{textAlign: 'right'}}
                  id="address"
                  onChange={handleChange}
                  name='address'
                  placeholder="כתובת"
                  value={formik.values.address}
                >
            </Form.Control> */}
            <Form.Label className='input_label'>כתובת</Form.Label>
            </Form.Group>
          </Row>

          <Row>
            <Row>
              <Form.Label className='section_header'>מאשר\ת שליחת מסרים פרסומיים באמצעות:</Form.Label>
            </Row>
            <Row className="mb-1">
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label="SMS" onChange={handleChange} name='allow_sms' value={isCheckBoxTrue(formik.values.allow_sms)} checked={isCheckBoxTrue(formik.values.allow_sms)} className="custom-checkbox"/>
                </Form.Group>
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label='דוא"ל' onChange={handleChange} name='allow_email' value={isCheckBoxTrue(formik.values.allow_email)} checked={isCheckBoxTrue(formik.values.allow_email)} className="custom-checkbox" />
                </Form.Group>
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label="טלפון" onChange={handleChange} name='allow_phone' value={isCheckBoxTrue(formik.values.allow_phone)} checked={isCheckBoxTrue(formik.values.allow_phone)} className="custom-checkbox"/>
                </Form.Group>
                <Form.Group className="mb-1 col-lg-3">
                    <Form.Check type="checkbox" label="דואר ישראל" onChange={handleChange} name='allow_mail' value={isCheckBoxTrue(formik.values.allow_mail)} checked={isCheckBoxTrue(formik.values.allow_mail)} className="custom-checkbox"/>
                </Form.Group>
            </Row>
          </Row>
          <Row>
          <Form.Group className="mb-1 col-lg-12 ">
              <TextEditor value={formik.values.note} placeholder={"הערות לקוח"} setValue={(value) => formik.setFieldValue('note' , value)} />
              {/* <Form.Control className='user_notes' type="text" onChange={handleChange} value={formik.values.note} name='note'/> */}
              <Form.Label className='input_label'>הערות לקוח</Form.Label>
          </Form.Group>
          </Row>

        </Col>

        <Col>
          <Row>
            <Col className='col-lg-6'>
              <Row>
                <Form.Label className='section_header additional_info_text'>מזהה לקוח</Form.Label>
              </Row>
              <Row>
              <Form.Group className="mb-1 col-lg-8">
                
                <Form.Control type="text" placeholder="מספר לקוח" disabled value={formik.values.id} name='id'/>
                <Form.Label className='input_label'>מס' לקוח</Form.Label>
              </Form.Group>
              </Row>
              <Row>
              <Form.Group className="mb-1 col-lg-8">
                <Form.Control type="text" placeholder="מספר זהות" value={formik.values.social_number} onChange={handleChange} name='social_number'/>
                <Form.Label className='input_label'>מס' זהות *</Form.Label>
              </Form.Group>
              </Row>
            </Col>
            <Col>
              <Row>
                <Form.Group className="mb-1 col-lg-2 user_profile_pic" onClick={handleCameraClick}>
                <Form.Control type="file" onChange={handleFileInput} ref={fileInputRef} style={{display: 'none'}} accept='.png,.jpeg,.jpg,.svg'/>
                
                {imageSrc ? 
                  <img onError={(e) => handleAvatarError(e)} className="new_client_image" src={imageSrc} /> :
                  handleAvatar(null , formik.values.gender)
                }
                
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Row>
            <Row>
              <div className='flex-row additional_info_text'>
                <Form.Label className='section_header' >פרטיים אישיים</Form.Label>
                <FontAwesomeIcon onClick={() => setShowWorkPlace(prev => !prev)} icon={showWorkPlace ? faMinus : faPlus} />
              </div>
            </Row>
            <Row>
            <Form.Group className="mb-1 col-lg-2 width-override">
                <Form.Control className='date_year' ref={yearInputRef}  onChange={handleDateChange} value={dateYear} maxLength={4}  onBlur={(e) => handleCalculateAge(e)}  name='year'/>
                <Form.Label className='input_label'>שנה</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-1">
                <Form.Control className='date_month' ref={monthInputRef} onChange={handleDateChange} value={dateMonth} maxLength={2} onBlur={(e) => handleCalculateAge(e)}  name='month'/>
                <Form.Label className='input_label'>חודש</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-1">
                <Form.Control className='date_day'  onChange={handleDateChange} value={dateDay} maxLength={2} onBlur={(e) => handleCalculateAge(e)}  name='day'/>
                <Form.Label className='input_label'>יום</Form.Label>
              </Form.Group>
              {/* <Form.Group className="mb-1 col-lg-4">
                <Form.Control type="date" onChange={handleChange} value={formik.values.birthday} onBlur={(e) => handleCalculateAge(e)}  name='birthday'/>
                <Form.Label className='input_label'>תאריך לידה</Form.Label>
              </Form.Group> */}
              <Form.Group className="mb-1 col-lg-2">
                <Form.Control type="text" placeholder="גיל" disabled value={age} name='age'/>
                <Form.Label className='input_label'>גיל</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-2">
                <Form.Select id='gender' name='gender' value={formik.values.gender} onChange={(event) => formik.setFieldValue('gender' , event.target.value)}>
                  <option value={'זכר'}>זכר</option>
                  <option value={'נקבה'}>נקבה</option>
                  <option value={'אחר'}>אחר</option>
                </Form.Select>
              {/* <Typeahead
                        style={{ textAlign: 'right' }}
                        id={`gender`}
                        options={['זכר' , 'נקבה' , 'אחר']}
                        placeholder='מין'
                        onChange={(selected) => formik.setFieldValue('gender' , selected.length > 0 ? selected[0] : '')}
                        onInputChange={(value) => formik.setFieldValue('gender' , value)}
                        labelKey={(option) => handleOption(option)}
                        name={`gender`}
                        selected={formik.values.gender ? [formik.values.gender] : []}
                    /> */}
                {/* <Form.Control type="text" placeholder="מין" onChange={handleChange} value={formik.values.gender} name='gender'/>
                <Form.Label className='input_label'>מין</Form.Label> */}
              </Form.Group>
              <Form.Group className="mb-1 col-lg-3">
                <Form.Control type="text" placeholder="סטטוס" onChange={handleChange} value={formik.values.status} name='status'/>
                <Form.Label className='input_label'>סטטוס</Form.Label>
              </Form.Group>
            </Row>
        </Row>
        <Row>
          <Form.Group className="col-lg-5">
                <Form.Control
                  id="medical_institute"
                  class="rtl_select"
                  onChange={handleChange}
                  name='medical_institute'
                  placeholder="קופת חולים"
                  value={formik.values.medical_institute}
                  
                >
                </Form.Control>
                <Form.Label className='input_label'>קופת חולים</Form.Label>
            </Form.Group>
          <Form.Group className="col-lg-5">
            <Form.Control
                onChange={handleChange}
                id="insurence_type"
                name='insurence_type'
                placeholder="סוג ביטוח"
                value={formik.values.insurence_type}
            >
            </Form.Control>
            <Form.Label className='input_label'>סוג ביטוח</Form.Label>
          </Form.Group>
        </Row>
        {showWorkPlace ?
        <Row>
        <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" onChange={handleChange} placeholder='מקצוע' value={formik.values.occupation}  name='occupation'/>
              <Form.Label className='input_label'>מקצוע</Form.Label>
            </Form.Group>
            <Form.Group className="mb-1 col-lg-5">
              <Form.Control type="text" placeholder="מקום עבודה" onChange={handleChange} value={formik.values.work_place} name='work_place'/>
              <Form.Label className='input_label'>מקום עבודה</Form.Label>
            </Form.Group>
        </Row> : null}
          <Row>
            <div className='flex-row additional_info_text'>
              <Form.Label className='section_header'>גורם מפנה</Form.Label>
            </div>
          </Row>
           <Row className='mb-1'>
            <Form.Group className="mb-1 col-lg-5">
                <Form.Control type="text" placeholder='גורם מפנה' onChange={handleChange} value={formik.values.refer_by}  name='refer_by'/>
                <Form.Label className='input_label'>גורם מפנה</Form.Label>
              </Form.Group>
              <Form.Group className="mb-1 col-lg-5">
                <Form.Control type="date"  placeholder="תאריך ההפנייה" dateFormat="MM/dd/yyyy" onChange={handleChange} value={formik.values.refer_date ? utils.fromatDate(formik.values.refer_date): formik.values.refer_date} name='refer_date'/>
                <Form.Label className='input_label'>תאריך ההפנייה</Form.Label>
            </Form.Group>
          </Row>
          <Row className='col-12 mb-5' >
            <div onClick={() => toggleDisesesModal()} className={medicalDiagnostics.length > 0 ?'header_field_row medical_condition' : "header_field_row no_medical_condition"}>
                {medicalDiagnostics.length > 0 ? <FontAwesomeIcon style={{cursor: "pointer"}} enableBackground={ "#e57373"} size={'sm'} icon={faWarning} /> : null} 
                {disesesText()}
            </div>
          </Row>
          <Row className='icons-container col-10'>
            <div className='col-1 icon-svg' style={{backgroundColor: "#98FB98"}}>
              <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => onExamimeClick("refraction")} enableBackground={"#e57373"} size={'xl'} icon={faMagnifyingGlass} />
            </div>
            <div className='col-1 icon-svg' style={{backgroundColor: "lightblue"}}>
              <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => onExamimeClick("glasses")} enableBackground={"#e57373"} size={'xl'} icon={faEye} />
            </div>
            <div className='col-1 icon-svg' style={{backgroundColor: "lightpink"}}>
              <FontAwesomeIcon style={{cursor: "pointer"}} onClick={() => onExamimeClick("lenses")} enableBackground={"#e57373"} size={'xl'} icon={faCircleDot} />
            </div>
            <div className='col-1 icon-svg'  style={{backgroundColor: "green"}}>
              <FontAwesomeIcon  style={{cursor: "pointer"}} color='white' enableBackground={"#e57373"} size={'xl'} onClick={() => handleWhatsAppClick()} icon={faWhatsapp} />
            </div>
            <Button type='submit' className='col-4'>עדכן</Button>
          </Row>
        </Col>  
      </Form>   
    </div>
  )
}

export default EditClientCard