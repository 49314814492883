const errors = {
    "PASSWORD_INCORRECT": "סיסמא שגויה",
    "CAPTCHA_FAILED": "שגיאה בקאפצה",
    "CPATCHA_REQUIRED": "נדרש אימות קאפצה",
    "LOGIN_DENIED": "התחברות נדחתה",
    "USER_NOT_EXISTS": "המשתמש לא קיים",
    "DB_ERROR": "בעיית שרת אנא פנה לאסף מדיה",
    "MISSING_FIELD": "שדה לא תקין",
    "PATIENT_EXISTS": "הלקוח כבר קיים במערכת",
    "MUST_WAIT_BEFORE_LOG_IN": "עלייך להמתין לפני נסיון כניסה חוזר",
    "INVALID_OTP_CODE": "קוד כניסה לא תקין",
    "OTP_CODE_EXPIRED": "קוד כניסה פג תוקף",
    "MUST_WAIT_BEFORE_RESEND_OTP": "עלייך להמתין לפני שליחה חדשה",
    "USER_BLOCKED_BY_CAPTCHA": "משתמש זה נחסם על ידי קאפצה",
    "PATIENT_LIST_IS_EMPTY": "לא נמצאו לקוחות במערכת",
    "FAILED_CREATING_PATIENT": "יצירת לקוח נכשלה אנא נסה שנית",
    "WRONG_FILE_TYPE": "סוג קובץ זה לא נתמך במערכת",
    "FILE_IS_TOO_LARGE": "הקובץ גדול מידי",
    "FILE_IS_TOO_SMALL": "הקובץ קטן מידי",
    "ERROR_READING_FILE": "שגיאה בקריאת הקובץ",
    "UPLOAD_FAILED": "העלאת קובץ נכשלה"
}

const fields = {
    "first_name": "שם פרטי",
    "last_name": "שם משפחה",
    "social_number": "תעודת זהות",
    "phone": "מספר טלפון",
    "sec_phone": "מספר טלפון משני",
    "email": "דואר אלקטרוני",
    "city": "יישוב",
    "address": "כתובת"
}

export default { errors , fields}