import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import './GenButton.css'

function GenButton({width = 120 , height , text , textColor , backgroundColor , onClick , delay = 1000 , type , className , optionalIcon = null}) {
    const [disabled , setDisabled] = useState(false)
    let styleSheet = {}
    let containerStyleSheet = {}
    
    if(width || height || text || textColor || backgroundColor){
        styleSheet = {
            width: width,
            height: height,
            color: textColor,
            backgroundColor: backgroundColor
        }
    }

    function onButtonClick(event){
        event.preventDefault();
        setDisabled(true)
        setTimeout(() => setDisabled(false , delay))
        if(onClick){
            onClick()
        }
    }
  return (
    <div className='custom-button-container'>
        <button onClick={(e) => onButtonClick(e)} type={type} style={styleSheet} className={`custom-button ${className}`}>
            <div className='custom-button-content'>
                <label>{text}</label>
                
                {optionalIcon ? <FontAwesomeIcon icon={optionalIcon} /> : null}
            </div>
            
        </button>
    </div>
  )
}

export default GenButton