import { observable, action, makeObservable } from "mobx";

class ModalInterface {
        visible =  false
        title = ''
        text = ''
        buttons = []
        children = null
        autoClose = null
      

      constructor() {
        makeObservable(this, {
          visible: observable,
          title: observable,
          text: observable,
          buttons: observable,
          children: observable,
          autoClose: observable,
          createModal: action,
          closeModal: action
        });
      }

      createModal(title = null , text = null , buttons = null , children = null, autoClose = null){
        this.visible = true
        this.title = title
        this.text = text
        this.buttons = buttons
        this.children = children
        this.autoClose = autoClose
      }
    
      closeModal(){
        this.visible = false
        this.title = ''
        this.text = ''
        this.buttons = null
        this.children = null
        this.autoClose = null
      }
}

export const modalInterface = new ModalInterface();