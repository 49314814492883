import { observable, action, makeObservable } from "mobx";

class MainMenuManager {
    showSearch = true
    showInfo = false
    showEdit = false
    showNew = false
    examineAnchor = null

    constructor(){
        makeObservable(this , {
            showSearch: observable,
            showInfo: observable,
            showEdit: observable,
            showNew: observable,
            examineAnchor: observable,
            changeCard: action,
            hideCards: action,
            setAnchor: action
        })
    }

    changeCard(card){
        this.hideCards()
        switch (card){
            case "search":
                this.showSearch = true
                break;
            case "info":
                this.showInfo = true
                break;
            case "edit":
                this.showEdit = true
                break;
            case "new":
                this.showNew = true
        }
    }

    hideCards(){
        this.showSearch = false
        this.showInfo = false
        this.showEdit = false
        this.showNew = false
    }

    getAnchor(){
        return this.examineAnchor
    }

    setAnchor(anchor){
        this.examineAnchor = anchor
    }

}

export const mainmm = new MainMenuManager();
