import React, { useContext, useRef, useState , useEffect} from 'react'
import api from '../../api/AxiosConfig'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './LoginForm.css'
import { modalInterface } from '../../../mobx/ModalInterface';
import { AppContext } from '../../../context/AppContext.js';
import messages from '../../../utils/messages/messages.js';

function LoginForm(props) {
    const {appContext } = useContext(AppContext)
    const [userCredentials , setUserCredentials] = useState({
        username: '',
        user_code: ''
    })

    async function handleLogin(event){
        event.preventDefault()

        if(userCredentials.username == ''){
            modalInterface.createModal("שגיאה" , "אנא הכנס שם משתמש")
            return
        }
        try {
            const response = await api.post('/auth/login' , {
                login_credentails: userCredentials.username,
                user_code: userCredentials.user_code,
                
            })
           
            if(response.data.status){
               appContext.setUserData(prev => ({
                ...prev,
                ...response.data.user_info
               }))
                props.changeForm(response.data.next)
            } else {
                modalInterface.createModal("שגיאה" , messages.errors[response.data.message])
            }
           
        } catch (e) {
            console.log(e);
        }
    }

    function handleTextChange(event){
        const { name, value } = event.target;
        const englishLettersRegex = /^[a-zA-Z0-9@.]*$/;
       
        if(englishLettersRegex.test(value) || value == ""){
            setUserCredentials(prev => ({
                ...prev,
                [name]: value
            }))
        }
        
    }


  return (
    <Form className='login_form_container col-xs-10' onSubmit={handleLogin}>
             
        <Form.Group  className="mb-3" controlId="formBasicUsername">
            <Form.Control className='login_form_input' type='text' maxLength={30} name='username' placeholder="שם משתמש" value={userCredentials.username} onChange={handleTextChange} />
        </Form.Group>

        <Form.Group className="mb-3" style={{display: 'none'}}>
            <Form.Label>תעודת זהות</Form.Label>
            <Form.Control type="text" placeholder="ת.ז" name='user_code' onChange={handleTextChange}/>
        </Form.Group>

        <Form.Group style={{justifyContent: 'flex-end' , display: 'flex' , margin: 0}}>
            <Button style={{width: "10rem" , height: '5rem' , fontSize: '2.5rem'}} variant="primary" type="submit">הבא</Button>
        </Form.Group>

    </Form>
  )
}

export default LoginForm