import {React , useEffect, useState , useRef} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { AppContext } from './context/AppContext';
import Header from './components/header/Header';

import GenModal from './components/modal/GenModal';
import Router from './navigation/Router';
import { Provider } from 'mobx-react';
import { modalInterface } from './mobx/ModalInterface';
import SideMenu from './components/sidemenu/SideMenu';
import { sm } from './mobx/ScreenManager';
import SocketService from './components/sockets/SocketService';
import { ac } from './mobx/AppContoller';
import Footer from './components/footer/Footer';

function App() {
  

  const debug = {
    showTestBtn: false
  } 

  const [userData , setUserData] = useState({
    id: null,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    last_login_attempt: null,
    access_level: 0,
    logged_in: false,
    image_path: null
  })

  const appContext = {
    userData: userData,
    setUserData: setUserData
  }

  const appcontext = {appContext}

  function onclick(){
    sm.changeScreen("test")
  }

  useEffect(() => {
    SocketService.connect()

    return () => {
      SocketService.disconnect();
      if(ac.currentPatient){
        SocketService.onPatientExit(ac.currentPatient , appContext.userData.id)
      }
    }
  },[])

  //prevent ctrl + s
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  return (
    <main className="App container-fluid" >
      <AppContext.Provider value={appcontext}>
          <GenModal visible={modalInterface.visible} title={modalInterface.title} text={modalInterface.text} buttons={modalInterface.buttons} children={modalInterface.children}/>
        <div className="header-footer row">
          <Header />
        </div>
        <div className="main-content row">
            <Router className="col-10" setUserData={setUserData} />
        </div>
        <div className="header-footer row">
          <Footer />
        </div>
      </AppContext.Provider>
  </main>
  );
}

export default App;


