import { observable, action, makeObservable} from "mobx";

class AppController {
    allPatients = []
    newPatientInfo = null
    currentPatient = null
    currentIndex = null
    currentRefractionExamine = 0
    currentGlassesExamine = 0
    currentLensesExamine = 0
    currentPatientData = {}
    currentPatientDiseases = {}

    constructor(){
        makeObservable(this, {
            currentPatient: observable,
            currentRefractionExamine: observable,
            currentGlassesExamine: observable,
            currentLensesExamine: observable,
            currentIndex: observable,
            newPatientInfo: observable,
            currentPatientData: observable,
            currentPatientDiseases: observable,
            setPatient: action,
            setExamines: action,
            setAllPatients: action,
            resetExamines: action,
            setLateset: action,
            insertNewPatient: action,
            updatePatient: action,
            setCurrentIndex: action,
            setNewPatientId: action,
            setCurrentPatientDiseases: action,
            setCurrentPatientData: action
          });
    }

    setPatient(patient){
        this.currentPatient = patient
    }

    setExamines(type , value){

        switch (type) {
            case "refraction":
                this.currentRefractionExamine = value
                break
            case "glasses":
                this.currentGlassesExamine = value
                break
            case "lenses": 
                this.currentLensesExamine = value
                break
        }
    }

    resetExamines(){
        this.currentRefractionExamine = 0
        this.currentGlassesExamine = 0
        this.currentLensesExamine = 0
    }

    setAllPatients(allPatients = []){
        this.allPatients = allPatients
    }

    insertNewPatient(patient){
        this.allPatients.unshift(patient)
    }

    updatePatient(index , patient){
        this.allPatients[index] = patient
    }

    setCurrentIndex(index){
        this.currentIndex = index
    }

    setLateset(examines){

        if(examines.refraction && this.currentRefractionExamine == 0){
            this.currentRefractionExamine = examines.refraction.length
        }
        if(examines.lenses && this.currentLensesExamine == 0){
            this.currentLensesExamine = examines.lenses.length
        }
        if(examines.glasses && this.currentGlassesExamine == 0){
            this.currentGlassesExamine = examines.glasses.length
        }
        
    }

    setNewPatientId(id , patient){
        this.newPatientInfo = patient
    }
    
    setCurrentPatientData(patient){
        this.currentPatientData = patient
    }

    setCurrentPatientDiseases(diseases){
        this.currentPatientDiseases = diseases
    }
}

export const ac = new AppController();