import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Button } from 'react-bootstrap'
import { mainmm } from '../../../mobx/MainMenuManager'
import { ac } from '../../../mobx/AppContoller'
import "./CustomDataTable.css"
import { modalInterface } from '../../../mobx/ModalInterface'
import ProfileCard from '../../cards/profile_card/ProfileCard'
import SocketService from '../../sockets/SocketService'
import { em } from '../../../mobx/ExamineManager'

// function debounce(func, wait) {
//   let timeout;
//   return function(...args) {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => func.apply(this, args), wait);
//   };
// }

function CustomDataTable({columns , data , isFixedHeader = true , isPagination = true , handleRowClick , handleRowDoubleClick , isMobile , selectedRowId , resetSearchBar}) {
  const [records , setRecords] = useState(data)
  const [search , setSearch] = useState('')

  const customStyles = {
      headCells: {
          style: {
              backgroundColor: "#f6f6f6",
              fontSize: "15px",
          },
      },
      cells: {
          style: {
              paddingLeft: '8px', // override the cell padding for data cells
              paddingRight: '8px',
              fontSize: "15px",
              width: "100%"
          },
      },
  };

  function handleFilter(event) {
    const searchTerms = event.target.value.toLowerCase().split(' '); 
 
    if (event.key === ' ' && event.target.value[event.target.value.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
    setSearch(event.target.value)
    const newData = data.filter(row => {
        return searchTerms.every(term => {

            const dateOfBirth = extractYear(row.birthday)
            const mathesID = row.id.toString().toLowerCase().includes(term);
            const matchesFirstName = row.first_name.toLowerCase().includes(term);
            const matchesLastName = row.last_name.toLowerCase().includes(term);
            const matchesPhone = row.phone.toLowerCase().includes(term);
            const matchesSocial = row.social_number.toLowerCase().includes(term);
            const matchMedical = row.medical_institute.toLowerCase().includes(term)
            const matchInsurence = row.insurence_type.toLowerCase().includes(term)
            const matchAddress = row.address.toLowerCase().includes(term)
            const matchCity = row.city.toLowerCase().includes(term)
            const matchYear = String(dateOfBirth).includes(term)

            return matchesFirstName || matchesLastName || matchesPhone || matchesSocial || mathesID || matchMedical || matchInsurence || matchAddress || matchCity || matchYear;
        });
    });

    setRecords(newData);
  }

 function extractYear(dateString){
  if(!dateString) return false
  const date = new Date(dateString)
  return date.getFullYear()
 }

  function handleNewClient(){
    mainmm.changeCard("new")
    ac.setPatient(null)
    ac.setCurrentPatientDiseases(null)
    ac.setCurrentPatientData(null)
  }

  const conditionalRowStyles = [
    {
      when: row => row.id === selectedRowId,
      style: {
        backgroundColor: '#e3f2fd', // You can customize this color as needed
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: '#90caf9', // Color on hover
        },
      },
    },
  ];

  useEffect(() => {
    setSearch('')
  },[resetSearchBar])

  useEffect(() => {
    setRecords(data);
    ac.setPatient(null)
}, [data]);

  return (
    <div className='container mt-5'>
        <div className='client_search_bar'>
          <input type='text' placeholder='חיפוש' value={search} onChange={handleFilter} onKeyDown={handleFilter}></input>
          <div>
            <Button className='new-client-button' size='lg' onClick={() => handleNewClient()}>לקוח חדש +</Button>
          </div>
        </div>
        <DataTable
          className='table_container'
          columns={columns}
          data={records}
          fixedHeader={isFixedHeader}
          pagination={isPagination}
          paginationPerPage={10}
          onRowClicked={(row) => handleRowClick(row)}
          onRowDoubleClicked={(row) => handleRowDoubleClick(row)}
          highlightOnHover
          pointerOnHover
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
          
          
        >
        </DataTable>
    </div>
  )
}

export default CustomDataTable