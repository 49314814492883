import React, { useContext, useState , useEffect, useRef} from 'react'
import HeaderCard from '../../components/examines_cards/header_card/HeaderCard'
import { ac } from '../../mobx/AppContoller'
import { AppContext } from '../../context/AppContext'
import api from '../../components/api/AxiosConfig'
import { mainmm } from '../../mobx/MainMenuManager'
import RefractionCard from '../../components/examines_cards/refraction_card/RefractionCard'
import './PatinetExamines.css'
import GlassesCard from '../../components/examines_cards/glasses_card/GlassesCard'
import LensesCard from '../../components/examines_cards/lenses_card/LensesCard'
import UploadCard from '../../components/examines_cards/upload_card/UploadCard'
import SocketService from '../../components/sockets/SocketService'
import { modalInterface } from '../../mobx/ModalInterface'
import utils from '../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from '../../components/loading_spinner/LoadingSpinner'
import PrintInfo from '../../components/print_info/PrintInfo'
import { em } from '../../mobx/ExamineManager'


function PatinetExamines() {
    const {appContext} = useContext(AppContext)
    const [patient , setPatient] = useState({})
    const [patientDiseases , setPatientDiseases] = useState({})
    const [patientExamines , setPatientExamines] = useState(null)
    const [latestExamine, setLatestExamine] = useState()
    const [allowEdit , setAllowEdit] = useState(true)
    const patientIdRef = useRef()
    const [showBackToTop , setShowBackToTop] = useState(false)

    async function getPatinetInfo(){
        try {
            const response = await api.post('/main/get-patient', {
                user_id: appContext.userData.id,
                patient_id: ac.currentPatient,
            })

            if(response.data.status){
                setPatient(response.data.patient[0])
                setPatientDiseases(response.data.diseases)
                setPatientExamines(response.data.examines)
                
                ac.setLateset(response.data.examines)
                ac.setCurrentPatientDiseases(response.data.diseases)
                console.log(response.data.diseases);
                
                const lastExamine = utils.calculateLastVisit(response.data.examines)
                if(lastExamine.status){
                    setLatestExamine(utils.fromatDate(lastExamine.lastExam))
                } else {
                    setLatestExamine(lastExamine.lastExam)
                }
                
                
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getPatinetInfo()
    }, [mainmm])

    useEffect(() => {
        const element_card = document.getElementById(`${mainmm.examineAnchor}_card`)
        if(element_card && mainmm.examineAnchor == null){
            setTimeout(() => element_card.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }), 1000)
        } 
    },[patientExamines])

    function handlePatientView(status){
        if(!status){
            modalInterface.createModal("התראה" , "משתמש אחר צופה בכרטיס , לא ניתן לערוך כרטיס זה!" , [{text: "אוקיי" , onclick: () => modalInterface.closeModal()}])
            setAllowEdit(false)
        }
    }

    useEffect(() => {
        SocketService.onPatientViewResult(handlePatientView)
    },[])

    useEffect(() => {
        SocketService.onPatientEnter(ac.currentPatient , appContext.userData.id)
        patientIdRef.current = ac.currentPatient

        return () => {
            SocketService.onPatientExit(patientIdRef.current , appContext.userData.id)
        }
    },[])

    // Function to scroll to the top
    function scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    
    // Show the button when the user scrolls down 20px from the top of the document
    window.onscroll = function() {showScrollButton()};
    
    function showScrollButton() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        setShowBackToTop(true)
        } else {
        setShowBackToTop(false)
        }
    }

    useEffect(() => {
        
    },[patientExamines])

  return (
    <div className='examines-container'  style={{position: 'relative' , height: '100%'}} >
        <LoadingSpinner visible={!patientExamines}/>
            <>
            
            {patientExamines ? 
                <>
                    
                    <HeaderCard patient={patient} patientDiseases={patientDiseases} latestExamine={latestExamine}/>
                    <RefractionCard examines={patientExamines.refraction} fillExamine={patientExamines.glasses} patient={patient} getPatinetInfo={() => getPatinetInfo()} allowEdit={allowEdit} /> 
                    <GlassesCard examines={patientExamines.glasses} fillExamine={patientExamines.refraction} patient={patient} getPatinetInfo={() => getPatinetInfo()} allowEdit={allowEdit}/>
                    <LensesCard examines={patientExamines.lenses} patient={patient} getPatinetInfo={() => getPatinetInfo()} allowEdit={allowEdit} /> 
                    <UploadCard patient={patient} allowEdit={allowEdit} />
                </>
                : null
            }
            
            </>
            {showBackToTop ? 
            <FontAwesomeIcon icon={faArrowUp} onClick={() => scrollToTop()} className='back-to-top no-print' /> : null}
            
    </div>
  )
}

export default PatinetExamines
