import React , {useEffect , useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { observer } from "mobx-react";
import { modalInterface } from '../../mobx/ModalInterface';

const GenModal = observer(({}) =>{

  const [countdown, setCountdown] = useState();

  useEffect(() => {
    if(modalInterface.autoClose){
      setCountdown(Math.round(modalInterface.autoClose / 1000));
      const timer = modalInterface.autoClose > 0 && setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = Math.round(prevCountdown - 1);
          if (newCountdown <= 0) {
            clearInterval(timer);
            modalInterface.closeModal();
            return 0;
          }
          return newCountdown;
        });
      }, 1000);
  
      return () => clearInterval(timer);
    }
    
  }, [modalInterface.autoClose]);

  return (
    <Modal
      show={modalInterface.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='no-print'
    >
      <Modal.Header dir='rtl'>
        <Button style={{position: 'absolute', left: 15}} onClick={() => modalInterface.closeModal()}>X</Button>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalInterface.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalInterface.children ? modalInterface.children :
        <div>
          <h4>{modalInterface.sub_title}</h4>
        <p>
          {modalInterface.text}
        </p>
        </div>
        }
      </Modal.Body>
      <Modal.Footer>
        {modalInterface.buttons ? modalInterface.buttons.map(button => {
          return (
            <Button key={`modal_key_${button.text}`} autoFocus={button.focus ? true : false} style={{backgroundColor: button?.color, borderColor: button.focus ? 'black' : button?.color , borderWidth: button.focus ? '2px' : '1px' } } onClick={button.onclick}>{button.text}</Button>
          )
        }): null}
        {modalInterface.autoClose ? <Button onClick={() => modalInterface.closeModal()}>סגור {countdown}</Button> : null}
      </Modal.Footer>
    </Modal>

  )
})


export default GenModal