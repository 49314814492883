import React, { useState , useContext , useEffect , useRef} from 'react'
import './RefractionCard.css'
import GenTable from '../../tables/table/GenTable'
import tableInfo from '../../../utils/tables_info/TablesInfo'
import GenButton from '../../buttons/GenButton'
import CardTitle from '../card_title/CardTitle'
import api from '../../api/AxiosConfig'
import { AppContext } from '../../../context/AppContext'
import { ac } from '../../../mobx/AppContoller'
import { observer } from "mobx-react";
import { Form } from 'react-bootstrap'
import utils from '../../../utils/utils'
import LoadingSpinner from '../../loading_spinner/LoadingSpinner'
import { modalInterface } from '../../../mobx/ModalInterface'
import { useMediaQuery } from 'react-responsive';
import { mainmm } from '../../../mobx/MainMenuManager'
import { Typeahead } from 'react-bootstrap-typeahead'
import { fsm } from '../../../mobx/FormSuggestionManager'
import { em } from '../../../mobx/ExamineManager'
import CheckerNameInput from '../../inputs/checker_name/CheckerNameInput'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PrintInfo from '../../print_info/PrintInfo'
import TextEditor from '../../text_editor/TextEditor'



const RefractionCard = observer(({examines , fillExamine , getPatinetInfo , allowEdit , patient}) => {
    const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` });
    const {appContext} = useContext(AppContext)
    const [minimize , setMinimize] = useState(true)
    const [saveColor , setSaveColor] = useState('#005371')
    const [tableData , setTableData] = useState({})
    const [note , setNote] = useState()
    const [initalValues , setInitialValues] = useState({})
    const [overlay, setOverlay] = useState(false)
    const [checkerName , setCheckerName] = useState("")
    const [allowPrint , setAllowPrint] = useState(true)
    const oldRxTableRef = useRef();
    const objTableRef = useRef();
    const subjTableRef = useRef();

    async function save(print = false){
        modalInterface.closeModal()
        if(utils.isEmptyObject(tableData)){
            if(print){
                mainmm.setAnchor("refraction")
                window.print()
                return
            }
             
        }

        if(ac.currentRefractionExamine != 0){
            modalInterface.createModal("התראה" , "האם אתה בטוח שאתה רוצה לעדכן בדיקה קיימת?" , [{
                text: "כן",
                focus: true,
                onclick: () => update(print)
            },{
                text: "לא",
                onclick: () => modalInterface.closeModal()}])
            return
        }
        console.log(tableData);
        
        try {
             const response = await api.post('/main/create-new-refraction-examine-card', {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                username: appContext.userData.username,
                type: "refraction",
                note: note,
                tableData: JSON.stringify(tableData),
                checker_name: checkerName
             })
             em.setLastSavedExamine("refraction" , checkerName)
             setTableData({})
             em.setEditExamine(false)
             getPatinetInfo()
             resetTables()
             if(print){
                window.print()  
             }

        } catch (e){
            console.log(e);
        }
       
    }

    async function update(print){
        try {
            const response = await api.post('/main/update-exsiting-refraction-card', {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                username: appContext.userData.username,
                type: "refraction",
                note: note,
                tableData: JSON.stringify(tableData),
                checker_name: checkerName,
                examine: examines[ac.currentRefractionExamine - 1],
             })
             modalInterface.closeModal()
             if(print){
                setTimeout(() => window.print(), 100)
             }
             
            setTimeout(() => {
                setTableData({})
                em.setEditExamine(false)
                getPatinetInfo()
                resetTables()
            },[print ? 1000 : 0])
            
             
        } catch (e) {
            console.log(e);
        }
    }

    function resetTables(){
        if (oldRxTableRef.current) {
            oldRxTableRef.current.onSave();
          }
          if (objTableRef.current) {
            objTableRef.current.onSave();
          }
          if (subjTableRef.current) {
            subjTableRef.current.onSave();
          }
    }

    async function load(id){
        setInitialValues({})
        setCheckerName("")
        setNote("")
        if(!examines || ac.currentRefractionExamine == 0 || !examines[ac.currentRefractionExamine - 1]){
            return
        }
        try {
            setOverlay(true)
            const response = await api.post('/main/load-patinet-examine',{
                user_id: appContext.userData.id,
                patient_id: ac.currentPatient,
                type: 'refraction',
                examine_id: id,
            })
            const examineCard = response.data.examineCard[0]
            setNote(examineCard.note)
            setCheckerName(examineCard.manual_checker_name)
            const fixedValues = utils.fixTableInputFormat(response.data.examine)
            setInitialValues(fixedValues)
            setOverlay(false)

        } catch (e) {
            console.log(e);
        }
    }

    //function to autofill from last glasses examine
    async function handleAutoFill(){
        setTableData({})
        setCheckerName("")
        setNote("")
        if(!fillExamine || fillExamine.length == 0 || ac.currentRefractionExamine > 0){

            return
        }
        try {
            setOverlay(true)
            const response = await api.post('/main/load-patinet-examine',{
                user_id: appContext.userData.id,
                patient_id: ac.currentPatient,
                type: 'glasses',
                examine_id: fillExamine[fillExamine.length - 1].id
            })
            if(response.data.examine['distance']){
                const distanceFill = utils.changeFillType(response.data.examine['distance'], 'old_rx')
                const fixedValues = utils.fixTableInputFormat(distanceFill)
                setInitialValues({"old_rx": fixedValues})
                setTableData(prev => ({
                    ...prev,
                    ['old_rx']: distanceFill
                }))
            }
            
            setOverlay(false)
        } catch (e) {
            console.log(e);
        }
    }

    async function handlePrint(){
        mainmm.setAnchor("refraction")
        save(true)
    }
    

    useEffect(() => {
        load(examines[ac.currentRefractionExamine - 1]?.id) 
    },[ac.currentRefractionExamine , examines])

    useEffect(() => {
        handleAutoFill()
    },[fillExamine , ac.currentRefractionExamine])

    useEffect(() => {
        if(mainmm.examineAnchor == "refraction"){
            setMinimize(false)
        } else {
            setMinimize(true)
        }
    },[mainmm.examineAnchor])

    useEffect(() => {
    
        const handleKeyDown = (event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 's' && em.currentExamine == "refraction") {
            modalInterface.createModal("התראה" , `האם אתה בטוח שברצונך לשמור בדיקת רפרקציה?` ,[{text: "כן" , onclick: () => save() }, {text: "לא" , onclick: modalInterface.closeModal()}])
            
          }
        };
      
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [tableData]);
    
   

    
  return (
    <div  id="refraction_card" className={`row ${mainmm.examineAnchor == "refraction" ? "print-friendly" : "no-print" } refraction_container container-fluid ${!allowEdit ? 'disabled-div' : null}`}   style={{position: 'relative'}}>
        <LoadingSpinner visible={overlay} />
        <div className='col-xs-12 justify-content-evenly' >
        
            <section className='col-xs-12 col-lg-12'>
                    
                    <div className='col-xs-12 col-lg-12 row print-friendly' >
                        <div className='card_header_section no-print'>
                            <CardTitle current_examine={ac.currentRefractionExamine} examines={examines} title={"רפרקציה"} handlePrint={handlePrint} setMinimize={setMinimize} minimize={minimize} saveColor={saveColor} onSaveClick={() => save()} type={"refraction"} />
                        </div>
                        <div>
                            <PrintInfo patient={patient} current_examine={ac.currentRefractionExamine} examines={examines}/>
                        </div>
                   
                        <div className={!minimize ? "open-examine-card" : "close-examine-card"}>
                            <div className='col-lg-12 col-xs-12 d-flex justify-content-center mt-2'>
                                <CheckerNameInput checkerName={checkerName} setCheckerName={setCheckerName} />
                                {/* <Form.Control placeholder='שם הבודק' className='checker-name' value={checkerName} onChange={(e) => setCheckerName(e.target.value)} /> */}
                            </div>
                            <div  className=" row flex-wrap">
                                <div id='old_rx_print' className='col-lg-12 col-xs-10'>
                                    <div style={{textAlign: 'left'}}>
                                        Old Rx
                                    </div>
                                    <GenTable ref={oldRxTableRef} tableFor={"old_rx"} examine={"refraction"} setTableData={setTableData} setSaveColor={setSaveColor} headers={tableInfo.refraction.tHeaders.oldRxHeader} columns={tableInfo.refraction.tColumns.oldRxColumns} initialValues={initalValues.old_rx ? initalValues.old_rx : tableInfo.refraction.oldRxInitialValues}/>
                                </div>
                                <div id='obj_print' className='col-lg-12 col-xs-10'>
                                    <div style={{textAlign: 'left'}}>
                                        Objective
                                    </div>
                                    <div className='refraction-note-responsive'>
                                        
                                        <div className='col-lg-6 col-xs-5' style={isMobile ? {display: 'none'} : null}> 
                                            <TextEditor  theme='snow' value={note} setValue={setNote}  placeholder='הערות'/>
                                            {/* <Form.Control value={note} onChange={(e) => setNote(e.target.value)} as='textarea' rows={3} placeholder='הערות' className='ref-note'></Form.Control> */}
                                        </div>
                                        
                                        
                                        <div className='col-lg-6 col-xs-5'>
                                            <GenTable ref={objTableRef}  tableFor={"objective"} examine={"refraction"} setSaveColor={setSaveColor} setTableData={setTableData}  headers={tableInfo.refraction.tHeaders.objHeader} columns={tableInfo.refraction.tColumns.objColumns} initialValues={initalValues.objective ? initalValues.objective : tableInfo.refraction.objInitialValues}/>
                                        </div>
                                    </div>
                                </div>
                                <div id='sub_print' className='col-lg-12 col-xs-10'>
                                    <div style={{textAlign: 'left'}}>
                                        Subjective
                                    </div>
                                    <GenTable ref={subjTableRef}  tableFor={"subjective"} examine={"refraction"} setSaveColor={setSaveColor} setTableData={setTableData}  headers={tableInfo.refraction.tHeaders.subjHeader} columns={tableInfo.refraction.tColumns.subjColumns} initialValues={initalValues.subjective ? initalValues.subjective : tableInfo.refraction.subjInitialValues}/>
                                </div>
                                
                                <div className='col-lg-6 col-xs-5' style={!isMobile ? {display: 'none'} : null}> 
                                    <Form.Control value={note} onChange={(e) => setNote(e.target.value)} as='textarea' rows={3} placeholder='הערות' className='ref-note'></Form.Control>
                                </div>
                                
                                <div className='no-print'>
                                    <GenButton width={'40%'} text={ac.currentRefractionExamine == 0 ? "שמירה כרטיס רפרקציה" : "עדכן כרטיס רפרקציה"} onClick={() => save()} backgroundColor={saveColor} textColor={'#fff'}></GenButton>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            </section>
        </div>
    </div>
  )
})

export default RefractionCard