import axios from "axios";

const host = `${window.location.hostname}`
let url = ''

if(host.includes("localhost")){
    url = `${window.location.protocol}//${host}:3100`
} else {
    url = `${window.location.protocol}//server.${host}`
}


const axiosInstance = axios.create({
    baseURL: url,
    headers: { "ngrok-skip-browser-warning": "true" },
    withCredentials: true,
  });
  
//   axiosInstance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       if (error.response && error.response.status === 401) {
//         // Handle 401 Unauthorized error here
//         // For example, you can redirect to a login page or show an error message
//       }
//       return Promise.reject(error);
//     }
//   );
  
  export default axiosInstance;