import React, { useEffect, useState } from 'react'
import './UploadCard.css'
import { Button } from 'react-bootstrap'
import PdfUpload from '../../upload/PdfUpload'
import api from '../../api/AxiosConfig'
import utils from '../../../utils/utils'
import GenButton from '../../buttons/GenButton'
import CardTitle from '../card_title/CardTitle'
import { modalInterface } from '../../../mobx/ModalInterface';
import UploadPreview from '../../upload/UploadPreview/UploadPreview'
import LoadingSpinner from '../../loading_spinner/LoadingSpinner'
import { mainmm } from '../../../mobx/MainMenuManager'
import { observer } from "mobx-react";

function UploadCard({patient , allowEdit}) {
  const [patientFiles , setPatientFiles] = useState([])
  const [minimize , setMinimize] = useState(true)
  const [loading , setLoading] = useState(false)

  function displayModal(){
    modalInterface.createModal("העלאת קבצים" , null  , null , <PdfUpload patient={patient} setPatientFiles={setPatientFiles} patientFiles={patientFiles} />)
  }

  async function getDocuments(){
    try {
      setLoading(true)
      const response = await api.post('/main/get-patient-documents', {
        patient_id: patient.id,
      })
      setPatientFiles(response.data.documents)
      setLoading(false)
    } catch (e) { 
      console.log(e);
    }
  }

  useEffect(() => {
    if(mainmm.examineAnchor == "files"){
        setMinimize(false)
    } else {
        setMinimize(true)
    }
  },[mainmm.examineAnchor])
  

  useEffect(() => {
    getDocuments()
  },[patient])

  return (
    <div id="files_card" className={`row upload_container container-fluid ${!allowEdit ? 'disabled-div' : null}`} style={{position: 'relative'}}>
      <LoadingSpinner visible={loading} />
        <div className='row justify-content-evenly'>
            <section className='upload_section col-12'>
               
                <div className='col-12'>
                  <div>
                    <CardTitle hidePrint={true} title={"קבצי לקוח"} saveColor={'#005371'} buttonText='חדש' minimize={minimize} setMinimize={setMinimize} type={"upload"} displayExamines={false} onSaveClick={() => displayModal()}/>
                  </div>
                    <div className={!minimize ? "open-examine-card" : "close-examine-card"}>
                    <div className='patient-upload-container'>
                      {patientFiles?.length > 0 ? patientFiles.map((file , index) => {   
                        return(
                          <UploadPreview key={`patient_file_${index}`} file={file} setPatientFiles={setPatientFiles}/>
                        )
                      }): <div>אין קבצים</div>}
                    </div>
                    {/* <PdfUpload patient={patient} setPatientFiles={setPatientFiles} patientFiles={patientFiles} /> */}
                    </div>
                    
                </div>
           
            </section>
        </div>
    </div>
  )
}

export default observer(UploadCard)