import React, { useContext, useEffect, useState } from 'react'
import utils from '../../../utils/utils'
import api from '../../api/AxiosConfig'
import url from '../../../context/Url';
import './UploadPreview.css'
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import DropDownSpinner from '../../drop_down_spinner/DropDownSpinner';
import { Form } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';

import { modalInterface } from '../../../mobx/ModalInterface';
import { ac } from '../../../mobx/AppContoller';
import { AppContext } from '../../../context/AppContext';
import EditFileModal from '../../modal/edit_file_modal/EditFileModal';
import Svg from '../../../utils/svg';

import FileNoSupported from '../../../assets/images/preview_not_supported.png'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function UploadPreview({file , setPatientFiles}) {
    const {appContext} = useContext(AppContext)
    const [downloadedFile, setDownloadedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    async function downloadFile() {
      try {
          const response = await api.post('/main/download-file', {
              path: file.path
          }, {
              responseType: 'arraybuffer',
              observe: 'response'
          });

          const contentDisposition = response.headers['content-disposition'];
          const contentType = response.headers['content-type'];
          let filename = "downloaded-file";
  
          if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="?(.*)"?(;|$)/);
              if (fileNameMatch.length === 2) {
                  filename = fileNameMatch[1];
              }
          }
  
          const blob = new Blob([response.data], { type: contentType });
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
  
          window.URL.revokeObjectURL(url);
          link.remove();
  
      } catch (e) {
        console.log(e);
        modalInterface.createModal("התראה" , "הקובץ אינו נמצא", null , null , 10 * 1000)
        return
   
      }
  }

    async function editFile(){
      modalInterface.createModal('עריכת קובץ' , null , null , <EditFileModal file={file} setPatientFiles={setPatientFiles}/>)
    }

    function deleteFile(){
      modalInterface.createModal("מחיקת קובץ" ,
       `האם אתה בטוח ש אתה רוצה למחוק את ${file.name}?` ,
        [{text: 'כן' , onclick: () => handleDeleteFile()} ,
        {text: 'לא' , onclick: () => modalInterface.closeModal(), color: "#808080"}])
    }

    async function handleDeleteFile(){
      try {
        const response = await api.post('/main/delete-patient-file' , {
          file: file,
          patient_id: ac.currentPatient,
          user_id: appContext.userData.id
        })
        if(response.data.status){
          const documents = response.data.documents
          setPatientFiles([...documents])
          modalInterface.closeModal()
        } else {
          //Handle error here
        }
      } catch (e) {
        console.log(e);
      }
    }

    const getFileExtension = (filename) => {
        return filename.split('.').pop();
    };

    const isImageFile = (extension) => {
        return ['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase());
    };

    const isPdfFile = (extension) => {
      return ['pdf'].includes(extension.toLowerCase())
    }

    const isVideoFile = (extension) => {
      return ['mp4' , 'ogg' , 'webm'].includes(extension.toLowerCase())
    }

    const isDocFile = (extension) => {
      return ['doc' , 'docx'].includes(extension.toLowerCase())
    }

  function handleAvatarError(e ){
    e.target.onerror = null;
    e.target.src = FileNoSupported
    
  }

    const renderFilePreview = () => {
      //HERE
        const fileExtension = getFileExtension(file.path);
        if (isImageFile(fileExtension)) {
          return (
            <img onError={(e) => handleAvatarError(e)} src={`${url}/${file.path}`} alt={file.name} style={{ maxWidth: '80%' , height: '100px' }} />
            )
        }
        else if(isVideoFile(fileExtension)){
          return(
            <video onError={(e) => handleAvatarError(e)} src={`${url}/${file.path}`} alt={file.name} style={{ maxWidth: '80%' , height: '100px' }} />
          )
        }
        else if (isPdfFile(fileExtension)) {
          return (
            <Svg onError={(e) => handleAvatarError(e)} style={{ maxWidth: '80%' , height: '100px' }} fileName={"file-pdf"}/>
          )
        }
        else if(isDocFile(fileExtension)) {
          return (
            <Svg onError={(e) => handleAvatarError(e)} style={{ maxWidth: '80%' , height: '100px' }} fileName={"file-doc"}/>
          )
        }
        else {
            
          return (
            <div style={{ minWidth: "80%" , maxWidth: '80%', height: '100px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
              <span style={{ color: '#666' }}>Document</span>
            </div>
          )
        }
    };

  return (
    <div className='col-lg-2 col-xs-12 preview-container' >
    <div className="preview-content">
        <div className='preview-render'>{renderFilePreview()}</div>
        <p className='preview-title'>{file.name}.{getFileExtension(file.path)}</p>
        <p className='preview-note' as='textarea'>{file.note}</p>
        <h6>{utils.fromatDate(file.creation_datetime)}</h6>
    </div>
    <DropDownSpinner 
      options={[
        {title: 'מחיקה' , onClick: () => deleteFile()},
        {title: 'עריכה', onClick: () => editFile()},
        {title: 'הורדה', onClick: () => downloadFile()}
      ]} 
      className={"preview-settings"} />
</div>
  )
}

export default UploadPreview

//onClick={() => downloadFile(file.path)}