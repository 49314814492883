const allowedImageTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/svg+xml'
];

const allowDocumentTypes = [
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
    'text/csv',
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
    'text/plain', 
    'application/rtf', 
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
];

const allowVideoTypes = [
    'video/mp4',
    'video/webm',
    'video/ogg'
];

const upload_utils = {
    validateFileType: function validateFileType(file){
        if(!file.type){
            return false
        }
       
        if(allowedImageTypes.includes(file.type)){
            return true
        }
        else if (allowDocumentTypes.includes(file.type)){
            return true
        }
        else if (allowVideoTypes.includes(file.type)){
            return true
        }
        else {
            return false
        }
    },

    validateImageFile: function validateImageFile(file){
        if(!file.type){
            return false
        }
        if(allowedImageTypes.includes(file.type)){
            return true
        } else {
            return false
        }
    }
}

export default upload_utils