import { forwardRef, useImperativeHandle, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import './GenTable.css'
import { useFormik } from 'formik';
import { faClone, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead } from 'react-bootstrap-typeahead';
import { observer } from 'mobx-react';
import { fsm } from '../../../mobx/FormSuggestionManager';
import { toJS } from "mobx"
import { em } from '../../../mobx/ExamineManager';

const GenTable = forwardRef(function({headers , columns , initialValues , setSaveColor , tableFor , examine , setTableData , hideBalance = false}, ref){
  const [inputStyles, setInputStyles] = useState({});
  const [isBalance , setIsBalance] = useState({
    r: false,
    l: false
  })
  const eyeRef = useRef("")

  const formik = useFormik({
    initialValues,
  })

  function duplicate() {
    const { r } = formik.values;

    const keys = Object.keys(r);
    
    keys.forEach(async key => {
  
      if(key == 'pd'){
        autoFillPD(null , r[key])
      }
      setInputStyles({})
      handleSave(`l.${key}` , r[key])
      await formik.setFieldValue(`l.${key}` , r[key])

    });
  }
  
  const handleClick = ( column , value , eye) => {
    const name = `${eye}.${column.name}`
    handleSave(name , value[0])
    formik.setFieldValue(name , value[0])
   
  }

  function handleChange(column , event){
    let value = event.target.value
    event.target.value = limitCheck(value , column.min , column.max , column.polarity)

    if(column.regex && !column.regex.test(value)){
      event.target.value = String(value).substring(0 , String(value).length - 1)
    }
    em.setEditExamine(true)
    handleSave(event.target.name , event.target.value)
    formik.handleChange(event)
      
  }

  function handleInputChange(column , value , eye){
    formik.setFieldValue(`${eye}.${column.name}`, value)
    handleSave(`${eye}.${column.name}` , value)
  }

  async function handleBalance(eye){
    eyeRef.current = eye;
    setIsBalance(prev => ({
      ...prev,
      [eye]: !prev[eye],
    }))
  }

  function handleBlur(column , eye , event){

    if(!event.target){
      return
    }

    if(isNaN(event.target.value)){
      return
    }
    
    if(column.polarity != "text"){
      event.target.value = formatNumber(event.target.value , column)
    } else {
      return
    }

    const formatName = String(event.target.name).slice(2)
    const twinValue = formik.values[eye == 'r' ? 'l' : 'r'][formatName]
    
    if(column.name == "pd" || column.name == "pd2"){
      if(twinValue != 0 || twinValue != null){
        autoFillPD(column)
      }

    }

    handleCalculateCyl(eye)

    if(!twinValue){
      twinDuplicate(column, eye , event.target.name , event.target.value)
      handleSave(event.target.name , event.target.value)
      formik.handleChange(event)
      setInputStyles(prev => ({
        ...prev,
        [formatName]: null
      }))
      return
    }
    
    twinDiffrence(column, eye , event.target.name , event.target.value)
    handleSave(event.target.name , event.target.value)
    formik.handleChange(event)
  }

  async function handleKeyDown(column , event){
    const value = event.target.value

    if (event.key === "Enter") {
      event.preventDefault();
      // Move to the next tabIndex
      
      const formControls = Array.from(document.querySelectorAll('input, .rbt-input-main'));
      const currentIndex = formControls.findIndex(element => element === event.target);
      const isTypeahead = formControls[currentIndex].classList.contains('rbt-input-main') || formControls[currentIndex].parentElement.classList.contains('rbt');
      let skipIndex = isTypeahead ? 2 : 1
      const nextElement = formControls[currentIndex + skipIndex];

      if (nextElement) {
        nextElement.focus();
      }
      return
    }

    if(isNaN(event.target.value)){
      return
    }

    if(column.polarity === "text"){
      handleSave(event.target.name , value)
      formik.setFieldValue(event.target.name, value)
      return
    }
    let numValue
    if(isNaN(value) || !value){
      numValue = 0
    } else {
      numValue = parseFloat(value)
    }
    const key = event.key
    let newValue

    switch(key){
      case 'ArrowUp':
        newValue = limitCheck(numValue + parseFloat(column.jumps), column.min , column.max)
        break;
      case 'ArrowDown':
        newValue = limitCheck(numValue - parseFloat(column.jumps), column.min , column.max)
        break;
      case 'ArrowLeft': 
      newValue = limitCheck(numValue - parseFloat(column.jumps), column.min , column.max)
        break;
      case 'ArrowRight': 
        newValue = limitCheck(numValue + parseFloat(column.jumps), column.min , column.max)
        break;
      default:
        return
    }
    await formik.setFieldValue(event.target.name, parseFloat(newValue).toFixed(column.decimal))
    handleSave(event.target.name , parseFloat(newValue).toFixed(column.decimal))
  }

  function handleSave(name , value){
    const eye = String(name).substring(0,1)
    name = String(name).substring(2,String(name).length + 1)
    setTableData(prev => ({
      ...prev, 
      [tableFor]: {
        ...prev[tableFor] || {}, 
        [eye]: {
          ...((prev[tableFor] || {})[eye] || {}), 
          [name]: value 
        }
      }
    }));
  }

  //Number fix
  function limitCheck(value , min , max , polarity){
    let newValue = 0
    if(!min && !max){
      return value
    }

    if(value < min){
      newValue = min
      return newValue
    }
    if(value > max){
      if(polarity == 'negative'){
        return -Math.abs(value)
      }
        newValue = max
        return newValue
    }
    if(value == "00"){
      newValue = 0
      return newValue
    }
    
    return value
    
    
  }

  function formatNumber(num , column) {
    if(!num){
      return num
    }
    num = parseFloat(num)
    if (num > 0) {
      if(column.polarity === 'both'){
        if (num % 0.5 === 0) {
          return "+" + num.toFixed(column.decimal);
        } else {
          return "+" + num;
        }
      } else {
        return num.toFixed(column.decimal)
      }
      
    }
    else if (num < 0) {
      if (num % 0.5 === 0) {
        return num.toFixed(column.decimal);
      } else {
        return num.toString();
      }
    }
    else {
      if (num % 1 === 0) {
        return num.toFixed(column.decimal);
      } else {
        return num.toString();
      }
    }
  }

  function handleCalculateCyl(eye){
    if(tableFor != "cornea"){
      return
    }

    const rh = parseFloat(formik.values[eye]['rh'])

    const rv = parseFloat(formik.values[eye]['rv'])
   
    if(!rh || !rv){
      return
    }

    const avr = (rh + rv) / 2
 
    const cyl = cylFormula(rh, rv) 

    formik.setFieldValue(`${eye}.avr`, avr.toFixed(2))
    handleSave(`${eye}.avr` , avr.toFixed(2))
    formik.setFieldValue(`${eye}.cyl`, cyl)
    handleSave(`${eye}.cyl` , cyl)

  }

  function cylFormula(h , v){
    const c = -337.6
    const d = Math.pow(10, -10)

    let cyl = (c / (h + d)) / (v - d) * Math.abs(h - v);

    return cyl.toFixed(2);
  }
  
  //Auto fills

  function autoFillPD(column , dup_value){
    if(!column){
      const override_combined = parseFloat(dup_value * 2).toFixed(2)
      formik.setFieldValue("r.pd2" , override_combined)
      formik.setFieldValue("l.pd2" , override_combined)
      handleSave("r_pd2" , override_combined)
      handleSave("l_pd2" , override_combined)

    } else {
      const pd2 = parseFloat(formik.values.r.pd2)
      const pd_l = parseFloat(formik.values.l.pd)
      const pd_r = parseFloat(formik.values.r.pd)

      if(pd2 > 0 && column.name == 'pd2'){
        let temp_pd_l = pd2 / 2
        let temp_pd_r = pd2 / 2

        formik.setFieldValue("r.pd" , formatNumber(temp_pd_l, column))
        formik.setFieldValue("l.pd" , formatNumber(temp_pd_r, column))
        handleSave("r_pd" , formatNumber(temp_pd_l , column))
        handleSave("l_pd" , formatNumber(temp_pd_r , column))

      } else {
        const combined_pd_expected_value = pd_l + pd_r
        if(isNaN(combined_pd_expected_value)){
          return
        }

        formik.setFieldValue("r.pd2" , formatNumber(combined_pd_expected_value, column))
        formik.setFieldValue("l.pd2" , formatNumber(combined_pd_expected_value, column))
        handleSave("r_pd2" , formatNumber(combined_pd_expected_value , column))
        handleSave("l_pd2" , formatNumber(combined_pd_expected_value , column))
      }
    
      
      
    }
    
  }

  //Twin related

  function twinDiffrence(column , eye , name , value){
    if(column.diffAlert == 0){
      return
    }
    const formatName = String(name).slice(2,5)
    const twinValue = formik.values[eye == 'r' ? 'l' : 'r'][formatName]
    if(!value){
      setInputStyles(prev => ({
        ...prev,
        [formatName]: null
      }))
      return
    }

    const diff = Math.abs(value - twinValue)

    if(isNaN(diff)){
      return
    }

    if((value == "" || !value) || !twinValue){
      return
    }
    
    if(diff >= column.diffAlert){
      setInputStyles(prev => ({
        ...prev,
        [formatName]: 'highlight'
      }))
    } 

    else if (diff <= column.diffAlert){
      setInputStyles(prev => ({
        ...prev,
        [formatName]: null
      }))
    } 
    
    else if (diff == 0) {
      setInputStyles(prev => ({
        ...prev,
        [formatName]: null
      }))
    }
  }

  function twinDuplicate(column , eye , name , value){
    if(!column.duplicate){
      return
    }
    const formatName = String(name).slice(2)
    const twinValue = formik.values[eye == 'r' ? 'l' : 'r'][formatName]

    if(!twinValue){
      const twinName = eye == "r" ? 'l.' + formatName : 'r.' + formatName
      handleSave(twinName , value)
      formik.setFieldValue(twinName , value)
    }
  }

  //Use Effect functions

  function isSaveRequired(){
    em.setCurrentExamine(examine)
    if(formik.dirty){
      
      setSaveColor('#008000')
    } else {
      em.setEditExamine(false)
      setSaveColor('#005371')
    }
  }
  
  function loadValues(){
    setInputStyles({})
    setIsBalance(prev => ({
      ...prev,
      r: initialValues.r?.b || false,
      l: initialValues.l?.b || false
    }))
    formik.setValues(initialValues)
  }

  function onSave(){
    formik.handleReset()
  }

  
  function handleOption(option){
    if (typeof option === 'object' && option.label) {
        return typeof option.label === 'string' ? option.label.toLowerCase() : option.label.toString();
    }
    // If the option is a string, return it in lowercase
    else if (typeof option === 'string') {
        return option.toLowerCase();
    }
    // If the option is a number, convert it to a string
    else if (typeof option === 'number') {
        return option.toString();
    }
    // Default case to handle other unexpected types
    else {
        return '';
    }
}

  useImperativeHandle(ref, () => ({
    onSave
  }))

  useEffect(() => {
    if (isBalance && eyeRef.current) {
      handleSave(`${eyeRef.current}.balance`, isBalance[eyeRef.current]);
    }
  }, [isBalance]);

  useEffect(() => {
    isSaveRequired()
  
  },[formik.values])

  
  useEffect(() => {
    loadValues()
  },[initialValues])
    

  return (
    <div className='d-flex flex-row-reverse table-print'>
      <Table style={{overflow: 'hidden'}} className='col-11 ' responsive dir='ltr'>
        <thead>   
        <tr>
          <th >{hideBalance ? "" : "B"}</th>
          <th></th>
          {Object.keys(headers).map((key, index, array) => (
            <th  key={`title_th_${tableFor}_${key}_${index}`} colSpan={headers[key].values.length > 0 ? headers[key].values.length : headers[key].merge} rowSpan={headers[key].merge} className={headers[key].title.includes("VA") || headers[key].title.includes("PD") ? "section-end double-row-center": "section-end"}>
              {headers[key].title}
            </th>
          ))}
        </tr>
        <tr>
        <th></th>
        <th></th>
          {Object.keys(headers).flatMap((key, sectionIndex, array) =>
            headers[key].values.map((value, index) => (
              <th key={`th_${tableFor}_${key}_${index}`}  className={"section-end"}>
                {value}
              </th>
            ))
          )}
        </tr>
        </thead>
        <tbody>
          <tr className={isBalance.r ? "balance_row" : null}>
            <td ><input type='checkbox' onKeyDown={(event) => handleKeyDown(null , event)} checked={isBalance.r} onChange={() => handleBalance("r", isBalance.r)} style={hideBalance ? {display: 'none'} : null}></input></td>
            <td className='eye-indicator'>R</td>
            {Object.keys(columns).map((c , index) => {
            
              return (
                  <td key={`td_r_${tableFor}_${index}`} rowSpan={columns[c].merge ? 2 : 1} className={columns[c].name.includes('2') ? "double-row-center" : null}>
                    <div className={columns[c].name == "va" || columns[c].name == 'va2'|| columns[c].name == "sc" ? 'va-handler' : null}>
                      {columns[c].name == "va" || columns[c].name == "va2" || columns[c].name == "sc" ? <span className='va-prefix' style={{display: 'inline'}} >6/</span> : null}
                        {columns[c].isEdit ? 
                        <Form.Control 
                          key={`key_index_${columns[c].name}_${tableFor}`}
                          className={`table_input ${inputStyles[columns[c].name]} ${columns[c]?.className ? columns[c].className : '' } ${isBalance.r && !columns[c].name.includes('2') ? "balance_row" : ""}`}
                          onBlur={handleBlur.bind(this, columns[c], "r")} 
                          name={"r."+columns[c].name} 
                          value={formik.values.r[columns[c].name]} 
                          onKeyDown={handleKeyDown.bind(this, columns[c])} 
                          onChange={handleChange.bind(this, columns[c])} 
                          title={formik.values.r[columns[c].name]}
                        /> : null}
                      </div>
                      {columns[c].isDropdown ?
                      <Typeahead
                        key={`key_index_${columns[c].name}_${tableFor}`}
                        id="base"
                        positionFixed={true}
                        className={`table_input ${inputStyles[columns[c].name]} ${columns[c]?.className ? columns[c].className : '' } ${isBalance.r ? "typeahead" : ""}`}
                        onChange={(selected) => handleClick(columns[c], selected , "r")}
                        onInputChange={(value) => handleInputChange(columns[c] , value , 'r')}
                        selected={formik.values.r[columns[c].name] ? [formik.values.r[columns[c].name]] : []}
                        value={formik.values.r[columns[c].name]}
                        options={columns[c].overrideDropdown ? fsm.lensesExamineSuggestion[columns[c].name] : columns[c].isDropdown || []}
                        labelKey={(option) => handleOption(option)}
                        inputProps={{
                          title: formik.values.r[columns[c].name] ? formik.values.r[columns[c].name].toString() : '',
                        }}
                        onKeyDown={(event) => handleKeyDown(columns[c] , event)}
                      />
                       : null}
                  </td>
              )
            })}
          </tr>
          <tr className={isBalance.l ? "balance_row" : null}>
            <td ><input type='checkbox'  onKeyDown={(event) => handleKeyDown(null , event)} checked={isBalance.l} onChange={() =>handleBalance("l", isBalance.l)} style={hideBalance ? {display: 'none'} : null}></input></td>
            <td className='eye-indicator'>L</td>
            {Object.keys(columns).map((c , index) => {
              return (
                <>
                  {!columns[c].merge ? 
                    <td key={`td_l_${tableFor}_${index}`}  >
                      <div className={columns[c].name == "va" || columns[c].name == 'va2' || columns[c].name == "sc" ? 'va-handler' : null}>
                        {columns[c].name == "va" || columns[c].name == "sc" ? <span className='va-prefix' style={{display: 'inline'}} >6/</span> : null}
                        {columns[c].isEdit? 
                        <Form.Control 
                          key={`key_index_${columns[c].name}_${tableFor}`}
                          className={`table_input ${inputStyles[columns[c].name]} ${columns[c]?.className ? columns[c].className : '' } ${isBalance.l && !columns[c].name.includes('2') ? "balance_row" : ""}`}
                          onBlur={handleBlur.bind(this, columns[c], "l")} 
                          disabled={isBalance.l} name={"l."+columns[c].name} 
                          value={formik.values.l[columns[c].name]} 
                          onKeyDown={handleKeyDown.bind(this, columns[c])} 
                          onChange={handleChange.bind(this, columns[c])} 
                          title={formik.values.l[columns[c].name]}
                        /> : null}
                      </div>
                      {columns[c].isDropdown  ? 
                      <Typeahead
                        id="base"
                        className={`table_input ${inputStyles[columns[c].name]} ${columns[c]?.className ? columns[c].className : '' } ${isBalance.l  ? "typeahead" : ""}`}
                        key={`key_index_${columns[c].name}_${tableFor}`}
                        positionFixed={true}
                        onChange={(selected) => handleClick(columns[c], selected , "l")}
                        onInputChange={(value) => handleInputChange(columns[c] , value , 'l')}
                        options={columns[c].overrideDropdown ? fsm.lensesExamineSuggestion[columns[c].name] : columns[c].isDropdown || []}
                        selected={formik.values.l[columns[c].name] ? [formik.values.l[columns[c].name]] : []}
                        value={formik.values.l[columns[c].name]}
                        labelKey={(option) => handleOption(option)}
                        
                        inputProps={{
                          title: formik.values.l[columns[c].name] ? formik.values.l[columns[c].name].toString() : '',
                        }}
                        onKeyDown={(event) => handleKeyDown(columns[c] , event)}
                      />
                   
                     : null}
                    </td>
                    : null}
                </>
              )
            })}
          </tr>
          
        </tbody>
        
      </Table>
      <div className='clone-button no-print'>
        <FontAwesomeIcon icon={faClone} alt="copy" style={{cursor: 'pointer'}} onClick={() => duplicate()}/>
      </div>
     
    </div>
  );
})

export default GenTable;