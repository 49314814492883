import { observable, action, makeObservable} from "mobx";

class ExamineManager {
    currentExamine = null
    lastSavedExamine = null
    lastCheckerName = null
    editingExamine = false


    constructor(){
        makeObservable(this, {
            currentExamine: observable,
            lastSavedExamine: observable,
            lastCheckerName: observable,
            editingExamine: observable,
            setCurrentExamine: action,
            setLastSavedExamine: action,
            setLastCheckerName: action,
            resetManager: action,
            setEditExamine: action,
          });
    }

    setCurrentExamine(type){
        this.currentExamine = type
    }

    setLastSavedExamine(type , name){
        this.lastSavedExamine = type
        if(name){
            this.lastCheckerName = name
        }
    }

    setLastCheckerName(name){
        this.lastCheckerName = name
    }

    resetManager(){
        this.currentExamine = null
        this.lastSavedExamine = null
        this.lastCheckerName = null
    }

    setEditExamine(condition){
        this.editingExamine = condition
    }



}

export const em = new ExamineManager();