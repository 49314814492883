import React, { useState , useEffect, useContext} from 'react'
import './HeaderCard.css'
import { faEye , faWarning, faMagnifyingGlass, faFile, faUser,  } from '@fortawesome/free-solid-svg-icons';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import url from '../../../context/Url.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../api/AxiosConfig.js'
import { modalInterface } from '../../../mobx/ModalInterface.js';
import utils from '../../../utils/utils.js';
import DiseasesModal from '../../modal/diseases_modal/DiseasesModal.js';
import { AppContext } from '../../../context/AppContext.js';
import Svg from '../../../utils/svg.js';

import AvatarPlaceHolder from '../../../assets/svgs/avatar-place-holder.svg'
import MalePlaceHolder from '../../../assets/svgs/male-place-holder.svg'
import FemalePlaceHolder from '../../../assets/svgs/female-place-holder.svg'
import { ac } from '../../../mobx/AppContoller.js';
import { mainmm } from '../../../mobx/MainMenuManager.js';

function HeaderCard({patient , patientDiseases , latestExamine}) {
    const {appContext} = useContext(AppContext)

    function toggleDisesesModal(){
        modalInterface.createModal("אבחנות רפאויות", null , null , <DiseasesModal patientDiseases={patientDiseases} />)
    }

    function disesesText(){
        return (
            patientDiseases.length > 0 ?
                <label>ישנן אבחנות רפואיות</label>:
                <label>אין אבחנות רפואיות</label>
            )
    }

    const handleWhatsAppClick = ()=>{
        const defaultMessage = encodeURIComponent(`שלום זה ${appContext.userData.first_name} מלויה אופטימטריה,`)
        const patient_phone =  utils.validatePhone(patient?.phone)
        if(!patient_phone){
          modalInterface.createModal("שגיאה",  "לא ניתן לשלוח הודעה לטלפון זה" , [{text: "אוקיי", focus: true,onclick: () => modalInterface.closeModal()}])
          return
        }
        window.open(`whatsapp://send?phone=${patient_phone}&text=${defaultMessage}`)

        // window.open(`https://wa.me/send?phone=${patient_phone}&text=${defaultMessage}`)
        return;
    }

    const handleMenuItemClick = (itemName) => {
        mainmm.setAnchor(itemName)
        const element_card = document.getElementById(`${itemName}_card`)
        if(element_card) element_card.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    
    };

    function handleAvatarError(e , gender){
        e.target.onerror = null;
        if (gender == "זכר") {
             e.target.src = MalePlaceHolder
        } else if (gender == "נקבה") {
            e.target.src = FemalePlaceHolder
        } else {
            e.target.src = AvatarPlaceHolder
        }
        
    }

    function handleAvatar(image_path, gender){
        if(image_path){
            return (
                <img onError={(e) => handleAvatarError(e , gender)} style={{width: '70px' , height: '70px', borderRadius: "50%"}} className='client_table_icon' src={`${url}/${image_path}`}></img>
            )
        } else {
            if(gender == "זכר"){
                return (
                    <Svg  style={{width: '70px' , height: '70px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-male'}/>
                )
            }
            else if(gender == "נקבה"){
                return (
                    <Svg  style={{width: '70px' , height: '70px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-female'}/>
                )
            } else {
                return (
                    <Svg  style={{width: '70px' , height: '70px', borderRadius: "50%"}} className={'client_table_icon'} fileName={'avatar-general'}/>
                )
            }
        }
    }
      
    useEffect(() =>{

    },[patient])

  return (
    <div className='container-fluid header_father no-print'>
        <div className='justify-content-evenly '>
            <section className='header_section '>
                <div className='row patient_headr d-flex col-sm-12'>
                    <div className='col-lg-1 patient_header_avatar'>
                        {handleAvatar(patient.image_path , patient.gender)}
                    </div>
                    <div className='col-lg-2 col-xs-3'>
                        <div className='header_field_row header_field_column'>
                            <label style={{fontWeight: "bold"}}>{patient?.first_name} {patient?.last_name} </label>
                            <div>
                                <label>גיל:</label>
                                {utils.calcualteAge(patient?.birthday)}
                            </div>
 
                        </div>
                    </div>
                    <div className='col-lg-2  col-xs-3 header_field_column'>
                        <div className='header_field_row'>
                            <label>קופ"ח: </label>
                            <div >{patient?.medical_institute}</div>
                        </div>
                        <div className='header_field_row'>
                            <label>ביטוח: </label>
                            <div>{patient?.insurence_type}</div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-xs-3 header_field_column'>
                        <div className='header_field_row'>
                            <div >{patient?.phone}</div>
                        </div>
                        <div className='header_field_row'>
                            <div>{patient?.secondary_phone}</div>
                        </div>
                    </div>
                    <div  className='col-lg-2 col-xs-3 header_field_column'>
                        <div onClick={() => toggleDisesesModal()} className={patientDiseases.length > 0 ?'header_field_row medical_condition' : "header_field_row no_medical_condition"}>
                            {patientDiseases.length > 0 ? <FontAwesomeIcon style={{cursor: "pointer"}} enableBackground={ "#e57373"} size={'sm'} icon={faWarning} /> : null} 
                            {disesesText()}
                        </div>
                        <div className='header_field_row'>
                            <label>ביקור אחרון:</label>
                            <div>{latestExamine}</div>                        
                        </div>
                    </div>
                    <div className='header_icons col-lg-3 col-xs-3'>
                        <div data-name="refraction" className="header_card_icons" style={{cursor: "pointer", backgroundColor: "#98FB98"}} onClick={() => handleMenuItemClick("refraction")}>
                            <FontAwesomeIcon data-name="refraction" style={{cursor: "pointer", margin:" 0 auto"}}  size={'xl'} icon={faMagnifyingGlass} onClick={() => handleMenuItemClick("refraction")} />
                        </div>
                        <div data-name="glasses" className="header_card_icons" style={{cursor: "pointer", backgroundColor: "lightblue"}} onClick={() => handleMenuItemClick("glasses")}>
                            <FontAwesomeIcon data-name="glasses" style={{cursor: "pointer", margin: "0 auto"}}  size={'xl'} icon={faEye} onClick={() => handleMenuItemClick("glasses")} />
                        </div>
                        <div data-name="lenses" className="header_card_icons" style={{cursor: "pointer", backgroundColor: "lightpink"}} onClick={() => handleMenuItemClick("lenses")}>
                            <FontAwesomeIcon data-name="lenses" style={{cursor: "pointer", margin:" 0 auto"}}  size={'xl'} icon={faCircleDot} onClick={() => handleMenuItemClick("lenses")} />
                        </div>
                        <div data-name="files" className="header_card_icons" style={{cursor: "pointer", backgroundColor: "#F0E68C"}} onClick={() => handleMenuItemClick("files")}>
                            <FontAwesomeIcon data-name="files" style={{cursor: "pointer", margin:" 0 auto"}}  size={'xl'} icon={faFile} onClick={() => handleMenuItemClick("files")} />
                        </div>
                        <div className="header_card_icons" style={{cursor: "pointer", backgroundColor: "green"}} onClick={handleWhatsAppClick}>
                            <FontAwesomeIcon style={{cursor: "pointer", margin:" 0 auto"}}  size={'xl'} icon={faWhatsapp} color='white' />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default HeaderCard
