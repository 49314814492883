import React from 'react'
import './CheckerNameInput.css'
import { fsm } from '../../../mobx/FormSuggestionManager'
import { Typeahead } from 'react-bootstrap-typeahead'

function CheckerNameInput({checkerName , setCheckerName}) {
    
    function handleOption(option){
        if (typeof option === 'object' && option.label) {
            return typeof option.label === 'string' ? option.label.toLowerCase() : option.label.toString();
        }
        // If the option is a string, return it in lowercase
        else if (typeof option === 'string') {
            return option.toLowerCase();
        }
        // If the option is a number, convert it to a string
        else if (typeof option === 'number') {
            return option.toString();
        }
        // Default case to handle other unexpected types
        else {
            return '';
        }
    }


  return (
    <>
    <div className='print-only'>שם הבודק:</div>
        <Typeahead 
            id='checker-name'
            style={{textAlign: 'right'}}
            options={fsm.checkerNameSuggestion?.manual_checker_name || []}
            className='checker-name'
            placeholder='שם הבודק'
            onInputChange={(value) => setCheckerName(value)}
            onChange={(selected) => setCheckerName(selected[0])}
            labelKey={(option) => handleOption(option)}
            selected={checkerName ? [checkerName] : []}
            name="checkername"
        />
    </>
   
  )
}

export default CheckerNameInput