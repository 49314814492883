import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import api from '../api/AxiosConfig'
import { Button, Form } from 'react-bootstrap';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf'
import './PdfUpload.css' 
import { modalInterface } from '../../mobx/ModalInterface';
import upload_utils from '../../utils/upload_utils';
import FileNoSupported from '../../assets/images/preview_not_supported.png'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function PdfUpload({patient , patientFiles , setPatientFiles}) {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [disableButton , setDisableButton] = useState(false)
  const [errorMessage , setErrorMessage] = useState(null)

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage(null)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);

      // Generate preview URL for image/video
      const fileUrl = URL.createObjectURL(droppedFile);
      setPreviewUrl(fileUrl);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage(null)
  };


  const handleFileChange = (e) => {
    setErrorMessage(null)
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      const fileType = uploadedFile.type;
      if (fileType.match('image.*') || fileType.match('video.*') || fileType === 'application/pdf') {
        setPreviewUrl(URL.createObjectURL(uploadedFile));
      } else {
        setPreviewUrl(null);
      }
    }
  };

  const renderPreview = () => {
    if(!file){
      return (
        <div 
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragLeave={handleDragOver}
        style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
        >
        Drag and drop a file here
      </div>
      )
        
    }
    if (!previewUrl) {
      return  <img src={FileNoSupported} style={{ maxWidth: '100%', maxHeight: '200px' }}></img>
    }

    if (file && file.type.match('image.*')) {
      return <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />;
    } else if (file && file.type === 'application/pdf') {
      return (
        <Document file={previewUrl} onLoadSuccess={({ numPages }) => {}}>
          <Page pageNumber={1} width={200} height={200} />
        </Document>
      );
    } else if (file && file.type.match('video.*')) {
      return <video width="100%" height="80%" controls>
               <source src={previewUrl} type={file.type} />
               Your browser does not support the video tag.
             </video>;
    }
    else {
      return (
        <img src={FileNoSupported} style={{ maxWidth: '100%', maxHeight: '200px' }}></img>
      )
    }
  };

  const initialValues = {
    name: '',
    note: ''
  }

  const onSubmit = async () => {
    if (!file) {
      return;
    }
    const fileValid = upload_utils.validateFileType(file)

    if(!fileValid){
      setFile(null)
      setErrorMessage("אינך יכול להעלות קובץ מסוג זה!")
      return
    }
    setDisableButton(true)
    const formData = new FormData();
    //Check file type here and prevent upload of suspicous files IN EDIT / ADD new patient too!
    formData.append('file', file);
    formData.append('name' , formik.values.name)
    formData.append('note' , formik.values.note)
    formData.append('patient_id', patient.id)
    try {
        const response = await api.post('/main/upload-patient-file', formData)
        if(response.data.status){
          const documents = response.data.documents
          setPatientFiles([...documents])
          setDisableButton(false)
          setErrorMessage(null)
          modalInterface.closeModal()
          formik.resetForm()
        } else {
          setErrorMessage(response.data.message)
          setDisableButton(false)
          //Handle Error here
        }
        
    } catch (error) {
      console.error("Error during file upload", error);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  })



  return (
    <div className='row col-12 pdf-upload-container'>
      <div className='col-8'>
        <Form>
          <Form.Control placeholder='שם הקובץ' onChange={formik.handleChange} value={formik.values.name} name='name' />
          <Form.Control placeholder='הערה' onChange={formik.handleChange} value={formik.values.note} name='note' />
          <Form.Control type="file" onChange={handleFileChange} accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document ,  image/*, video/*" />
          {errorMessage ? <p style={{color: 'red'}}> {errorMessage}</p> : null}
          <Button onClick={formik.handleSubmit} disabled={disableButton} >העלאה</Button>
        </Form>
      </div>
      <div className='col-4 pdf-preview-container'>
        {renderPreview()}
      </div>
    </div>
  );
}

export default PdfUpload;
