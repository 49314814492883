const schema = {
    nameMinLen: 2,
    nameMaxLen: 20,
    socialNumMinLen: 0,
    socialNumMaxLen: 20
}

const rv = {
    isContainHebrew: function isContainHebrew(str){
        const hebrewRegex = /[^\s\n]*[\u0590-\u05FF][^\s\n]*/;
        return hebrewRegex.test(str);
    },
      
    isContainEnglish: function isContainEnglish(str){
        const englishRegex = /[a-zA-Z]/;
        return englishRegex.test(str);
    },
      
    isContainNumbers:  function isContainNumbers(str){
        const numberRegex = /\d/
        return numberRegex.test(str)
    },
      
    isContainSpecialSigns: function isContainSpecialSigns(str){
        const specialRegex = /[!@#$%^&*()_+\-=;]/;
        return specialRegex.test(str)
    },
      
    isContainApostropheSign: function isContainApostropheSign(str){
        const apostropheRegex = /['\u2019]/;
        return apostropheRegex.test(str);
    },    
      
    isContainSpaces: function isContainSpaces(str){
        if(String(str).includes(' ')){
          return true
        } else {
          return false
        }
    },
      
    isContainQuotes: function isContainQuotes(str){
        const quoteRegex = /["'`]/;
        return quoteRegex.test(str);
    },
      
    isContainLatinLetters: function isContainLatinLetters(str){
        const latinRegex = /[\u0100-\u024F]/g;
        return latinRegex.test(str)
    }
}

const validatePatient = {
    validatePatientName: function validatePatientName(name){
        const nameLen = String(name).length
        if(nameLen < schema.nameMinLen){
            return false
        }
        if(nameLen > schema.nameMaxLen){
            return false
        }
        return true
    },
    validatePatientSocialNum: function validatePatientSocialNum(id){
        const idLen = String(id).length
        if(idLen < schema.socialNumMinLen){
            return false
        }
        if(idLen > schema.socialNumMaxLen){
            return false
        }
        return true
    }
}

export default validatePatient