import React , {useEffect, useState , useContext , useRef} from 'react'
import './GlassesCard.css'
import GenTable from '../../tables/table/GenTable'
import tableInfo from '../../../utils/tables_info/TablesInfo'
import { useFormik } from 'formik';
import { Form, Row , Col } from 'react-bootstrap'
import CardTitle from '../card_title/CardTitle';
import { ac } from '../../../mobx/AppContoller';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../../api/AxiosConfig'
import { AppContext } from '../../../context/AppContext';
import { observer  } from "mobx-react";
import LoadingSpinner from '../../loading_spinner/LoadingSpinner';
import utils from '../../../utils/utils';
import { fsm } from '../../../mobx/FormSuggestionManager';
import {modalInterface} from '../../../mobx/ModalInterface'
import GlassesMultiForm from '../../forms/examine_forms/GlassesForm';
import { useMediaQuery } from 'react-responsive';
import { mainmm } from '../../../mobx/MainMenuManager';
import GenButton from '../../buttons/GenButton';
import { em } from '../../../mobx/ExamineManager';
import CheckerNameInput from '../../inputs/checker_name/CheckerNameInput';
import PrintInfo from '../../print_info/PrintInfo';
import TextEditor from '../../text_editor/TextEditor';
import ExamineDropDown from '../../DropDownMenu/ExamineDropDown/ExamineDropDown';

const initialFormState = {
    frame_supplier: null,
    lenses_name: null,
    material_index: null,
    lenses_diamater: null,
    lenses_cover: null,
    order_id: null,
    date_of_delivery: null,
    frame_manufacture: null,
    frame_modal: null,
    frame_color: null,
    frame_size: null,
    pole_size: null,
    frame_info: null,
    medical_institute_cover: null,
    lenses_manufacture: null,
    glasses_function: null,
    total_price: null,
    client_pickup: false
};

function GlassesCard({examines , fillExamine , getPatinetInfo , allowEdit , patient}) {
    const isMobile = useMediaQuery({ query: `(max-width: 991.98px)` });
    const {appContext} = useContext(AppContext)
    const [minimize , setMinimize] = useState(true)
    const [saveColor , setSaveColor] = useState('#005371')
    const [tableData , setTableData] = useState({})
    const [genNote , setGenNote] = useState('')
    const [nearNote , setNearNote] = useState('')
    const [otherNote , setOtherNote] = useState('')

    const [tableValues , setTableValues] = useState({})
    const [overlay, setOverlay] = useState(false)
    const [checkerName , setCheckerName] = useState()
    const [forms, setForms] = useState([{...initialFormState}]);
    const disTableRef = useRef();
    const nearTableRef = useRef();
    const otherTableRef = useRef();
    const [deletedForms , setDeletedForms] = useState([])

    async function save(print = false){
        modalInterface.closeModal()
        if(utils.isEmptyObject(tableData)){
            if(print){
                mainmm.setAnchor("glasses")
                window.print()
                return
            }
           
        }

        if(ac.currentGlassesExamine != 0){
            modalInterface.createModal("התראה" , "האם אתה בטוח שאתה רוצה לעדכן בדיקה קיימת?" , [{
                text: "כן",
                focus: true,
                onclick: () => update(print)
            },{
                text: "לא",
                onclick: () => modalInterface.closeModal()}])
            return
        }
        
        const notes = {
                'general': genNote,
                'other': otherNote,
                'near': nearNote
        }

           try {
             const response = await api.post('/main/create-new-glasses-examine-card', {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                username: appContext.userData.username,
                type: "glasses",
                notes: notes,
                tableData: JSON.stringify(tableData),
                forms: forms,
                checker_name: checkerName
             })
             if(response.data.status){
                em.setLastSavedExamine("glasses" , checkerName)
                em.setEditExamine(false)
                setTableData({})
                getPatinetInfo()
                resetTables()
                setForms([{...initialFormState}])

                if(print){
                    window.print()  
                 }
             }
             
        } catch (e) {
            console.log(e);
        }

    }

    async function update(print){
        const notes = {
            'general': genNote,
            'other': otherNote,
            'near': nearNote
        }
        console.log(notes);
        try {
            const response = await api.post('/main/update-exsiting-glasses-card', {
                patient_id: ac.currentPatient,
                user_id: appContext.userData.id,
                username: appContext.userData.username,
                type: "glasses",
                notes: notes,
                tableData: JSON.stringify(tableData),
                forms: forms,
                checker_name: checkerName,
                examine: examines[ac.currentGlassesExamine - 1],
                deleted_forms: deletedForms
             })
             modalInterface.closeModal()
             if(print){
                setTimeout(() => window.print() , 100)
             }
             setTimeout(() => {
                em.setEditExamine(false)
                getPatinetInfo()
                resetTables()
                setTableData({})
                setForms([{...initialFormState}])
             },[print ? 1000 : 0])
           

            
        } catch (e) {
            console.log(e);
        }
    }

    function resetTables(){
        if (disTableRef.current) {
            disTableRef.current.onSave();
        }
        if (nearTableRef.current) {
            nearTableRef.current.onSave();
        }
        if (otherTableRef.current) {
            otherTableRef.current.onSave();
        }
    }

    function resetLoad(){
        setDeletedForms([])
        setTableValues({})
        setForms([{...initialFormState}])
        setCheckerName("")
        setGenNote('')
        setNearNote('')
        setOtherNote('')
        resetTables()

    }

    async function load(forceLast){
        resetLoad()
        if(!examines || ac.currentGlassesExamine == 0 || !examines[ac.currentGlassesExamine - 1]){
            return
        }
        
        try {
            setOverlay(true)
            const response = await api.post('/main/load-patinet-examine',{
                user_id: appContext.userData.id,
                patient_id: ac.currentPatient,
                type: 'glasses',
                examine_id: examines[ac.currentGlassesExamine - 1].id
            })
            const examinesInfo = response.data.examine
            const examineCard = response.data.examineCard[0]
            setCheckerName(examineCard.manual_checker_name)
            const fixedValues = utils.fixTableInputFormat(response.data.examine)
            setTableValues(fixedValues)
            setNearNote(examinesInfo.near?.r.note)
            setOtherNote(examinesInfo.other?.r.note)
            setGenNote(examineCard?.note)

            setForms(response.data.glassesOrder.length > 0 ? response.data.glassesOrder : [{...initialFormState}])
            setOverlay(false)
        } catch (e) {
            console.log(e);
        }
    }

    async function handleAutoFill(){

        if(!fillExamine || fillExamine.length == 0 || ac.currentGlassesExamine > 0){
            modalInterface.createModal('התראה' , 'אינך יכול להעתיק לכרטיס קיים' , null , null , 10000)
            return
        }
        try {
            setOverlay(true)
            const response = await api.post('/main/load-patinet-examine',{
                user_id: appContext.userData.id,
                patient_id: ac.currentPatient,
                type: 'refraction',
                examine_id: fillExamine[fillExamine.length - 1].id
            })
            if(!response.data.examine['subjective']){
                setOverlay(false)
                modalInterface.createModal('התראה' , 'לא נמצא השדה הרלוונטי בכרטיס רפרקציה המבוקש' , null , null , 10000)
                return
            }
            resetLoad()
            const distanceFill = utils.changeFillType(response.data.examine['subjective'], 'distance')
            const nearFill = utils.glasses.fillGlassesTables(distanceFill , 'add_n')
            const otherFill = utils.glasses.fillGlassesTables(distanceFill, 'add_i')         

            setTableValues({"distance": distanceFill , "near": nearFill, "other": otherFill})
       
            setTableData(prev => ({
                ...prev,
                ['distance']: distanceFill,
                ['near']: nearFill,
                ['other']: otherFill
            }))
            setCheckerName(fillExamine[fillExamine.length - 1].manual_checker_name)
            setOverlay(false)
            modalInterface.closeModal()
        } catch (e) {
            resetLoad()
            console.log(e);
        }
    }
    
    // function isSaveRequired(){
    //     if(formik.dirty){
    //         setSaveColor('#008000')
    //       } else {
    //         setSaveColor('#005371')
    //       }
    // }

    function handleOption(option){
        if (typeof option === 'object' && option.label) {
            return typeof option.label === 'string' ? option.label.toLowerCase() : option.label.toString();
        }
        // If the option is a string, return it in lowercase
        else if (typeof option === 'string') {
            return option.toLowerCase();
        }
        // If the option is a number, convert it to a string
        else if (typeof option === 'number') {
            return option.toString();
        }
        // Default case to handle other unexpected types
        else {
            return '';
        }
    }

    async function handlePrint(){
        mainmm.setAnchor("glasses")
        save(true)
       
    }

    


    useEffect(() => {
        load()
    },[ac.currentGlassesExamine, examines])

    useEffect(() => {
        if(mainmm.examineAnchor == "glasses"){
            setMinimize(false)
        } else {
            setMinimize(true)
        }
    },[mainmm.examineAnchor])

    useEffect(() => {
        const handleKeyDown = (event) => {
          if ((event.ctrlKey || event.metaKey) && event.key === 's' && em.currentExamine == "glasses") {
            event.preventDefault();
            modalInterface.createModal("התראה" , `האם אתה בטוח שברצונך לשמור בדיקת משקפיים?` ,[{text: "כן" , focus: true,  onclick: () => save() }, {text: "לא" , onclick: modalInterface.closeModal()}])
            
          }
        };
      
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, [tableData]);

    useEffect(() => {
 
    }, [forms]);


  return (
    <div id="glasses_card" className={`row ${mainmm.examineAnchor == "glasses" ? "print-friendly" : "no-print" } glasses_container container-fluid ${!allowEdit ? 'disabled-div' : null}`} style={{position: 'relative'}}>
        <LoadingSpinner visible={overlay} />
        <div className='col-xs-12 justify-content-evenly'>
            <section className='col-xs-12 col-lg-12 '>  
                <div className='col-xs-12 col-lg-12 row'>
                    <div className='card_header_section'>
                        <CardTitle current_examine={ac.currentGlassesExamine} handlePrint={handlePrint} examines={examines} title={"משקפיים"} minimize={minimize} setMinimize={setMinimize} saveColor={saveColor} onSaveClick={() => save()} type={"glasses"} handleAutofill={handleAutoFill}/>
                    </div>
                    <div>
                        <PrintInfo patient={patient} current_examine={ac.currentGlassesExamine} examines={examines}/>
                    </div>
                    <div className={!minimize ? "open-examine-card" : "close-examine-card"}>
                        <div className='col-lg-12 col-xs-12 d-flex justify-content-center mt-2'>
                            <CheckerNameInput checkerName={checkerName} setCheckerName={setCheckerName} />

                            {/* <Form.Control placeholder='שם הבודק' className='checker-name' value={checkerName} onChange={(e) => setCheckerName(e.target.value)} /> */}
                        </div>
                        <div className='row flex-wrap '>
                            <div className='col-lg-12 col-xs-12 print-order-1'>
                                <div style={{textAlign: 'left'}}>Distance</div>
                                <GenTable ref={disTableRef} tableFor={"distance"} examine={"glasses"} setTableData={setTableData} setSaveColor={setSaveColor} headers={tableInfo.glasses.tHeaders.distanceHeaders} columns={tableInfo.glasses.tColumns.distacneColumns} initialValues={tableValues.distance ? tableValues.distance : tableInfo.glasses.distanceInitailValues}/>
                            </div>
                            {/* THIS DIV IS FOR PRINTING ONLY */}
                            <div className='col-lg-12 col-xs-12 table-note-container print-order-2' style={{display: 'none'}} >
                                <TextEditor  theme='snow' value={genNote} />
                            {   /* <Form.Control placeholder='הערות' as='textarea' rows={5} value={notes.general} name='general' onChange={handleNoteChange}  className='table-note'></Form.Control> */}
                            </div>
                            {/*THIS DIV IS FOR PRINTING ONLY */}
                        </div>
                        <div className='row flex-wrap'>
                            <div style={{textAlign: 'left'}}>Near</div>
                            
                            <div className='col-lg-3 col-xs-3 table-note-container print-order-4' style={isMobile ? {order: 2} : null}>
                                <TextEditor  theme='snow' value={nearNote} setValue={setNearNote} placeholder='הערות ל - Near'   />
                                {/* <Form.Control placeholder='הערות ל - Near'  value={notes.near} name='near' onChange={handleNoteChange} as='textarea' className='table-note'></Form.Control> */}
                            </div>
                            <div className='col-lg-9 col-xs-9 print-order-3'>
                                <GenTable ref={nearTableRef} tableFor={"near"} examine={"glasses"} setTableData={setTableData} setSaveColor={setSaveColor} headers={tableInfo.glasses.tHeaders.nearHeader} columns={tableInfo.glasses.tColumns.nearColumn} initialValues={tableValues.near ? tableValues.near : tableInfo.glasses.nearOtherInitialValues}/>
                            </div>
                        </div>
                        <div className='row flex-wrap'>
                            <div style={{textAlign: 'left'}}>Other</div>
                            
                            <div className='col-lg-3 col-xs-3 table-note-container print-order-6' style={isMobile ? {order: 2} : null} >
                             <TextEditor  theme='snow' value={otherNote} setValue={setOtherNote} placeholder='הערות ל - Other'   />

                                {/* <Form.Control placeholder='הערות ל - Other' value={notes.other} name='other' onChange={handleNoteChange} as='textarea'  className='table-note'></Form.Control> */}
                            </div>
                            
                            <div className='col-lg-9 col-xs-9 print-order-5' >
                                <GenTable ref={otherTableRef} tableFor={"other"} examine={"glasses"} setTableData={setTableData} setSaveColor={setSaveColor} headers={tableInfo.glasses.tHeaders.otherHeader} columns={tableInfo.glasses.tColumns.otherColumn} initialValues={tableValues.other ? tableValues.other : tableInfo.glasses.nearOtherInitialValues}/>
                            </div>
                        </div>
                        {/* <div className='pagebreak'>
                        <PrintInfo patient={patient} current_examine={ac.currentLensesExamine} examines={examines}/>
                        </div> */}
                        <div className='glasses-order-title no-print '>מאפייני משקפיים</div>

                        <GlassesMultiForm patient={patient} setDeletedForms={setDeletedForms} forms={forms} setForms={setForms} initialFormState={initialFormState} setSaveColor={setSaveColor} examines={examines}/>
                        
                        <div className='col-lg-12 col-xs-12 table-note-container no-print' >
                            <TextEditor  theme='snow' value={genNote} setValue={setGenNote} placeholder='הערות'  />
                            {/* <Form.Control placeholder='הערות' as='textarea' rows={5} value={notes.general} name='general' onChange={handleNoteChange}  className='table-note'></Form.Control> */}
                        </div>
                        <br className='no-print' />
                        <div className='no-print'>
                            <GenButton width={'40%'} text={ac.currentGlassesExamine == 0 ? "שמירה כרטיס משקפיים" : "עדכן כרטיס משקפיים"} onClick={() => save()} backgroundColor={saveColor} textColor={'#fff'}></GenButton>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  )
}

export default observer(GlassesCard)