import React , {useContext, useEffect} from 'react';
import LoginPage from '../pages/login/LoginPage';
import MainMenuPage from '../pages/main_menu/MainMenuPage';
import { AppContext } from '../context/AppContext';
import { sm } from '../mobx/ScreenManager';
import TestScreen from '../pages/test/TestScreen';
import { observer } from 'mobx-react';

const Router =  observer(() => {

  return (
    <>
      {sm.showLogin ? <LoginPage /> : null}
      {sm.showMainMenu ? <MainMenuPage /> : null}
      {sm.showTest ? <TestScreen /> : null}
     
    </>

  //   <BrowserRouter>
  //   <Routes>
  //       <Route path="/login" element={<LoginPage setUserData={setUserData} />} />
  //       <Route path="/main_menu" element={<MainMenuPage />} />
  //       <Route path="/login_otp" element={<LoginOtpPage />} />
  //   </Routes>
  // </BrowserRouter>
  );
});

export default Router;
