import React, { useEffect, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import GenButton from '../buttons/GenButton';
import './ImageCropper.css';
import { Button } from 'react-bootstrap';

function ImageCropper({ file, onSave, onAbort }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setImageSrc(fileUrl);

            return () => URL.revokeObjectURL(fileUrl);
        }
    }, [file]);

    const getCroppedImgAsFile = async () => {
        const image = new Image();
        image.src = imageSrc;
        await new Promise((resolve) => {
            image.onload = resolve;
        });
    
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
    
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
    
        ctx.drawImage(
            image,
            croppedAreaPixels.x * scaleX,
            croppedAreaPixels.y * scaleY,
            croppedAreaPixels.width * scaleX,
            croppedAreaPixels.height * scaleY,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );
    
        // Convert canvas to blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                // Convert the blob to a File object
                const file = new File([blob], "croppedImage.jpeg", { type: "image/jpeg" });
                resolve(file);
            }, 'image/jpeg');
        });
    };
    
    const handleSave = async () => {
        if (croppedAreaPixels) {
            const croppedImageFile = await getCroppedImgAsFile();
            onSave(croppedImageFile); // Pass the cropped image File to the onSave handler
        }
    };

    const handleAbort = () => {

        onAbort(file); // Trigger the onAbort handler
    };

    return (
        <div className='cropper-container'>
            <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape='round'                
            />
            <div className="buttons-container">
                <Button backgroundColor={'#0a58ca'} color={'#fff'} onClick={handleSave}>שמור</Button>
                <Button backgroundColor={'#0a58ca'} color={'#fff'} onClick={handleAbort}>ביטול</Button>
            </div>
        </div>
    );
}

export default ImageCropper;