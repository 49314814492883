import Dropdown from 'react-bootstrap/Dropdown';
import more_info from '../../assets/images/more_info_dots.png'
import { forwardRef } from 'react';
import { mainmm } from '../../mobx/MainMenuManager'
import "./DropDownMenu.css"
import { ac } from '../../mobx/AppContoller';
import { modalInterface } from '../../mobx/ModalInterface';

function DropDownMenu({hoveredPatient}) {
    const CustomToggle = forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {<img src={more_info}/>}
        </a>
      ));

      function handleEdit(){
        modalInterface.closeModal()
        mainmm.changeCard("edit")
        ac.setPatient(hoveredPatient.id)
        ac.setCurrentPatientData(hoveredPatient)
      }

    return (
        <Dropdown  drop="down-centered" autoClose="outside inside">
        <Dropdown.Toggle
            as={CustomToggle}
            variant="success"
            id="dropdown-basic"
        >
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item onClick={()=> handleEdit()}>עריכת משתמש</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
      );
}

export default DropDownMenu;